import React, { useEffect } from 'react';
import PageLikeGrowth from '../../../components/Audience/PageLikeGrowth/PageLikeGrowth';
import { Box, Divider, Grid } from '@mui/material';
import OrganicVsPaidLikes from '../../../components/Audience/OrganicVsPaidLikes/OrganicVsPaidLikes';
import PageLikesBySoure from '../../../components/Audience/PageLikesBySource/PageLikesBySoure';
import PageUnlikeBySource from '../../../components/Audience/PageUnlikeBySource/PageUnlikeBySource';
import PageLikesByCountry from '../../../components/Audience/PageLikesByCountry/PageLikesByCountry';
import PageLikesByLanguage from '../../../components/Audience/PageLikesByLanguage/PageLikesByLanguage';
import PageLikesByCity from '../../../components/Audience/PageLikesByCity/PageLikesByCity';
import PageLikesByGender from '../../../components/Audience/PageLikesByGender/PageLikesByGender';
import LikesByAgeGroup from '../../../components/Audience/LikesByAgeGroup/LikesByAgeGroup';
import PageImpressionsvsReach from '../../../components/ReachandImpressionComponents/PageImpressionsvsReach/PageImpressionsvsReach';
import OrganicandPaidPagevsReach from '../../../components/ReachandImpressionComponents/OrganicandPaidPagevsReach/OrganicandPaidPagevsReach';
import ViralandNonViralpagevsReach from '../../../components/ReachandImpressionComponents/ViralandNonViralPagevsReach/ViralandNonViralpagevsReach';
import PageShareImpressionType from '../../../components/ReachandImpressionComponents/PageShareImpressions/PageShareImpressionType';
import PageShares from '../../../components/ReachandImpressionComponents/PageShares/PageShares';
import PageReachbyCountry from '../../../components/ReachandImpressionComponents/PageReachbyCountry/PageReachbyCountry';
import PageSharesbytype from '../../../components/ReachandImpressionComponents/PageSharesbyType/PageSharesbytype';
import PageReachbyLanguage from '../../../components/ReachandImpressionComponents/PageReachbyLanguage/PageReachbyLanguage';
import PageReachesByCity from '../../../components/ReachandImpressionComponents/PageReachbyCity/PageReachbyCity';
import PageReachesByGender from '../../../components/ReachandImpressionComponents/PageReachbyGender/PageReachbyGender';
import PageReachAgeGroup from '../../../components/ReachandImpressionComponents/PageReachbyAgeGroup/PageReachAgeGroup';
import {
  getPageReachImpressionData,
  getPaidOrganicReachData,
  getViralReachImpressionsData,
  getShareImpressionsByTypeData,
  getPageSharesData,
  getShareByTypeData,
  getReachByCountryData,
  getReachByLanguageData,
  getReachByCityData,
  getReachByGenderData,
} from '../../../redux/reduxThunk/facebook';
import { useSelector, useDispatch } from 'react-redux';

function ReachandImpression() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchPosts_EngagementTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-02',
        toDate: '2023-04-13',
      };

      dispatch(getPageReachImpressionData(summaryData));
      dispatch(getPaidOrganicReachData(summaryData));
      dispatch(getViralReachImpressionsData(summaryData));
      dispatch(getShareImpressionsByTypeData(summaryData));
      dispatch(getPageSharesData(summaryData));
      dispatch(getShareByTypeData(summaryData));
      dispatch(getReachByCountryData(summaryData));
      dispatch(getReachByLanguageData(summaryData));
      dispatch(getReachByCityData(summaryData));
      dispatch(getReachByGenderData(summaryData));
    };
    fetchPosts_EngagementTabData();
  }, [audienceSummary]);

  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container sx={{ mb: '10px' }}>
          <Grid lg={6}>
            <PageImpressionsvsReach />
          </Grid>

          <Grid lg={5.5} sx={{ ml: 2 }}>
            <OrganicandPaidPagevsReach />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: '10px' }}>
          <Grid lg={6}>
            <ViralandNonViralpagevsReach />
          </Grid>

          <Grid lg={5.5} sx={{ ml: 2 }}>
            <PageShareImpressionType />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: '10px' }}>
          <Grid lg={6}>
            <PageShares />
          </Grid>

          <Grid lg={5.5} sx={{ ml: 2 }}>
            <PageSharesbytype />
          </Grid>
        </Grid>
        <PageReachbyCountry />

        <Grid container sx={{ mb: '10px' }}>
          <Grid lg={6}>
            <PageReachesByGender />
          </Grid>
          <Grid lg={5.5} sx={{ ml: 2 }}>
            <PageReachAgeGroup />
          </Grid>
        </Grid>

        <Grid container sx={{marginBottom:"10px"}}>
          <Grid lg={6}>
            <PageReachbyLanguage />
          </Grid>

          <Grid lg={5.5} sx={{ ml: 2 }}>
            <PageReachesByCity />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ReachandImpression;
