import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import { useSelector } from 'react-redux';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Engagegement',
    heading3: '%',
  },
];

function OrganicVsPaidLikes() {
  const { paidOrganicLikesLoading, paidOrganicLikesData } = useSelector(
    (state) => state.facebook
  );
  const tabledata = [
    {
      data1: 'Organic  ',
      data2: paidOrganicLikesData?.paidLikes,
      data3: `(${((paidOrganicLikesData?.paidLikes * 100) / 100).toFixed(2)})%`,
    },
    {
      data1: 'Paid',
      data2: paidOrganicLikesData?.organicLikes,
      data3: `${((paidOrganicLikesData?.organicLikes * 100) / 100).toFixed(
        2
      )}%`,
    },
  ];
  const sum = tabledata.reduce((total, item) => total + item.data2, 0);
  var doughnutData = [
    { labels: 'Total', value: [sum] },
    { labels: 'Organic', value: paidOrganicLikesData?.paidLikes },
    { labels: 'Paid', value: paidOrganicLikesData?.organicLikes },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {paidOrganicLikesLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography variant="h4" color="#000" fontWeight={600}>
                Organic vs Paid Page Likes
              </Typography>
              <MoreHorizOutlined fontSize="large" onClick={openMenu} />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Discuss',
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            <Box>
              {paidOrganicLikesData?.paidLikes > 0 ||
              paidOrganicLikesData?.organicLikes > 0 ? (
                <DoughnutChart doughnutData={doughnutData} dataKey="value" />
              ) : (
                <NoDataComponent />
              )}
            </Box>

            {tabledata?.length > 0 ? (
              <TableComponent heading={heading} tabledata={tabledata} />
            ) : null}
          </>
        )}
      </Box>
    </>
  );
}

export default OrganicVsPaidLikes;
