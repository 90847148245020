import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';
import { useSelector, useDispatch } from 'react-redux';
const heading = [
  {
    heading1: 'Reach',
    heading2: 'Plays',
    heading3: '%',
  },
];
const tabledata = [
  {
    data1: 'Reach',
    data2: 16,
    data3: '87.5%',
  },
  {
    data1: 'Plays',
    data2: 2,
    data3: '6.25%',
  },
];

const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Total', value: [sum] },
  { labels: 'Reach', value: 16 },
  { labels: 'Plays', value: 2 },
];

function ReelsEngagementByType() {
  const { reelsEngagementTypeLoading, reelsEngagementTypeData } = useSelector(
    (state) => state.instagram
  );

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between">
          <Typography variant="h4" color="#000" fontWeight={600}>
            Reels Engagements by Type
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {reelsEngagementTypeLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : reelsEngagementTypeData?.data?.length > 0 ? (
          <>
            <DoughnutChart
              doughnutData={reelsEngagementTypeData?.doughnutData}
              dataKey="value"
            />

            <TableComponent
              heading={heading}
              tabledata={reelsEngagementTypeData?.data}
            />
          </>
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default ReelsEngagementByType;
