import React from 'react'
import { Card, CardContent, CardMedia, Typography, Box, Grid, Divider } from '@mui/material'
import { MoreHorizOutlined, } from '@mui/icons-material';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';



const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 26,
    height: 26,
    border: `2px solid ${theme.palette.background.paper}`,
}));

function TopConnectionCards({data}) {
    
    return (<>
        {data.map((item) => {
            return (
                <>
                <Grid className='topPostcards'>
                <Card sx={{ bgcolor: '#f8f8f8' }}>
                        <Grid container sx={{bgcolor: '#f8f8f8',
                            mr:1, p: '8px 15px',}}>
                            <Grid lg={5}>
                                <Badge className='social-account'
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt="Remy Sharp" src={item.SmallIcon} />
                                    }
                                >
                                    <Avatar alt="Travis Howard" src={item.BigIcon} sx={{ width: 56, height: 56 }} />
                                </Badge>
                            </Grid>
                            <Grid lg={7}>
                                <Typography variant='h5'>
                                    {item.Title}
                                </Typography>
                            </Grid>
                        </Grid>


                        <CardContent>
                            <Divider />
                            <Grid display='flex' alignItems='center'
                                justifyContent='space-between' mt={1}>
                                <Typography variant='h6' fontWeight={500}>
                                    Likes
                                </Typography>
                                <Typography variant='h6' fontWeight={500}>
                                    {item.Likes}
                                </Typography>
                            </Grid>
                            <Grid display='flex' alignItems='center'
                                justifyContent='space-between' mt={1}>
                                <Typography variant='h6' fontWeight={500}>
                                    Comments
                                </Typography>
                                <Typography variant='h6' fontWeight={500}>
                                    {item.Comments}
                                </Typography>
                            </Grid>
                            <Grid display='flex' alignItems='center'
                                justifyContent='space-between' mt={1}>
                                <Typography variant='h6' fontWeight={500}>
                                    Wall Posts
                                </Typography>
                                <Typography variant='h6' fontWeight={500}>
                                    {item.WallPosts}
                                </Typography>
                            </Grid>
                            <Grid display='flex' alignItems='center'
                                justifyContent='space-between' mt={1}>
                                <Typography variant='h6' fontWeight={500}>
                                    Incoming DM
                                </Typography>
                                <Typography variant='h6' fontWeight={500}>
                                    {item.IncomingDM}
                                </Typography>
                            </Grid>
                            <Grid display='flex' alignItems='center'
                                justifyContent='space-between' mt={1}>
                                <Typography variant='h6' fontWeight={500}>
                                    All Engagement
                                </Typography>
                                <Typography variant='h6' fontWeight={500}>
                                    {item.AllEngagement}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                   
                </>
            )
        })}

    </>
    )
}

export default TopConnectionCards