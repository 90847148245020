import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import HorizontalStackedBarChart from '../../Common/HorizontalStackedBarChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

function PageReachAgeGroup() {
  const data = [
    {
      age: '+65',
      Male: 2,
      Female: 2,
      Others: 0,
    },
    {
      age: '55-64',
      Male: 1,
      Female: 2,
      Others: 0,
    },
    {
      age: '45-54',
      Male: 11,
      Female: 5,
      Others: 0,
    },
    {
      age: '35-44',
      Male: 13,
      Female: 22,
      Others: 12,
    },
    {
      age: '25-34',
      Male: 33,
      Female: 28,
      Others: 0,
    },
    {
      age: '18-24',
      Male: 13,
      Female: 16,
      Others: 0,
    },
    {
      age: '13-17',
      Male: 6,
      Female: 4,
      Others: 0,
    },
    {
      age: '<13',
      Male: 0,
      Female: 0,
      Others: 0,
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { reachByGenderLoading, reachByGenderData } = useSelector(
    (state) => state.facebook
  );

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
            width: '100%',
            height:"100%",
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
        }}
      >
        {reachByGenderLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Page Reach by Age Group
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {reachByGenderData?.totalFemaleCount > 0 ||
            reachByGenderData?.totalMaleCount > 0 ? (
              <HorizontalStackedBarChart
                data={reachByGenderData?.data}
                YAxis_dataKey="ageGroup"
                bar1DataKey="femaleCount"
                bar2DataKey="maleCount"
                bar3DataKey="Others"
                XAxis_Label="Reach"
                YAxis_Label="Age Range"
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PageReachAgeGroup;
