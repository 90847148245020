import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import TableComponent from '../../TableComponent/TableComponent';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const data = [
  {
    name: "16 MAR' 23",
    Reactions: 200,
    Comments: 450,
  },
  {
    name: "17 MAR' 23",
    Reactions: 250,
    Comments: 967,
  },
  {
    name: "18 MAR' 23",
    Reactions: 1250,
    Comments: 1098,
  },
  {
    name: "19 MAR' 23",
    Reactions: 1480,
    Comments: 1200,
  },
  {
    name: "20 MAR' 23",
    Reactions: 1520,
    Comments: 1108,
  },
  {
    name: "21 MAR' 23",
    Reactions: 300,
    Comments: 680,
  },
  {
    name: "22 MAR' 23",
    Reactions: 400,
    Comments: 450,
  },
];

function TypeOfEngagementActions() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { engagementTypeActionsLoading, engagementTypeActionsData } =
    useSelector((state) => state.facebook);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
        {engagementTypeActionsLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Type of Engagegement Actions
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {engagementTypeActionsData?.data?.length > 0 ? (
              <BarAndAreaChart
                data={engagementTypeActionsData?.data}
                XAxis_dataKey="day"
                barDataKey="commentsCount"
                area1DataKey="reactionsCount"
                YAxis_Label="Number Of Engagements"
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default TypeOfEngagementActions;
