import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import HorizontalDualBarComponent from '../../Common/ChartComponents/HorizontalDualBarComponent/HorizontalDualBarComponent';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const horizontalDualBarData = [
  {
    name: 'Other',
    value1: 53,
    value2: 16,
  },
  {
    name: 'Page Post',
    value1: 46,
    value2: 33,
  },
  {
    name: 'Fan',
    value1: 1,
    value2: 1,
  },
  {
    name: 'Mention',
    value1: 0,
    value2: 0,
  },
];
const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 20;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value.split(' ')[1]}
      </text>
    </g>
  );
};

function PageSharesbytype() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { shareByTypeLoading, shareByTypeData } = useSelector(
    (state) => state.facebook
  );
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {shareByTypeLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Page Shares by Type
            </Typography>
            <MoreHorizOutlined
              fontSize="large"
              onClick={openMenu}
              sx={{ cursor: 'pointer' }}
            />
          </Grid>
          <MenuComponent
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            displayItems={[
              'Export as PNG',
              'Export as PDF',
              'Export as CSV',
              'Send as Email',
              'Schedule',
            ]}
          />
          {shareByTypeData?.data?.length > 0 ? (
            <HorizontalDualBarComponent
              horizontalDualBarData={shareByTypeData?.data}
              dataKey1="value1"
              dataKey2="value2"
              yAxisLabel="name"
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
    </Box>
  );
}

export default PageSharesbytype;
