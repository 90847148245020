import React, { useState, useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TableComponent from '../../TableComponent/TableComponent';
import { MoreHorizOutlined } from '@mui/icons-material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highmaps';
// import worldMap from "@highcharts/map-collection/world.geo.json";
// import worldMap from "highcharts-map-collection/"
import * as worldMap from '@highcharts/map-collection/custom/world.geo.json';
// import mapData from 'highcharts-map-collection/custom/world.geo.json';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Source',
    heading2: 'Likes',
    heading3: 'Likes%',
  },
];

function LikesByCountry() {
  const { likesByCountyLoading, likesByCountyData } = useSelector(
    (state) => state.instagram
  );
  const [hoverData, setHoverData] = useState(null);
  const [mapCountry, setMapCountry] = useState(
    likesByCountyData?.mapData || []
  );
  const chartRef = React.useRef(null);

  //initializing map country data
  useEffect(() => {
    setMapCountry(likesByCountyData?.mapData || []);
  }, [likesByCountyData]);

  let data1 = {
    chart: {
      map: worldMap,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: 'spacingBox',
      },
    },
    colorAxis: {
      min: 0,
    },
    title: {
      text: null,
    },
    series: [
      {
        name: 'Likes',
        states: {
          hover: {
            color: 'blue',
          },
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}',
        },
        allAreas: false,
        data: mapCountry,
      },
    ],
  };
  function resetZoom() {
    const chart = chartRef.current.chart;
    if (chart) {
      chart.xAxis[0].setExtremes();
    }
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Likes by Country
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {likesByCountyLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <Grid container columns={12}>
            {likesByCountyData?.data?.length > 0 ? (
              <>
                <Grid lg={7} p={1}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={data1}
                    constructorType={'mapChart'}
                    updateArgs={[true, true, true]}
                    ref={chartRef}
                  />
                </Grid>

                <Grid lg={5}>
                  <Box
                    sx={{ border: '2px solid #ECECEC', borderRadius: '8px' }}
                  >
                    <Typography
                      variant="h4"
                      color="#000"
                      fontWeight={600}
                      m={2}
                    >
                      Top 5 Countries
                    </Typography>
                    <TableComponent
                      heading={heading}
                      tabledata={likesByCountyData?.data}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <NoDataComponent />
            )}
          </Grid>
        )}
      </Box>
    </>
  );
}

export default LikesByCountry;
