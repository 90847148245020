import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TableComponent from '../../TableComponent/TableComponent';
import { MoreHorizOutlined } from '@mui/icons-material';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const heading = [
  {
    heading1: 'Language',
    heading2: 'Reach',
    heading3: 'Reach%',
  },
];
const tabledata = [
  {
    data1: 'English',
    data2: '144',
    data3: '84.71%',
  },
  {
    data1: 'Bengali',
    data2: '6',
    data3: '3.53%',
  },
  {
    data1: 'Spanish',
    data2: '6',
    data3: '3.53%',
  },
  {
    data1: 'Italian',
    data2: '4',
    data3: '2.35%',
  },
  {
    data1: 'French',
    data2: '3',
    data3: '1.76%',
  },
  {
    data1: 'Other Languages',
    data2: '7',
    data3: '4.12%',
  },
];

function PageReachbyLanguage() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { reachByLanguageLoading, reachByLanguageData } = useSelector(
    (state) => state.facebook
  );
  const top5Language =
    reachByLanguageData &&
    reachByLanguageData?.data?.length > 0 &&
    reachByLanguageData?.data.slice(0, 5);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height:"100%",
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {reachByLanguageLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Page Reach by Language
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />

            {reachByLanguageData?.data?.length > 0 ? (
              <Box sx={{ border: '2px solid #ECECEC', borderRadius: '8px' }}>
                <TableComponent heading={heading} tabledata={top5Language} />
              </Box>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PageReachbyLanguage;
