import React, { useEffect } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import NumofPostsVsEngagement from '../../../components/PostAndEngageMentComponents/NumofPostsVsEngagement/NumofPostsVsEngagement';
import PostByType from '../../../components/PostAndEngageMentComponents/PostByType/PostByType';
import TopPostsBy from '../../../components/PostAndEngageMentComponents/TopPostsBy/TopPostsBy';
import TopConnections from '../../../components/PostAndEngageMentComponents/TopConnections/TopConnections';
import PostEngagementVsImpresion from '../../../components/PostAndEngageMentComponents/PostEngagementVsImpresions/PostEngagementVsImpresion';
import EngagementByPostType from '../../../components/PostAndEngageMentComponents/EngagementByPostType/EngagementByPostType';
import TypeOfEngagementActions from '../../../components/PostAndEngageMentComponents/TypeofEngagementActions';
import PostReactionByType from '../../../components/PostAndEngageMentComponents/PostReactionByType';
import PostsByPublishingApps from '../../../components/PostAndEngageMentComponents/PostsByPublishingApps';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPostsByTypeData,
  getTopPostsData,
  getPostsEngagementImpressionData,
  getPostsByAppsData,
  getEngagementByTypeData,
  getReactionsByTypeData,
  getEngagementTypeActionsData,
} from '../../../redux/reduxThunk/facebook';

function PostsAndEngagements() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchPosts_EngagementTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-02',
        toDate: '2023-04-13',
      };
      dispatch(getPostsByTypeData(summaryData));
      dispatch(getTopPostsData(summaryData));
      dispatch(getPostsEngagementImpressionData(summaryData));
      dispatch(getPostsByAppsData(summaryData));
      dispatch(getEngagementByTypeData(summaryData));
      dispatch(getReactionsByTypeData(summaryData));
      dispatch(getEngagementTypeActionsData(summaryData));
    };
    fetchPosts_EngagementTabData();
  }, [audienceSummary]);
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container sx={{ mb: 2 }}>
          <Grid lg={6} sx={{ width: '100%' }}>
            <NumofPostsVsEngagement />
          </Grid>

          <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
            <PostByType />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <TopPostsBy />
        </Grid>
        {/* <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <TopConnections />
        </Grid> */}
        {/* <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <PostsByPublishingApps />
        </Grid> */}
        <Grid container>
          <Grid
            lg={6}
            sx={{
              width: '100%',
              bgcolor: '#fff',
              p: 4,
              border: '2px solid #ECECEC',
              borderRadius: '8px',
              m: 1,
            }}
          >
            <PostEngagementVsImpresion />
          </Grid>

          <Grid
            lg={5.5}
            sx={{
              width: '100%',
              bgcolor: '#fff',
              p: 4,
              border: '2px solid #ECECEC',
              borderRadius: '8px',
              m: 1,
            }}
          >
            <EngagementByPostType />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            lg={6}
            sx={{
              width: '100%',
              bgcolor: '#fff',
              p: 4,
              border: '2px solid #ECECEC',
              borderRadius: '8px',
              m: 1,
            }}
          >
            <TypeOfEngagementActions />
          </Grid>

          <Grid
            lg={5.5}
            sx={{
              width: '100%',
              bgcolor: '#fff',
              p: 4,
              border: '2px solid #ECECEC',
              borderRadius: '8px',
              m: 1,
            }}
          >
            <PostReactionByType />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PostsAndEngagements;
