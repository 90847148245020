import { configureStore } from '@reduxjs/toolkit';
import userReduser from './Slice/user';
import facebookReducer from './Slice/facebook';
import instagramReducer from './Slice/instagram';
import selectedCardComponentReducer from './Slice/addCards';
export const store = configureStore({
  reducer: {
    user: userReduser,
    facebook: facebookReducer,
    selectedCardComponent: selectedCardComponentReducer,
    facebook: facebookReducer,
    instagram: instagramReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
