import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

function PageReachesByGender() {
  const { reachByGenderLoading, reachByGenderData } = useSelector(
    (state) => state.facebook
  );
  const heading = [
    {
      heading1: 'GENDER',
      heading2: 'REACH',
      heading3: '%',
    },
  ];
  const tabledata = [
    {
      data1: 'Male  ',
      data2: reachByGenderData?.totalMaleCount,
      data3: '46.47%',
    },
    {
      data1: 'Female  ',
      data2: reachByGenderData?.totalFemaleCount,
      data3: '46.47%',
    },
    {
      data1: 'others  ',
      data2: 0,
      data3: '7.06%',
    },
  ];

  const sum = tabledata.reduce((total, item) => total + item.data2, 0);

  var doughnutData = [
    { labels: 'Total', value: [sum] },
    { labels: 'Male', value: reachByGenderData?.totalMaleCount },
    { labels: 'Female', value: reachByGenderData?.totalFemaleCount },
    { labels: 'Others', value: 0 },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height:"100%",
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {reachByGenderLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography variant="h4" color="#000" fontWeight={600}>
                Page Reach by Gender
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {reachByGenderData?.totalFemaleCount > 0 ||
            reachByGenderData?.totalMaleCount > 0 ? (
              <>
                <DoughnutChart doughnutData={doughnutData} dataKey="value" />

                <Grid
                  height="100%"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                  <TableComponent heading={heading} tabledata={tabledata} />
                </Grid>
              </>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PageReachesByGender;
