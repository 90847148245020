import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';

const data = [
  {
    name: '1',
    Number_Of_Story_Exits: 3,
  },
  {
    name: '2',
    Number_Of_Story_Exits: 3,
  },
  {
    name: '3',
    Number_Of_Story_Exits: 3,
  },
  {
    name: '4',
    Number_Of_Story_Exits: 3,
  },
  {
    name: '5',
    Number_Of_Story_Exits: 3,
  },
];

function AvgExitsVsNumOfStories() {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Average Exits Vs Number of Stories
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        <Grid width="100%" height="100%">
          <ResponsiveContainer width="100%" height={500}>
            <LineChart data={data} margin={{ left: 20, right: 20, top: 20 }}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="auto" fontSize="12"></XAxis>
              <YAxis
                fontSize="14"
                label={{
                  value: 'Average Story Exits',
                  angle: -90,
                  position: 'insideLeft',
                  fontSize: '16px',
                  style: { textAnchor: 'middle' },
                }}
                domain={[0, (dataMax) => dataMax * 1.5]}
              />

              <Tooltip
                itemStyle={{ fontSize: '16px' }}
                wrapperStyle={{ fontSize: '16px' }}
              />
              <Legend
                iconSize={14}
                wrapperStyle={{ fontSize: 18 }}
                iconType="circle"
              />
              <Line
                dataKey="Number_Of_Story_Exits"
                barSize={35}
                fill="#413ea0"
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Box>
    </>
  );
}

export default AvgExitsVsNumOfStories;
