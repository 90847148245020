import { Typography, Box, Grid, Divider } from '@mui/material'
import React from 'react'
import { MoreHorizOutlined, } from '@mui/icons-material';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import TopReelCards from '../../TopReelCards';
// import TopPostCards from '../../TopPostCards/TopPostCards';
import Loader from '../../Loader';
import NoDataComponent from "../../Common/NoDataComponent";
import { useSelector } from 'react-redux';

function TopReelsBy() {
    const { top5ReelsLoading, top5ReelsData } = useSelector((state) => state.instagram);
    const data = [
        {
            Title: 'directly via facebook',
            PostedtContentIcon: <SlowMotionVideoIcon />,
            DateAndTime: '27 march 2023 12:00 AM',
            PostedContent: 'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336363969_871328360600246_5636292073696989109_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=wrDwVcqiu6IAX91v3IQ&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfANZZiRD1s1IzRrXGitx9y23JMvxleEfnb8LVs9uSmcMg&oe=643DD81B',
            Likes: 20,
            Comments: 10,
            Shares: 1,
            Saved: 1,
            Engagement: 30,
            Plays: 50,
            Reach: 50,
        },
        {
            Title: 'directly via facebook',
            PostedtContentIcon: <SlowMotionVideoIcon />,
            DateAndTime: '29 march 2023 2:00 PM',
            PostedContent: 'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336063631_5685703464874664_2356209906088714133_n.jpg?stp=dst-jpg_p720x720&_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=ej5WkD_mHWcAX-QQ0hp&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfBY6suXyRVTXPtrATbeeHbIoqY6A03mFMY8j__GJcfBUg&oe=64414BAD',
            Likes: 20,
            Comments: 10,
            Shares: 1,
            Saved: 1,
            Engagement: 30,
            Plays: 50,
            Reach: 50,
        },
        {
            Title: 'directly via facebook',
            PostedtContentIcon: <SlowMotionVideoIcon />,
            DateAndTime: '27 march 2023 12:00 AM',
            PostedContent: 'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/338174249_219957520635652_7543591465357023898_n.jpg?stp=dst-jpg_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=NZ6lfVUYAZsAX_V1trm&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfDpFtM5j_-iQdqTCd6azLGKNbCxDfzheZmifW_CnlILiA&oe=6440D0AA',
            Likes: 20,
            Comments: 10,
            Shares: 1,
            Saved: 1,
            Engagement: 30,
            Plays: 50,
            Reach: 50,
        },
        {
            Title: 'directly via facebook',
            PostedtContentIcon: <SlowMotionVideoIcon />,
            DateAndTime: '27 march 2023 12:00 AM',
            PostedContent: 'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336363969_871328360600246_5636292073696989109_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=wrDwVcqiu6IAX91v3IQ&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfANZZiRD1s1IzRrXGitx9y23JMvxleEfnb8LVs9uSmcMg&oe=643DD81B',
            Likes: 20,
            Comments: 10,
            Shares: 1,
            Saved: 1,
            Engagement: 30,
            Plays: 50,
            Reach: 50,
        },
        {
            Title: 'directly via facebook',
            PostedtContentIcon: <SlowMotionVideoIcon />,
            DateAndTime: '27 march 2023 12:00 AM',
            PostedContent: 'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/339777887_256550240043020_5668072463112124590_n.jpg?stp=dst-jpg_p720x720&_nc_cat=104&ccb=1-7&_nc_sid=730e14&_nc_ohc=4VG-vkEHChwAX_3092i&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfCxUtYbjE8joK8MB8EidDbWLRZjG-rx9ddsz-0ySSZrIA&oe=6440A54E',
            Likes: 20,
            Comments: 10,
            Shares: 1,
            Saved: 1,
            Engagement: 30,
            Plays: 50,
            Reach: 50,
        }
    ]
    return (<>
        <Box sx={{ width: '100%' }}>
            {top5ReelsLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
                <>
                    <Grid display='flex' justifyContent='space-between' mb={4}>
                        <Typography variant='h4' color='#000' fontWeight={600}>
                            Top Reels By
                        </Typography>
                        <MoreHorizOutlined fontSize='large' />
                    </Grid>
                    {top5ReelsData?.length > 0 ?
                        <Grid container display='flex' >
                            <TopReelCards data={top5ReelsData} />
                        </Grid>
                        : <NoDataComponent />}

                </>
            }
        </Box>
    </>
    )
}

export default TopReelsBy