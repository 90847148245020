import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import TableComponent from '../../TableComponent/TableComponent';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from "../../Common/NoDataComponent";
import Loader from '../../Loader';

const data = [
  {
    name: "16 MAR' 23",
    Engagement: 1,
    Impressions: 120,
  },
  {
    name: "17 MAR' 23",
    Engagement: 25,
    Impressions: 200,
  },
  {
    name: "18 MAR' 23",
    Engagement: 50,
    Impressions: 300,
  },
  {
    name: "19 MAR' 23",
    Engagement: 250,
    Impressions: 400,
  },
  {
    name: "20 MAR' 23",
    Engagement: 400,
    Impressions: 600,
  },
  {
    name: "21 MAR' 23",
    Engagement: 10,
    Impressions: 680,
  },
  {
    name: "22 MAR' 23",
    Engagement: 200,
    Impressions: 680,
  },
];
function PostEngagementVsImpresion() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const openMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchorEl(null);
    };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Posts Engagegement Vs Impressions
          </Typography>
          <MoreHorizOutlined
            fontSize="large"
            onClick={openMenu}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
        <BarAndAreaChart
          data={data}
          XAxis_dataKey="name"
          barDataKey="Engagement"
          area1DataKey="Impressions"
          YAxis_Label="Number Of Engagements"
        />
      </Box>
    </>
  );
    const { postsEngagementImpressionLoading, postsEngagementImpressionData } = useSelector((state) => state.facebook)
    return (<>
        <Box sx={{
            width: '100%', bgcolor: '#fff', p: '8px 15px'
        }}>
            {postsEngagementImpressionLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
                <>
                    <Grid display='flex' justifyContent='space-between' mb={4}>
                        <Typography variant='h4' color='#000' fontWeight={600}>
                            Posts Engagegement Vs Impressions
                        </Typography>
                        <MoreHorizOutlined fontSize='large' />
                    </Grid>
                    {postsEngagementImpressionData?.data?.length > 0 ?
                        <BarAndAreaChart
                            data={postsEngagementImpressionData?.data}
                            XAxis_dataKey='day'
                            barDataKey='impressionsCount'
                            area1DataKey='engagementCount'
                            YAxis_Label='Number Of Engagements'
                        />
                        : <NoDataComponent />}
                </>
            }

        </Box>
    </>
    )
}

export default PostEngagementVsImpresion;
