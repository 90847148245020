import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import './styles.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import AudienceSummary from '../../../components/SummaryComponents/FacebookSummaryComponents/AudienceSummary/AudienceSummary';
import PerformanceSummary from '../../../components/SummaryComponents/FacebookSummaryComponents/PerformanceSummary/PerformanceSummary';
import ImpressionSummary from '../../../components/SummaryComponents/FacebookSummaryComponents/ImpressionSummary/ImpressionSummary';
import MessengerSummary from '../../../components/SummaryComponents/FacebookSummaryComponents/MessengerSummary/MessengerSummary';
import PostAndEngagement from '../../../components/SummaryComponents/FacebookSummaryComponents/PostAndEngagementSummary/PostAndEngagement';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAudienceSummaryData,
  getPerformanceSummaryData,
  getPostEngagementSummaryData,
  getImpressionsSummaryData,
  getMessengerSummaryData
} from "../../../redux/reduxThunk/facebook";

ChartJS.register(ArcElement, Tooltip, Legend);

function FacebookSummary() {
  let { facebook, instagram } = useSelector((state) => state.user);
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);
  
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchFacebookSummaryTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: "2023-04-02",
        toDate: "2023-04-13"
      }
      dispatch(getAudienceSummaryData(summaryData))
      dispatch(getPerformanceSummaryData(summaryData))
      dispatch(getPostEngagementSummaryData(summaryData))
      dispatch(getImpressionsSummaryData(summaryData))
      dispatch(getMessengerSummaryData(summaryData))
    }
    fetchFacebookSummaryTabData()
  }, [audienceSummary])
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <AudienceSummary />
        <PostAndEngagement />
        <PerformanceSummary />
        <ImpressionSummary />
        <MessengerSummary />
      </Grid>
    </>
  );
}

export default FacebookSummary;
