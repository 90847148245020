import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ComposedChart,
  LabelList,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
} from 'recharts';

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
  
    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value.split(' ')[1]}
        </text>
      </g>
    );
  };

function HorizontalDualBarComponent({horizontalDualBarData,dataKey1,dataKey2,yAxisLabel}) {
  return (
    <Grid container columns={12}>
    <ResponsiveContainer height={500} width={'100%'}>
      <ComposedChart
        layout="vertical"
        data={horizontalDualBarData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis type="number" hide />
        <YAxis dataKey={yAxisLabel} type="category"/>
        <Tooltip
          itemStyle={{ fontSize: '16px' }}
          wrapperStyle={{ fontSize: '16px' }}
        />
       
       {dataKey1 ? <Bar dataKey={dataKey1} barSize={20} fill="#413ea0"></Bar>:null}
       {dataKey2 ? <Bar dataKey={dataKey2} barSize={20} fill="#7a40ed"></Bar>:null}
        <LabelList
              dataKey="views"
              position="insideTopRight"
              content={renderCustomizedLabel}
            />
      </ComposedChart>
    </ResponsiveContainer>
  </Grid>
  )
}

export default HorizontalDualBarComponent
