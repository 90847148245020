import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import TableComponent from '../../TableComponent/TableComponent';
import BarAndLineChart from '../../Common/ChartComponents/BarAndLineChart';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';
import { useSelector } from 'react-redux';

function FollowerGrowth() {
  const { likeGrowthLoading, likeGrowthData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Follower Growth
          </Typography>

          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {/* <Grid width='100%' height='100%'>
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                        width={600}
                        height={450}
                        data={data}
                        margin={{ left: 20, right: 20, top:20 }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="auto" fontSize="14">
                        </XAxis >
                        <YAxis  fontSize="14" label={{ value: 'Number Of Followers', angle: -90, position: 'insideLeft',
                         fontSize: '16px', style: { textAnchor: 'middle' } }} domain={[0, 'dataMax']}/>
                        
                        <Tooltip itemStyle={{fontSize:'16px'}} wrapperStyle={{ fontSize:18 }}/>
                        <Legend  iconSize={14} wrapperStyle={{ fontSize:18 }}/>
                        <Bar dataKey="New_Followers" barSize={25} fill="#413ea0" />
                        <Line type="monotone" dataKey="Net_Followers" stroke="#ff7300" />
                    </ComposedChart>
                    </ResponsiveContainer>
                </Grid> */}
        {likeGrowthLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : likeGrowthData?.data?.length > 0 ? (
          <BarAndLineChart
            data={likeGrowthData?.data}
            XAxis_dataKey="day"
            barDatakey="New_followers"
            // line1Datakey='Net_Followers'
            YAxis_Label="Number Of Followers"
          />
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default FollowerGrowth;
