import React from 'react'
import { Grid } from '@mui/material'

function GridContainer3({
  children
}) {
  return (
    <>
      <Grid lg={3}
        display='grid' flexDirection='row' 
        justifyContent='space-between' p='8px 15px'
      >
        {children}
      </Grid>
    </>
  )
}

export default GridContainer3