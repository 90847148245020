import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Divider,
} from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import TopConnectionCards from '../../TopConnectionCards/TopConnectionCards';
import { FacebookIcon, GreetKaroIcon } from '../../../assets/assets/assets';
import { MetaIcon } from '../../../assets/assets/assets';
import { InstagramIcon } from '../../../assets/assets/assets';
import { GoogleAnalyticsIcon } from '../../../assets/assets/assets';
import { YoutubeIcon } from '../../../assets/assets/assets';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 26,
  height: 26,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function TopConnections() {
  const data = [
    {
      BigIcon: <GreetKaroIcon />,
      SmallIcon: <FacebookIcon />,
      Title: 'Greet Karo',
      Likes: 20,
      Comments: 10,
      WallPosts: 0,
      IncomingDM: 0,
      AllEngagement: 30,
    },
    {
      BigIcon: <MetaIcon />,
      SmallIcon: <InstagramIcon />,
      Title: 'Tejas',
      Likes: 45,
      Comments: 22,
      WallPosts: 2,
      IncomingDM: 0,
      AllEngagement: 69,
    },
    {
      BigIcon: <GoogleAnalyticsIcon />,
      SmallIcon: <FacebookIcon />,
      Title: 'Rajesh',
      Likes: 100,
      Comments: 99,
      WallPosts: 2,
      IncomingDM: 2,
      AllEngagement: 203,
    },
    {
      BigIcon: <YoutubeIcon />,
      SmallIcon: <FacebookIcon />,
      Title: 'Yugandar',
      Likes: 20,
      Comments: 10,
      WallPosts: 0,
      IncomingDM: 2,
      AllEngagement: 22,
    },
    {
      BigIcon: <GreetKaroIcon />,
      SmallIcon: <FacebookIcon />,
      Title: 'Sandeep',
      Likes: 25,
      Comments: 12,
      WallPosts: 0,
      IncomingDM: 1,
      AllEngagement: 38,
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Top Connections who have
          </Typography>
          <MoreHorizOutlined
            fontSize="large"
            onClick={openMenu}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />
        <Grid container display="flex">
          <TopConnectionCards data={data} />
        </Grid>
      </Box>
    </>
  );
}

export default TopConnections;
