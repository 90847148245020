import React, { useEffect } from 'react';
import TopReelsBy from '../../../components/ReelsAndEngagement/TopReelsBy';
import { Divider, Grid } from '@mui/material';
import NumOfReelsVsEngagement from '../../../components/ReelsAndEngagement/NumOfReelsVsEngagement';
import ReelsPlayVsReach from '../../../components/ReelsAndEngagement/ReelsPlayVsReach';
import ReelsEngagementActions from '../../../components/ReelsAndEngagement/ReelsEngagementActions';
import ReelsEngagementByType from '../../../components/ReelsAndEngagement/ReelsEngagementByType/ReelsEngagementByType';

// import ReelsPlayByReach from '../../../components/ReelsPlayVsReach'
import { useSelector, useDispatch } from 'react-redux';
import {
  getReelsVsEngagementData,
  getReelsPlaysVsReachData,
  getTop5ReelsData,
  getReelsEngagementActionsData,
  getReelsEngagementTypeData,
} from '../../../redux/reduxThunk/instagram';
function ReelsAndManagement() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchInstagramReelsAndManagementTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-02',
        toDate: '2023-04-13',
      };
      dispatch(getReelsVsEngagementData(summaryData));
      dispatch(getReelsPlaysVsReachData(summaryData));
      dispatch(getTop5ReelsData(summaryData));
      dispatch(getReelsEngagementActionsData(summaryData));
      dispatch(getReelsEngagementTypeData(summaryData));
    };
    fetchInstagramReelsAndManagementTabData();
  }, [audienceSummary]);
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container>
          <Grid lg={5.5} sx={{ m: 1 }}>
            <NumOfReelsVsEngagement />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <ReelsPlayVsReach />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <TopReelsBy />
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <Grid lg={7} sx={{ m: 1 }}>
            <ReelsEngagementActions />
          </Grid>

          <Divider orientation="vertical" flexItem />
          {/* <OrganicVsPaidLikes /> */}
          <Grid lg={4.5} sx={{ m: 1 }}>
            <ReelsEngagementByType />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ReelsAndManagement;
