import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridContainer from '../../../Common/gridContainer/GridContainer';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import TableComponent from '../../../TableComponent/TableComponent';
import { useSelector } from 'react-redux';
import PiChart from '../../../Common/ChartComponents/PiCharts/PiCharts';
import MenuComponent from '../../../Common/MenuComponent/MenuComponent';
import Loader from '../../../Loader';

function ImpressionSummary() {
  const {impressionsSummaryLoading, impressionsSummaryData } = useSelector((state) => state.facebook);
  const piData = [
    {
      name: 'Organic',
      value: impressionsSummaryData?.pageOrganicReach,
      percentage: impressionsSummaryData?.pageOrganicReach,
    },
    {
      name: 'Paid',
      value: impressionsSummaryData?.pagePaidReach,
      percentage: impressionsSummaryData?.pagePaidReach,
    },
  ];
  const COLORS = ['#413ea0', '#7a40ed'];
  const heading = [
    {
      heading1: 'Countries',
      heading2: 'Count',
      heading3: '%',
    },
  ];
  const tabledata = [
    {
      data1: 'INDIA  ',
      data2: '114',
      data3: '92.68%',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Impression Summary
          </Typography>
          <MoreHorizOutlined fontSize="large" onClick={openMenu}/>
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />
        {impressionsSummaryLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
        <>
        <Grid container columns={12}>
          <GridContainer>
            <GridDataComponent
              heading="Page impressions"
              value={impressionsSummaryData?.pageImpressionsTotal}
              percentFall={(
                (impressionsSummaryData?.pageImpressionsTotal * 100) /
                100
              ).toFixed(2)}
              avg="2"
            />
          </GridContainer>
          <Divider orientation="vertical" flexItem />

          <GridContainer>
            <GridDataComponent
              heading="Page Reach"
              value={impressionsSummaryData?.pageReachTotal}
              percentFall={(
                (impressionsSummaryData?.pageReachTotal * 100) /
                100
              ).toFixed(2)}
              avg="<4"
            />
          </GridContainer>
          <Divider orientation="vertical" flexItem />
          <Grid lg={3} >
            <Typography variant="h5" className="subheading" p="8px 15px 0px 15px">
              organic VS paid reach
            </Typography>
            <Grid container width="100%">
              <Grid width="100%" lg={7}>
                {impressionsSummaryData?.pageOrganicReach > 0 ||
                impressionsSummaryData?.pagePaidReach > 0 ? (
                  <PiChart
                  piData={piData}
                  dataKey="value"
                />                ) : (
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    No data to display.
                  </Typography>
                )}
              </Grid>
              {impressionsSummaryData?.pageOrganicReach > 0 ||
              impressionsSummaryData?.pagePaidReach > 0 ? (
                <Grid
                  lg={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box sx={{mb:2}}>
                    {piData?.map((item) => (
                      <Box display="flex" >
                        <Typography variant="h5" sx={{ mr: 1,}}>
                          {item.percentage}
                        </Typography>
                        <Typography variant="h5">{item.name}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem />
          <Grid lg={3.5} p="8px 15px">
            <Typography variant="h5" className="subheading">
              top country
            </Typography>
            <Grid height="80%" display="flex" alignItems="center">
              <TableComponent heading={heading} tabledata={tabledata} />
            </Grid>
          </Grid>
        </Grid>
        </>
}
      </Box>
    </>
  );
}

export default ImpressionSummary;
