import React from 'react'
import { Grid } from '@mui/material'
import {
    ResponsiveContainer,
    ComposedChart,
    Area,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from "recharts";

function BarAndAreaChart({
    data,
    XAxis_dataKey,
    barDataKey,
    area1DataKey,
    area2DataKey,
    YAxis_Label
}) {
    
  return (
    <>
    <Grid width='100%' height='100%'>
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                        data={data}
                        margin={{ left: 10, right: 10, }}
                    >
                        <XAxis dataKey={XAxis_dataKey} scale="auto" fontSize="12">
                        </XAxis >
                        <YAxis fontSize="14"  label={{
                            value: YAxis_Label, angle: -90, position: 'insideLeft',
                            fontSize: '16px',style: { textAnchor: 'middle' }
                        }} domain={[0, dataMax => (dataMax * 1.5)]} />
                        <Tooltip itemStyle={{ fontSize: '16px' }} wrapperStyle={{ fontSize: '16px' }} />
                        <Legend iconSize={14} wrapperStyle={{ fontSize: 18 }} iconType='circle' />
                        {barDataKey ? <Bar type="monotone" barSize={25} dataKey={barDataKey} fill="#413ea0" /> : null}
                        {area1DataKey ? <Area type="monotone" dataKey={area1DataKey} stroke="#ff7300" /> : null}
                        {area2DataKey ? <Area type="monotone" dataKey={area2DataKey} stroke="#82ca9d" /> : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </Grid>
    </>
  )
}

export default BarAndAreaChart