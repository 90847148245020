import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import React from 'react'

function GridDataComponent({
    heading,
    value,
    percentRise,
    percentFall,
    avg,
}) {
    return (<>
        <Grid container>
            <Grid lg={12}>
                <Typography variant='h5' className='subheading' >
                    {heading}
                </Typography>

            </Grid>
            <Grid lg={6}>
                
                <Typography
                    variant='h1' className='numbers'
                    sx={{ fontSize: '50px' }} mr={1}
                    fontWeight={400} color='#7a40ed'>
                    {value}
                </Typography>
                </Grid>
                <Grid lg={6}>
                {percentRise && (<Typography
                    variant='h5'
                    fontWeight={600}
                    display='flex'
                    alignItems='center'
                    fontSize='large'
                    color='green'
                    mt={2}
                >
                    <ArrowUpward fontSize='large' />
                    {percentRise}%
                </Typography>)}
                {percentFall && (<Typography
                    variant='h6'
                    fontWeight={600}
                    display='flex'
                    alignItems='center'
                    fontSize='large'
                    color='red'
                    mt={2}
                >
                    <ArrowDownward fontSize='large' />
                    {percentFall}%
                </Typography>)}
            </Grid>
            <Grid lg={12} display='flex' alignItems='center' sx={{ minHeight: '20px' }}>
                <Typography variant='h6' mr='5px'>
                    {avg ? `Average per day:` : ''}
                </Typography>
                <Typography variant='h6' fontWeight={600}>
                    {avg ? avg : ''}
                </Typography>
            </Grid>
        </Grid>


    </>
    )
}

export default GridDataComponent