import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, Select, Typography, Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
    handleSelectedBrandId,
} from '../../redux/Slice/user';
import { setCookie } from "../../helpers/cookie";
import { sidebarStyles } from "../../Themes/sideBarTheme";

function SelectSocialDropdown({ brandSettingData, allBrandData }) {
    const sidebarClass = sidebarStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState('');
    // default on load showing first value and api call
    useEffect(() => {
        let firstValue =
            allBrandData && allBrandData?.length > 0
                ? allBrandData[0]
                : '';

        let data = {
            brandId: firstValue?._id,
            brandName: firstValue?.name,
            icon: <Avatar className={sidebarClass.avatar}>
                {firstValue?.name && firstValue?.name[0]}
            </Avatar>
        };

        dispatch(handleSelectedBrandId(data.brandId));
        setCookie('created_brand_id', data.brandId);
        setValue({ ...data, icon: data.icon });
    }, [allBrandData]);

    // Dropdown on change function
    const handleChange = (event) => {
        let selectedAccount = event.target.value

        let data = {
            brandId: selectedAccount ? allBrandData?.find(brand => brand.name === selectedAccount)._id : '',
            brandName: selectedAccount?.name,
            icon: <Avatar className={sidebarClass.avatar}>
                {selectedAccount?.name ? selectedAccount?.name[0] : 'B'}
            </Avatar>
        };
        dispatch(handleSelectedBrandId(data.brandId));
        setCookie('created_brand_id', data.brandId);
        setValue({ brandName: selectedAccount, icon: data.icon });
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            className="dropdown_div mr-20"
            style={{ margin: '6px', height: '56px', width: "100%" }}
            renderValue={(selectedValue) => (
                <Grid display='flex' alignItems='center'  >
                    {/* <Avatar className={sidebarClass.avatar}>
                        {selectedValue?.icon}
                    </Avatar> */}
                    <Typography sx={{ ml: 1, fontSize: '16px', fontWeight: "600" }}>{selectedValue?.brandName}</Typography>
                </Grid>
            )}
        >
            {allBrandData?.map((brands) => (
                <MenuItem key={brands.id} value={brands.name} sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Avatar sx={{ height: '32px', width: '32px', fontSize: '16px', fontWeight: "600" }}>
                        {brands.name[0]}
                    </Avatar> */}
                    <Typography sx={{ ml: 1, fontSize: '16px', fontWeight: "600" }}>{brands.name}</Typography>
                </MenuItem>
            ))}
        </Select>
    );
}

export default SelectSocialDropdown;
