import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import { useSelector } from 'react-redux';
import MenuComponent from '../../../Common/MenuComponent/MenuComponent';
import Loader from '../../../Loader';

function MessengerSummary() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { messengerSummaryLoading, messengerSummaryData } = useSelector(
    (state) => state.facebook
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Messenger Insights Summary
          </Typography>
          <MoreHorizOutlined fontSize="large" onClick={openMenu} />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />
        {messengerSummaryLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid container columns={12}>
              <GridContainer3>
                <GridDataComponent
                  heading="Connection Gain"
                  value={messengerSummaryData?.messengerConnectionsGained}
                  percentRise={(
                    (messengerSummaryData?.messengerConnectionsGained * 100) /
                    100
                  ).toFixed(2)}
                  avg="0"
                />
              </GridContainer3>
              <Divider orientation="vertical" flexItem />

              <GridContainer3>
                <GridDataComponent
                  heading="Connection lost"
                  value={messengerSummaryData?.messengerConnectionsLost}
                  percentFall={(
                    (messengerSummaryData?.messengerConnectionsGained * 100) /
                    100
                  ).toFixed(2)}
                  avg="0"
                />
              </GridContainer3>
              <Divider orientation="vertical" flexItem />
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default MessengerSummary;
