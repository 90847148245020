import React,{useEffect} from 'react';
import {  Divider, Grid } from '@mui/material';
import ProfileViews from '../../../components/InstagramPerformanceComponents/ProfileViews/ProfileViews';
import ProfileClicks from '../../../components/InstagramPerformanceComponents/ProfileClicks/ProfileClicks';
import InstagramCtaClicksbyType from '../../../components/InstagramPerformanceComponents/CtaClicksByType/InstagramCtaClicksbyType';
import { useSelector, useDispatch } from 'react-redux';
import {
  getProfileViewsData,
  getPageClicksData,
  getCtaClickTypesData
} from "../../../redux/reduxThunk/instagram";


function InstagramPerformance() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);
  
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchInstagramPerformanceTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: "2023-04-02",
        toDate: "2023-04-13"
      }
      dispatch(getProfileViewsData(summaryData))
      dispatch(getPageClicksData(summaryData))
      dispatch(getCtaClickTypesData(summaryData))
     
    }
    fetchInstagramPerformanceTabData()
  }, [audienceSummary])
  return (
    <Grid sx={{ width: '100%',mb:2  }} container>
      <Grid container>
        <Grid  sx={{ width: '100%' }}>
          <ProfileViews />
        </Grid>
      </Grid>
     
     
      <Grid container>
        <Grid lg={6} sx={{ width: '100%' }}>
          <ProfileClicks />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml:2, }}>
        < InstagramCtaClicksbyType />
        </Grid>
      </Grid>
      
    </Grid>
  );
}

export default InstagramPerformance;
