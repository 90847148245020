import React from 'react';
import { Avatar, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import Loader from '../../components/Loader';
import {inputStyles} from "../../Themes/InputTheme";

function CommentReplyInput({
  commentData,
  postComment,
  handlePostComment,
  handleClickComment,
  loader,
}) {
  const inputClass=inputStyles()
  return (
    <Box className="div_align">
      {/* <Avatar title="profile_image" className="avatar" /> */}
      <TextField
        // label="With normal TextField"
        className={inputClass.input}
        id="myTextField"
        fullWidth
        multiline
        sx={{
          ml: 1,
          backgroundColor: '#ffffff',
        }}
        value={postComment}
        onChange={(e) => handlePostComment(e)}
        InputProps={{
          style: {
            alignItems: 'flex-start',
            fontSize: 14,
          },
          startAdornment: (
            <div>
              <span
                style={{
                  paddingRight: '8px',
                  paddingTop: '5px',
                  color: 'rgba(0, 0, 0, 0.6)',
                  fontSize: 12,
                }}
              >
                {`@${commentData?.author_name}`}
              </span>
              <br />
            </div>
          ),
          endAdornment: loader ? (
            <Loader color="inherit" value={100} size={25} />
          ) : (
            <span className="input_button" onClick={handleClickComment}>
              Send
            </span>
          ),
        }}
      />
    </Box>
  );
}
export default CommentReplyInput;
