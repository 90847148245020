import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import Loader from '../../../components/Loader';
import NoDataComponent from "../../Common/NoDataComponent";
import { useSelector, useDispatch } from 'react-redux';


function InstagramCtaClicksbyType() {

  const { ctaClickTypesLoading, ctaClickTypesData } = useSelector((state) => state.instagram);
  const heading = [
    {
      heading1: 'Type',
      heading2: 'Total',
      heading3: '%',
    },
  ];
  const tabledata = [
    {
      data1: 'Website',
      data2: 3,
      data3: '100%',
    },
    {
      data1: 'Phone Call',
      data2: 0,
      data3: '0%',
    },
    {
      data1: 'Email',
      data2: 0,
      data3: '0%',
    },
    {
      data1: 'Directions',
      data2: 0,
      data3: '0%',
    },
    {
      data1: 'Text Message',
      data2: 0,
      data3: '0%',
    },
  ];

  const sum = ctaClickTypesData && ctaClickTypesData?.length >0 && ctaClickTypesData?.reduce((total, item) => total + item.data2, 0);
  console.log("data2",ctaClickTypesData[0])
  var doughnutData = [
    { labels: 'CTA Clicks by Type', value: [sum] },
    { labels: 'Website', value: ctaClickTypesData[0]?.data2 },
    { labels: 'Phone Call', value: ctaClickTypesData[2]?.data2 },
    { labels: 'Email', value: ctaClickTypesData[1]?.data2 },
    { labels: 'Directions', value: ctaClickTypesData[4]?.data2 },
    { labels: 'Text Message', value: ctaClickTypesData[3]?.data2 },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
        }}
      >{ctaClickTypesLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) :
        <>

          <Grid display="flex" justifyContent="space-between">
            <Typography variant="h4" color="#000" fontWeight={600}>
              CTA Clicks by Type
            </Typography>
            <MoreHorizOutlined fontSize="large" />
          </Grid>
          {sum > 0 ?
            <>
              <DoughnutChart doughnutData={doughnutData} dataKey="value" />

              <TableComponent heading={heading} tabledata={ctaClickTypesData} />
            </>
            : <NoDataComponent />}

        </>
        }
      </Box>
    </>
  );
}

export default InstagramCtaClicksbyType;
