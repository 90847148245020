import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from 'recharts';
import SingleBarChart from '../../Common/ChartComponents/SingleBarChart';
import Loader from '../../../components/Loader';
import NoDataComponent from '../../Common/NoDataComponent';
import { useSelector, useDispatch } from 'react-redux';
const data = [
  {
    name: '19 Mar 23',
    Page_Profile_Views: 0,
  },
  {
    name: '21 Mar 23',
    Page_Profile_Views: 0,
  },

  {
    name: '24 Mar 23',
    Page_Profile_Views: 3,
  },
  {
    name: '25 Mar 23',
    Page_Profile_Views: 3,
  },
  {
    name: '27 Mar 23',
    Page_Profile_Views: 2,
  },
  {
    name: '29 Mar 23',
    Page_Profile_Views: 3,
  },
  {
    name: '31 Mar 23',
    Page_Profile_Views: 0,
  },
  {
    name: '01 Apr 23',
    Page_Profile_Views: 8,
  },
  {
    name: '03 Apr 23',
    Page_Profile_Views: 13,
  },
  {
    name: '05 Apr 23',
    Page_Profile_Views: 6,
  },
  {
    name: '07 Apr 23',
    Page_Profile_Views: 14,
  },
  {
    name: '08 Apr 23',
    Page_Profile_Views: 0,
  },
];

function ProfileViews() {
  const { profileViewsLoading, profileViewsData } = useSelector(
    (state) => state.instagram
  );
  console.log('profileViewsData', profileViewsData);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {profileViewsLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Profile Views
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            {/* <Grid width='100%' height='100%' >
          <ResponsiveContainer height={500} width={"100%"}>
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" />
              <YAxis
                fontSize="14"
                label={{
                  value: 'Number Of Views',
                  angle: -90,
                  position: 'left',
                  fontSize: '16px',
                  style: { textAnchor: 'middle' }
                }}
                domain={[0, 'dataMax']}
              />
              <Tooltip
                itemStyle={{ fontSize: '16px' }}
                wrapperStyle={{ fontSize: '16px' }}
              />
              <Legend
                iconSize={14}
                wrapperStyle={{ fontSize: 18 }}
                iconType="circle"
              />

              <Bar dataKey="Page_Profile_Views" barSize={40} fill="#413ea0" />
            </BarChart>
          </ResponsiveContainer>
        </Grid> */}
            {profileViewsData?.length > 0 ? (
              <SingleBarChart
                data={profileViewsData}
                XAxis_dataKey="end_time"
                barDataKey="Page_Profile_Views"
                YAxis_Label="Number Of Views"
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ProfileViews;
