import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import { handleDeleteCards } from '../../../redux/Slice/addCards';


function MenuComponent(props) {
  const dispatch = useDispatch();
  const { anchorEl, closeMenu, displayItems } = props;
  const { selectedCardComponent } = useSelector((state) => state.selectedCardComponent);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    closeMenu();
  };
  const handleDeleteCard = () =>{
    dispatch(handleDeleteCards())
    closeMenu();
  }

  const menuItems = [
    { label: 'Export as PDF', onClick: handleClose },
    { label: 'Export as PNG', onClick: handleClose },
    { label: 'Export as CSV', onClick: handleClose },
    { label: 'Send as Email', onClick: handleClose },
    { label: 'Schedule', onClick: handleClose },
    { label: 'Delete', onClick: handleDeleteCard },
  ];

  const filteredItems = menuItems.filter(item => displayItems?.includes(item.label));

  return (
    <div>
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        
      >
        <MenuItem  className='menu_texts' >Discuss</MenuItem>
        <Divider/>
        {filteredItems.map(item => (
          <MenuItem key={item.label} className='menu_texts' onClick={item.onClick}>{item.label}</MenuItem>
        ))}
      </Menu>
    </div>
  )
}
export default MenuComponent