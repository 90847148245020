import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAudienceSummary,
  getPerformanceSummary,
  getPostEngagementSummary,
  getImpressionsSummary,
  getMessengerSummary,
  getMessengerConnectionGrowth,
  getMessengerConnectionLost,
  getLikeGrowth,
  getPaidOrganicLike,
  getLikeSources,
  getUnlikeSources,
  getLikesByCounty,
  getLikesByLanguage,
  getLikesByCity,
  getLikesByGender,
  getLikesByAge,
  getPostsByType,
  getTopPosts,
  getPostsEngagementImpression,
  getPostsByApps,
  getEngagementByType,
  getReactionsByType,
  getEngagementTypeActions,
  getPageReachImpression,
  getPaidOrganicReach,
  getViralReachImpressions,
  getShareImpressionsByType,
  getPageShares,
  getShareByType,
  getReachByCountry,
  getReachByLanguage,
  getReachByCity,
  getReachByGender,
  getPageProfileViews,
  getPageTabViews,
  getPageViewsByInternal,
  getPageViewsByExternal,
  getPageViewsByGender,
  getPageViewsByAgeGroup,
  getPageClicks,
  getPageClicksByPost,
  getPostsPositiveFeedback,
  getPostsNegativeFeedback,
  getCtaClickTypes,
} from '../Api/facebookApi';

// summary
export const getAudienceSummaryData = createAsyncThunk(
  'summary/audience-summary',
  async (data) => await getAudienceSummary(data)
);
export const getPostEngagementSummaryData = createAsyncThunk(
  'summary/post-engagement-summary',
  async (data) => await getPostEngagementSummary(data)
);
export const getPerformanceSummaryData = createAsyncThunk(
  'summary/performance-summary',
  async (data) => await getPerformanceSummary(data)
);
export const getImpressionsSummaryData = createAsyncThunk(
  'summary/impressions-summary',
  async (data) => await getImpressionsSummary(data)
);
export const getMessengerSummaryData = createAsyncThunk(
  'summary/messenger-summary',
  async (data) => await getMessengerSummary(data)
);

// messenger
export const getMessengerConnectionGrowthData = createAsyncThunk(
  'messenger/messenger-connection-growth',
  async (data) => await getMessengerConnectionGrowth(data)
);
export const getMessengerConnectionLostData = createAsyncThunk(
  'messenger/messenger-connection-lost',
  async (data) => await getMessengerConnectionLost(data)
);

// audience
export const getLikeGrowthData = createAsyncThunk(
  'audience/like-growth',
  async (data) => await getLikeGrowth(data)
);
export const getPaidOrganicLikesData = createAsyncThunk(
  'audience/paid-organic-likes',
  async (data) => await getPaidOrganicLike(data)
);
export const getLikeSourcesData = createAsyncThunk(
  'audience/like-sources',
  async (data) => await getLikeSources(data)
);
export const getUnlikeSourcesData = createAsyncThunk(
  'audience/unlike-sources',
  async (data) => await getUnlikeSources(data)
);
export const getLikesByCountyData = createAsyncThunk(
  'audience/likes-by-county',
  async (data) => await getLikesByCounty(data)
);
export const getLikesByLanguageData = createAsyncThunk(
  'audience/likes-by-language',
  async (data) => await getLikesByLanguage(data)
);
export const getLikesByCityData = createAsyncThunk(
  'audience/likes-by-city',
  async (data) => await getLikesByCity(data)
);
export const getLikesByGenderData = createAsyncThunk(
  'audience/likes-by-gender',
  async (data) => await getLikesByGender(data)
);
export const getLikesByAgeData = createAsyncThunk(
  'audience/likes-by-age',
  async (data) => await getLikesByAge(data)
);

// Posts & Engagement
export const getPostsByTypeData = createAsyncThunk(
  'Posts & Engagement/posts-by-type',
  async (data) => await getPostsByType(data)
);
export const getTopPostsData = createAsyncThunk(
  'Posts & Engagement/top-posts',
  async (data) => await getTopPosts(data)
);
export const getPostsEngagementImpressionData = createAsyncThunk(
  'Posts & Engagement/posts-engagement-impression',
  async (data) => await getPostsEngagementImpression(data)
);
export const getPostsByAppsData = createAsyncThunk(
  'Posts & Engagement/posts-by-apps',
  async (data) => await getPostsByApps(data)
);
export const getEngagementByTypeData = createAsyncThunk(
  'Posts & Engagement/engagement-by-type',
  async (data) => await getEngagementByType(data)
);
export const getReactionsByTypeData = createAsyncThunk(
  'Posts & Engagement/reactions-by-type',
  async (data) => await getReactionsByType(data)
);
export const getEngagementTypeActionsData = createAsyncThunk(
  'Posts & Engagement/engagement-type-actions',
  async (data) => await getEngagementTypeActions(data)
);

// Reach & impression
export const getPageReachImpressionData = createAsyncThunk(
  'Reach & impression/page-reach-impression',
  async (data) => await getPageReachImpression(data)
);
export const getPaidOrganicReachData = createAsyncThunk(
  'Reach & impression/paid-organic-reach',
  async (data) => await getPaidOrganicReach(data)
);
export const getViralReachImpressionsData = createAsyncThunk(
  'Reach & impression/viral-reach-impressions',
  async (data) => await getViralReachImpressions(data)
);
export const getShareImpressionsByTypeData = createAsyncThunk(
  'Reach & impression/share-impressions-by-type',
  async (data) => await getShareImpressionsByType(data)
);
export const getPageSharesData = createAsyncThunk(
  'Reach & impression/page-shares',
  async (data) => await getPageShares(data)
);
export const getShareByTypeData = createAsyncThunk(
  'Reach & impression/share-by-type',
  async (data) => await getShareByType(data)
);
export const getReachByCountryData = createAsyncThunk(
  'Reach & impression/reach-by-country',
  async (data) => await getReachByCountry(data)
);
export const getReachByLanguageData = createAsyncThunk(
  'Reach & impression/reach-by-language',
  async (data) => await getReachByLanguage(data)
);
export const getReachByCityData = createAsyncThunk(
  'Reach & impression/reach-by-city',
  async (data) => await getReachByCity(data)
);
export const getReachByGenderData = createAsyncThunk(
  'Reach & impression/reach-by-gender',
  async (data) => await getReachByGender(data)
);

//Performance
export const getPageProfileViewsData = createAsyncThunk(
  'Performance/profile-views',
  async (data) => await getPageProfileViews(data)
);
export const getPageTabViewsData = createAsyncThunk(
  'Performance/tab-views',
  async (data) => await getPageTabViews(data)
);
export const getPageViewsByInternalData = createAsyncThunk(
  'Performance/views-by-internal-referral',
  async (data) => await getPageViewsByInternal(data)
);
export const getPageViewsByExternalData = createAsyncThunk(
  'Performance/views-by-external-referral',
  async (data) => await getPageViewsByExternal(data)
);
export const getPageViewsByGenderData = createAsyncThunk(
  'Performance/views-by-gender',
  async (data) => await getPageViewsByGender(data)
);
export const getPageViewsByAgeGroupData = createAsyncThunk(
  'Performance/views-by-age',
  async (data) => await getPageViewsByAgeGroup(data)
);
export const getPageClicksData = createAsyncThunk(
  'Performance/page-clicks',
  async (data) => await getPageClicks(data)
);
export const getPageClicksByPostData = createAsyncThunk(
  'Performance/post-page-clicks',
  async (data) => await getPageClicksByPost(data)
);
export const getPostsPositiveFeedbackData = createAsyncThunk(
  'Performance/posts-positive-feedback',
  async (data) => await getPostsPositiveFeedback(data)
);
export const getPostsNegativeFeedbackData = createAsyncThunk(
  'Performance/posts-negative-feedback',
  async (data) => await getPostsNegativeFeedback(data)
);
export const getCtaClickTypesData = createAsyncThunk(
  'Performance/cta-click-types',
  async (data) => await getCtaClickTypes(data)
);
