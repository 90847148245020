import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TableComponent from '../../TableComponent/TableComponent';
import { MoreHorizOutlined } from '@mui/icons-material';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const heading = [
  {
    heading1: 'Language',
    heading2: 'Likes',
    heading3: 'Likes%',
  },
];
const tabledata = [
  {
    data1: 'Bengaluru',
    data2: '566',
    data3: '76.66%',
  },
  {
    data1: 'Hyderabad',
    data2: '85',
    data3: '9.5%',
  },
  {
    data1: 'Mumbai',
    data2: '14',
    data3: '0.8%',
  },
  {
    data1: 'Chennai',
    data2: '8',
    data3: '0.7%',
  },
  {
    data1: 'Kochi',
    data2: '7',
    data3: '0.7',
  },
  {
    data2: '5',
    data1: 'Delhi',
    data3: '0.5%',
  },
];

function PageReachesByCity() {
  const { reachByCityLoading, reachByCityData } = useSelector(
    (state) => state.facebook
  );
  const top5City =
    reachByCityData &&
    reachByCityData?.data?.length > 0 &&
    reachByCityData?.data?.slice(0, 5);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {reachByCityLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Pages Reach by City
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {reachByCityData?.data?.length > 0 ? (
              <Box sx={{ border: '2px solid #ECECEC', borderRadius: '8px' }}>
                <TableComponent heading={heading} tabledata={top5City} />
              </Box>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PageReachesByCity;
