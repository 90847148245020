import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TableComponent from '../../TableComponent/TableComponent';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import Loader from '../../Loader';

function PageLikesBySoure() {
  const { likeSourcesLoading, likeSourcesData } = useSelector(
    (state) => state.facebook
  );

  const heading = [
    {
      heading1: 'Source',
      heading2: 'Likes',
      heading3: 'Likes%',
    },
  ];
  const tabledata = [
    {
      data1: 'Page Suggestions',
      data2: '100',
      data3: '100%',
    },
    {
      data1: 'Page Suggestions',
      data2: '100',
      data3: '100%',
    },
    {
      data1: 'Page Suggestions',
      data2: '100',
      data3: '100%',
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {likeSourcesLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Pages Likes by Source
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Discuss',
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            <Grid container columns={12}>
              {likeSourcesData?.likesBySource?.length > 0 ? (
                <TableComponent
                  heading={heading}
                  tabledata={likeSourcesData?.likesBySource}
                />
              ) : (
                <NoDataComponent />
              )}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default PageLikesBySoure;
