import { createSlice } from '@reduxjs/toolkit';
import {
  getAudienceSummaryData,
  getPerformanceSummaryData,
  getPostEngagementSummaryData,
  getImpressionsSummaryData,
  getMessengerSummaryData,
  getMessengerConnectionGrowthData,
  getMessengerConnectionLostData,
  getLikeGrowthData,
  getPaidOrganicLikesData,
  getLikeSourcesData,
  getUnlikeSourcesData,
  getLikesByCountyData,
  getLikesByLanguageData,
  getLikesByCityData,
  getLikesByGenderData,
  getLikesByAgeData,
  getPostsByTypeData,
  getTopPostsData,
  getPostsEngagementImpressionData,
  getPostsByAppsData,
  getEngagementByTypeData,
  getReactionsByTypeData,
  getEngagementTypeActionsData,
  getPageReachImpressionData,
  getPaidOrganicReachData,
  getPageProfileViewsData,
  getPageTabViewsData,
  getPageViewsByInternalData,
  getPageViewsByExternalData,
  getPageViewsByGenderData,
  getPageViewsByAgeGroupData,
  getPageClicksData,
  getPageClicksByPostData,
  getPostsPositiveFeedbackData,
  getPostsNegativeFeedbackData,
  getCtaClickTypesData,
  getViralReachImpressionsData,
  getShareImpressionsByTypeData,
  getPageSharesData,
  getShareByTypeData,
  getReachByCountryData,
  getReachByLanguageData,
  getReachByCityData,
  getReachByGenderData,
} from '../reduxThunk/facebook';

const initialState = {
  audienceSummary: {},

  // summary tab:-
  // audienceSummary
  audienceSummaryDataLoading: false,
  audienceSummaryDataError: '',
  audienceSummaryData: [],

  // performanceSummary
  performanceSummaryLoading: false,
  performanceSummaryError: '',
  performanceSummaryData: [],

  // postEngagementSummary
  postEngagementSummaryLoading: false,
  postEngagementSummaryError: '',
  postEngagementSummaryData: [],

  // impressionsSummary
  impressionsSummaryLoading: false,
  impressionsSummaryError: '',
  impressionsSummaryData: [],

  // messengerSummary
  messengerSummaryLoading: false,
  messengerSummaryError: '',
  messengerSummaryData: [],

  // audience
  // likeGrowth
  likeGrowthLoading: false,
  likeGrowthError: '',
  likeGrowthData: [],

  // paidOrganicLikes
  paidOrganicLikesLoading: false,
  paidOrganicLikesError: '',
  paidOrganicLikesData: [],

  // likeSources
  likeSourcesLoading: false,
  likeSourcesError: '',
  likeSourcesData: [],

  // UnlikeSources
  unlikeSourcesLoading: false,
  unlikeSourcesError: '',
  unlikeSourcesData: [],

  // likesByCounty
  likesByCountyLoading: false,
  likesByCountyError: '',
  likesByCountyData: [],

  // likesByLanguage
  likesByLanguageLoading: false,
  likesByLanguageError: '',
  likesByLanguageData: [],

  // likesByCity
  likesByCityLoading: false,
  likesByCityError: '',
  likesByCityData: [],

  // likesByGender
  likesByGenderLoading: false,
  likesByGenderError: '',
  likesByGenderData: [],

  // likesByAge
  likesByAgeLoading: false,
  likesByAgeError: '',
  likesByAgeData: [],

  // Posts & Engagement
  // postsByType
  postsByTypeLoading: false,
  postsByTypeError: '',
  postsByTypeData: [],

  // topPosts
  topPostsLoading: false,
  topPostsError: '',
  topPostsData: [],

  // postsEngagementImpression
  postsEngagementImpressionLoading: false,
  postsEngagementImpressionError: '',
  postsEngagementImpressionData: [],

  // postsByApps
  postsByAppsLoading: false,
  postsByAppsError: '',
  postsByAppsData: [],

  // engagementByType
  engagementByTypeLoading: false,
  engagementByTypeError: '',
  engagementByTypeData: [],

  // reactionsByTypeData
  reactionsByTypeDataLoading: false,
  reactionsByTypeDataError: '',
  reactionsByTypeDataData: [],

  // engagementTypeActions
  engagementTypeActionsLoading: false,
  engagementTypeActionsError: '',
  engagementTypeActionsData: [],

  // Reach & impression
  // pageReachImpression
  pageReachImpressionLoading: false,
  pageReachImpressionError: '',
  pageReachImpressionData: [],

  // paidOrganicReach
  paidOrganicReachLoading: false,
  paidOrganicReachError: '',
  paidOrganicReachData: [],

  // viralReachImpressions
  viralReachImpressionsLoading: false,
  viralReachImpressionsError: '',
  viralReachImpressionsData: [],

  // shareImpressionsByType
  shareImpressionsByTypeLoading: false,
  shareImpressionsByTypeError: '',
  shareImpressionsByTypeData: [],

  // pageSharesData
  pageSharesLoading: false,
  pageSharesError: '',
  pageSharesData: [],

  // shareByType
  shareByTypeLoading: false,
  shareByTypeError: '',
  shareByTypeData: [],

  //  reachByCountry
  reachByCountryLoading: false,
  reachByCountryError: '',
  reachByCountryData: [],

  // reachByLanguage
  reachByLanguageLoading: false,
  reachByLanguageError: '',
  reachByLanguageData: [],

  // reachByCity
  reachByCityLoading: false,
  reachByCityError: '',
  reachByCityData: [],

  // reachByGender
  reachByGenderLoading: false,
  reachByGenderError: '',
  reachByGenderData: [],

  // messenger tab:-
  // messengerConnectionGrowth
  messengerConnectionGrowthLoading: false,
  messengerConnectionGrowthError: '',
  messengerConnectionGrowthData: [],

  // paidOrganicReach
  paidOrganicReachLoading: false,
  paidOrganicReachError: '',
  paidOrganicReachData: [],

  // messengerConnectionLost
  messengerConnectionLostLoading: false,
  messengerConnectionLostError: '',
  messengerConnectionLostData: [],

  // Performance Tabs
  pageProfileViewsLoading: false,
  pageProfileViewsError: '',
  pageProfileViewsData: {},

  pageTabViewsLoading: false,
  pageTabViewsError: '',
  pageTabViewsData: {},

  pageViewsByInternalLoading: false,
  pageViewsByInternalError: '',
  pageViewsByInternalData: {},

  pageViewsByExternalLoading: false,
  pageViewsByExternalError: '',
  pageViewsByExternalData: {},

  pageViewsByGenderLoading: false,
  pageViewsByGenderError: '',
  pageViewsByGenderData: {},

  pageViewsByAgeGroupLoading: false,
  pageViewsByAgeGroupError: '',
  pageViewsByAgeGroupData: {},

  pageClicksLoading: false,
  pageClicksError: '',
  pageClicksData: {},

  pageClicksByPostLoading: false,
  pageClicksByPostError: '',
  pageClicksByPostData: {},

  postsPositiveFeedbackLoading: false,
  postsPositiveFeedbackError: '',
  postsPositiveFeedbackData: {},

  postsNegativeFeedbackLoading: false,
  postsNegativeFeedbackError: '',
  postsNegativeFeedbackData: {},

  ctaClickTypesLoading: false,
  ctaClickTypesError: '',
  ctaClickTypesData: {},
};

export const facebookSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {
    handleAudienceSummary: (state, action) => {
      state.audienceSummary = action.payload;
    },
  },

  // To handle Async data through redux toolkit

  extraReducers: (builder) => {
    // summary tab
    builder.addCase(getAudienceSummaryData.pending, (state, action) => {
      state.audienceSummaryDataLoading = true;
    });
    builder.addCase(getAudienceSummaryData.fulfilled, (state, action) => {
      state.audienceSummaryDataLoading = false;
      state.audienceSummaryData = action.payload.data;
    });
    builder.addCase(getAudienceSummaryData.rejected, (state, action) => {
      state.audienceSummaryDataLoading = false;
      state.audienceSummaryDataError = 'error data';
      state.audienceSummaryData = [];
    });
    builder.addCase(getPerformanceSummaryData.pending, (state, action) => {
      state.performanceSummaryLoading = true;
    });
    builder.addCase(getPerformanceSummaryData.fulfilled, (state, action) => {
      state.performanceSummaryLoading = false;
      state.performanceSummaryData = action.payload.data;
    });
    builder.addCase(getPerformanceSummaryData.rejected, (state, action) => {
      state.performanceSummaryLoading = false;
      state.performanceSummaryError = 'error data';
      state.performanceSummaryData = [];
    });
    builder.addCase(getPostEngagementSummaryData.pending, (state, action) => {
      state.postEngagementSummaryLoading = true;
    });
    builder.addCase(getPostEngagementSummaryData.fulfilled, (state, action) => {
      state.postEngagementSummaryLoading = false;
      state.postEngagementSummaryData = action.payload.data;
    });
    builder.addCase(getPostEngagementSummaryData.rejected, (state, action) => {
      state.postEngagementSummaryLoading = false;
      state.postEngagementSummaryError = 'error data';
      state.postEngagementSummaryData = [];
    });
    builder.addCase(getImpressionsSummaryData.pending, (state, action) => {
      state.impressionsSummaryLoading = true;
    });
    builder.addCase(getImpressionsSummaryData.fulfilled, (state, action) => {
      state.impressionsSummaryLoading = false;
      state.impressionsSummaryData = action.payload.data;
    });
    builder.addCase(getImpressionsSummaryData.rejected, (state, action) => {
      state.impressionsSummaryLoading = false;
      state.impressionsSummaryError = 'error data';
      state.impressionsSummaryData = [];
    });
    builder.addCase(getMessengerSummaryData.pending, (state, action) => {
      state.messengerSummaryLoading = true;
    });
    builder.addCase(getMessengerSummaryData.fulfilled, (state, action) => {
      state.messengerSummaryLoading = false;
      state.messengerSummaryData = action.payload.data;
    });
    builder.addCase(getMessengerSummaryData.rejected, (state, action) => {
      state.messengerSummaryLoading = false;
      state.messengerSummaryError = 'error data';
      state.messengerSummaryData = [];
    });
    // audience
    builder.addCase(getLikeGrowthData.pending, (state, action) => {
      state.likeGrowthLoading = true;
    });
    builder.addCase(getLikeGrowthData.fulfilled, (state, action) => {
      state.likeGrowthLoading = false;
      state.likeGrowthData = action.payload.data;
    });
    builder.addCase(getLikeGrowthData.rejected, (state, action) => {
      state.likeGrowthLoading = false;
      state.likeGrowthError = 'error data';
      state.likeGrowthData = [];
    });
    builder.addCase(getPaidOrganicLikesData.pending, (state, action) => {
      state.paidOrganicLikesLoading = true;
    });
    builder.addCase(getPaidOrganicLikesData.fulfilled, (state, action) => {
      state.paidOrganicLikesLoading = false;
      state.paidOrganicLikesData = action.payload.data;
    });
    builder.addCase(getPaidOrganicLikesData.rejected, (state, action) => {
      state.paidOrganicLikesLoading = false;
      state.paidOrganicLikesError = 'error data';
      state.paidOrganicLikesData = [];
    });
    builder.addCase(getLikeSourcesData.pending, (state, action) => {
      state.likeSourcesLoading = true;
    });
    builder.addCase(getLikeSourcesData.fulfilled, (state, action) => {
      state.likeSourcesLoading = false;
      state.likeSourcesData = action.payload.data;
    });
    builder.addCase(getLikeSourcesData.rejected, (state, action) => {
      state.likeSourcesLoading = false;
      state.likeSourcesError = 'error data';
      state.likeSourcesData = [];
    });
    builder.addCase(getUnlikeSourcesData.pending, (state, action) => {
      state.unlikeSourcesLoading = true;
    });
    builder.addCase(getUnlikeSourcesData.fulfilled, (state, action) => {
      state.unlikeSourcesLoading = false;
      state.unlikeSourcesData = action.payload.data;
    });
    builder.addCase(getUnlikeSourcesData.rejected, (state, action) => {
      state.unlikeSourcesLoading = false;
      state.unlikeSourcesError = 'error data';
      state.unlikeSourcesData = [];
    });
    builder.addCase(getLikesByCountyData.pending, (state, action) => {
      state.likesByCountyLoading = true;
    });
    builder.addCase(getLikesByCountyData.fulfilled, (state, action) => {
      state.likesByCountyLoading = false;
      state.likesByCountyData = action.payload.data;
    });
    builder.addCase(getLikesByCountyData.rejected, (state, action) => {
      state.likesByCountyLoading = false;
      state.likesByCountyError = 'error data';
      state.likesByCountyData = [];
    });
    builder.addCase(getLikesByLanguageData.pending, (state, action) => {
      state.likesByLanguageLoading = true;
    });
    builder.addCase(getLikesByLanguageData.fulfilled, (state, action) => {
      state.likesByLanguageLoading = false;
      state.likesByLanguageData = action.payload.data;
    });
    builder.addCase(getLikesByLanguageData.rejected, (state, action) => {
      state.likesByLanguageLoading = false;
      state.likesByLanguageError = 'error data';
      state.likesByLanguageData = [];
    });
    builder.addCase(getLikesByCityData.pending, (state, action) => {
      state.likesByCityLoading = true;
    });
    builder.addCase(getLikesByCityData.fulfilled, (state, action) => {
      state.likesByCityLoading = false;
      state.likesByCityData = action.payload.data;
    });
    builder.addCase(getLikesByCityData.rejected, (state, action) => {
      state.likesByCityLoading = false;
      state.likesByCityError = 'error data';
      state.likesByCityData = [];
    });
    builder.addCase(getLikesByGenderData.pending, (state, action) => {
      state.likesByGenderLoading = true;
    });
    builder.addCase(getLikesByGenderData.fulfilled, (state, action) => {
      state.likesByGenderLoading = false;
      state.likesByGenderData = action.payload.data;
    });
    builder.addCase(getLikesByGenderData.rejected, (state, action) => {
      state.likesByGenderLoading = false;
      state.likesByGenderError = 'error data';
      state.likesByGenderData = [];
    });
    builder.addCase(getLikesByAgeData.pending, (state, action) => {
      state.likesByAgeLoading = true;
    });
    builder.addCase(getLikesByAgeData.fulfilled, (state, action) => {
      state.likesByAgeLoading = false;
      state.likesByAgeData = action.payload.data;
    });
    builder.addCase(getLikesByAgeData.rejected, (state, action) => {
      state.likesByAgeLoading = false;
      state.likesByAgeError = 'error data';
      state.likesByAgeData = [];
    });
    // Posts & Engagement
    builder.addCase(getPostsByTypeData.pending, (state, action) => {
      state.postsByTypeLoading = true;
    });
    builder.addCase(getPostsByTypeData.fulfilled, (state, action) => {
      state.postsByTypeLoading = false;
      state.postsByTypeData = action.payload.data;
    });
    builder.addCase(getPostsByTypeData.rejected, (state, action) => {
      state.postsByTypeLoading = false;
      state.postsByTypeError = 'error data';
      state.postsByTypeData = [];
    });
    builder.addCase(getTopPostsData.pending, (state, action) => {
      state.topPostsLoading = true;
    });
    builder.addCase(getTopPostsData.fulfilled, (state, action) => {
      state.topPostsLoading = false;
      state.topPostsData = action.payload.data;
    });
    builder.addCase(getTopPostsData.rejected, (state, action) => {
      state.topPostsLoading = false;
      state.topPostsError = 'error data';
      state.topPostsData = [];
    });
    builder.addCase(
      getPostsEngagementImpressionData.pending,
      (state, action) => {
        state.postsEngagementImpressionLoading = true;
      }
    );
    builder.addCase(
      getPostsEngagementImpressionData.fulfilled,
      (state, action) => {
        state.postsEngagementImpressionLoading = false;
        state.postsEngagementImpressionData = action.payload.data;
      }
    );
    builder.addCase(
      getPostsEngagementImpressionData.rejected,
      (state, action) => {
        state.postsEngagementImpressionLoading = false;
        state.postsEngagementImpressionError = 'error data';
        state.postsEngagementImpressionData = [];
      }
    );
    builder.addCase(getPostsByAppsData.pending, (state, action) => {
      state.postsByAppsLoading = true;
    });
    builder.addCase(getPostsByAppsData.fulfilled, (state, action) => {
      state.postsByAppsLoading = false;
      state.postsByAppsData = action.payload.data;
    });
    builder.addCase(getPostsByAppsData.rejected, (state, action) => {
      state.postsByAppsLoading = false;
      state.postsByAppsError = 'error data';
      state.postsByAppsData = [];
    });
    builder.addCase(getEngagementByTypeData.pending, (state, action) => {
      state.engagementByTypeLoading = true;
    });
    builder.addCase(getEngagementByTypeData.fulfilled, (state, action) => {
      state.engagementByTypeLoading = false;
      state.engagementByTypeData = action.payload.data;
    });
    builder.addCase(getEngagementByTypeData.rejected, (state, action) => {
      state.engagementByTypeLoading = false;
      state.engagementByTypeError = 'error data';
      state.engagementByTypeData = [];
    });

    builder.addCase(getReactionsByTypeData.pending, (state, action) => {
      state.reactionsByTypeDataLoading = true;
    });
    builder.addCase(getReactionsByTypeData.fulfilled, (state, action) => {
      state.reactionsByTypeDataLoading = false;
      state.reactionsByTypeDataData = action.payload.data;
    });
    builder.addCase(getReactionsByTypeData.rejected, (state, action) => {
      state.reactionsByTypeDataLoading = false;
      state.reactionsByTypeDataError = 'error data';
      state.reactionsByTypeDataData = [];
    });
    builder.addCase(getEngagementTypeActionsData.pending, (state, action) => {
      state.engagementTypeActionsLoading = true;
    });
    builder.addCase(getEngagementTypeActionsData.fulfilled, (state, action) => {
      state.engagementTypeActionsLoading = false;
      state.engagementTypeActionsData = action.payload.data;
    });
    builder.addCase(getEngagementTypeActionsData.rejected, (state, action) => {
      state.engagementTypeActionsLoading = false;
      state.engagementTypeActionsError = 'error data';
      state.engagementTypeActionsData = [];
    });
    // PageReachImpression
    builder.addCase(getPageReachImpressionData.pending, (state, action) => {
      state.pageReachImpressionLoading = true;
    });
    builder.addCase(getPageReachImpressionData.fulfilled, (state, action) => {
      state.pageReachImpressionLoading = false;
      state.pageReachImpressionData = action.payload.data;
    });
    builder.addCase(getPageReachImpressionData.rejected, (state, action) => {
      state.pageReachImpressionLoading = false;
      state.pageReachImpressionError = 'error data';
      state.pageReachImpressionData = [];
    });
    builder.addCase(getPaidOrganicReachData.pending, (state, action) => {
      state.paidOrganicReachLoading = true;
    });
    builder.addCase(getPaidOrganicReachData.fulfilled, (state, action) => {
      state.paidOrganicReachLoading = false;
      state.paidOrganicReachData = action.payload.data;
    });
    builder.addCase(getPaidOrganicReachData.rejected, (state, action) => {
      state.paidOrganicReachLoading = false;
      state.paidOrganicReachError = 'error data';
      state.paidOrganicReachData = [];
    });
    builder.addCase(getViralReachImpressionsData.pending, (state, action) => {
      state.viralReachImpressionsLoading = true;
    });
    builder.addCase(getViralReachImpressionsData.fulfilled, (state, action) => {
      state.viralReachImpressionsLoading = false;
      state.viralReachImpressionsData = action.payload.data;
    });
    builder.addCase(getViralReachImpressionsData.rejected, (state, action) => {
      state.viralReachImpressionsLoading = false;
      state.viralReachImpressionsError = 'error data';
      state.viralReachImpressionsData = [];
    });
    builder.addCase(getShareImpressionsByTypeData.pending, (state, action) => {
      state.shareImpressionsByTypeLoading = true;
    });
    builder.addCase(
      getShareImpressionsByTypeData.fulfilled,
      (state, action) => {
        state.shareImpressionsByTypeLoading = false;
        state.shareImpressionsByTypeData = action.payload.data;
      }
    );
    builder.addCase(getShareImpressionsByTypeData.rejected, (state, action) => {
      state.shareImpressionsByTypeLoading = false;
      state.shareImpressionsByTypeError = 'error data';
      state.shareImpressionsByTypeData = [];
    });
    builder.addCase(getPageSharesData.pending, (state, action) => {
      state.pageSharesLoading = true;
    });
    builder.addCase(getPageSharesData.fulfilled, (state, action) => {
      state.pageSharesTypeLoading = false;
      state.pageSharesTypeData = action.payload.data;
    });
    builder.addCase(getPageSharesData.rejected, (state, action) => {
      state.pageSharesLoading = false;
      state.pageSharesError = 'error data';
      state.pageSharesData = [];
    });
    builder.addCase(getShareByTypeData.pending, (state, action) => {
      state.shareByTypeLoading = true;
    });
    builder.addCase(getShareByTypeData.fulfilled, (state, action) => {
      state.shareByTypeLoading = false;
      state.shareByTypeData = action.payload.data;
    });
    builder.addCase(getShareByTypeData.rejected, (state, action) => {
      state.shareByTypeLoading = false;
      state.shareByTypeError = 'error data';
      state.shareByTypeData = [];
    });
    builder.addCase(getReachByCountryData.pending, (state, action) => {
      state.reachByCountryLoading = true;
    });
    builder.addCase(getReachByCountryData.fulfilled, (state, action) => {
      state.reachByCountryLoading = false;
      state.reachByCountryData = action.payload.data;
    });
    builder.addCase(getReachByCountryData.rejected, (state, action) => {
      state.reachByCountryLoading = false;
      state.reachByCountryError = 'error data';
      state.reachByCountryData = [];
    });
    builder.addCase(getReachByLanguageData.pending, (state, action) => {
      state.reachByLanguageLoading = true;
    });
    builder.addCase(getReachByLanguageData.fulfilled, (state, action) => {
      state.reachByLanguageLoading = false;
      state.reachByLanguageData = action.payload.data;
    });
    builder.addCase(getReachByLanguageData.rejected, (state, action) => {
      state.reachByLanguageLoading = false;
      state.reachByLanguageError = 'error data';
      state.reachByLanguageData = [];
    });
    builder.addCase(getReachByCityData.pending, (state, action) => {
      state.reachByCityLoading = true;
    });
    builder.addCase(getReachByCityData.fulfilled, (state, action) => {
      state.reachByCityLoading = false;
      state.reachByCityData = action.payload.data;
    });
    builder.addCase(getReachByCityData.rejected, (state, action) => {
      state.reachByCityLoading = false;
      state.reachByCityError = 'error data';
      state.reachByCityData = [];
    });
    builder.addCase(getReachByGenderData.pending, (state, action) => {
      state.reachByGenderLoading = true;
    });
    builder.addCase(getReachByGenderData.fulfilled, (state, action) => {
      state.reachByGenderLoading = false;
      state.reachByGenderData = action.payload.data;
    });
    builder.addCase(getReachByGenderData.rejected, (state, action) => {
      state.reachByGenderLoading = false;
      state.reachByGenderError = 'error data';
      state.reachByGenderData = [];
    });
    // messenger
    builder.addCase(
      getMessengerConnectionGrowthData.pending,
      (state, action) => {
        state.messengerConnectionGrowthLoading = true;
      }
    );
    builder.addCase(
      getMessengerConnectionGrowthData.fulfilled,
      (state, action) => {
        state.messengerConnectionGrowthLoading = false;
        state.messengerConnectionGrowthData = action.payload.data;
      }
    );
    builder.addCase(
      getMessengerConnectionGrowthData.rejected,
      (state, action) => {
        state.messengerConnectionGrowthLoading = false;
        state.messengerConnectionGrowthError = 'error data';
        state.messengerConnectionGrowthData = [];
      }
    );
    builder.addCase(getMessengerConnectionLostData.pending, (state, action) => {
      state.messengerConnectionLostLoading = true;
    });
    builder.addCase(
      getMessengerConnectionLostData.fulfilled,
      (state, action) => {
        state.messengerConnectionLostLoading = false;
        state.messengerConnectionLostData = action.payload.data;
      }
    );
    builder.addCase(
      getMessengerConnectionLostData.rejected,
      (state, action) => {
        state.messengerConnectionLostLoading = false;
        state.messengerConnectionLostError = 'error data';
        state.messengerConnectionLostData = [];
      }
    );

    // Performance
    builder.addCase(getPageProfileViewsData.pending, (state, action) => {
      state.pageProfileViewsLoading = true;
    });
    builder.addCase(getPageProfileViewsData.fulfilled, (state, action) => {
      state.pageProfileViewsLoading = false;
      state.pageProfileViewsData = action.payload.data;
    });
    builder.addCase(getPageProfileViewsData.rejected, (state, action) => {
      state.pageProfileViewsLoading = false;
      state.pageProfileViewsError = 'error data';
      state.pageProfileViewsData = {};
    });

    builder.addCase(getPageTabViewsData.pending, (state, action) => {
      state.pageTabViewsLoading = true;
    });
    builder.addCase(getPageTabViewsData.fulfilled, (state, action) => {
      state.pageTabViewsLoading = false;
      state.pageTabViewsData = action.payload.data;
    });
    builder.addCase(getPageTabViewsData.rejected, (state, action) => {
      state.pageTabViewsLoading = false;
      state.pageTabViewsError = 'error data';
      state.pageTabViewsData = {};
    });

    builder.addCase(getPageViewsByInternalData.pending, (state, action) => {
      state.pageViewsByInternalLoading = true;
    });
    builder.addCase(getPageViewsByInternalData.fulfilled, (state, action) => {
      state.pageViewsByInternalLoading = false;
      state.pageViewsByInternalData = action.payload.data;
    });
    builder.addCase(getPageViewsByInternalData.rejected, (state, action) => {
      state.pageViewsByInternalLoading = false;
      state.pageViewsByInternalError = 'error data';
      state.pageViewsByInternalData = {};
    });

    builder.addCase(getPageViewsByExternalData.pending, (state, action) => {
      state.pageViewsByExternalLoading = true;
    });
    builder.addCase(getPageViewsByExternalData.fulfilled, (state, action) => {
      state.pageViewsByExternalLoading = false;
      state.pageViewsByExternalData = action.payload.data;
    });
    builder.addCase(getPageViewsByExternalData.rejected, (state, action) => {
      state.pageViewsByExternalLoading = false;
      state.pageViewsByExternalError = 'error data';
      state.pageViewsByExternalData = {};
    });

    builder.addCase(getPageViewsByGenderData.pending, (state, action) => {
      state.pageViewsByGenderLoading = true;
    });
    builder.addCase(getPageViewsByGenderData.fulfilled, (state, action) => {
      state.pageViewsByGenderLoading = false;
      state.pageViewsByGenderData = action.payload.data;
    });
    builder.addCase(getPageViewsByGenderData.rejected, (state, action) => {
      state.pageViewsByGenderLoading = false;
      state.pageViewsByGenderError = 'error data';
      state.pageViewsByGenderData = {};
    });

    builder.addCase(getPageViewsByAgeGroupData.pending, (state, action) => {
      state.pageViewsByAgeGroupLoading = true;
    });
    builder.addCase(getPageViewsByAgeGroupData.fulfilled, (state, action) => {
      state.pageViewsByAgeGroupLoading = false;
      state.pageViewsByAgeGroupData = action.payload.data;
    });
    builder.addCase(getPageViewsByAgeGroupData.rejected, (state, action) => {
      state.pageViewsByAgeGroupLoading = false;
      state.pageViewsByAgeGroupError = 'error data';
      state.pageViewsByAgeGroupData = {};
    });

    builder.addCase(getPageClicksData.pending, (state, action) => {
      state.pageClicksLoading = true;
    });
    builder.addCase(getPageClicksData.fulfilled, (state, action) => {
      state.pageClicksLoading = false;
      state.pageClicksData = action.payload.data;
    });
    builder.addCase(getPageClicksData.rejected, (state, action) => {
      state.pageClicksLoading = false;
      state.pageClicksError = 'error data';
      state.pageClicksData = {};
    });

    builder.addCase(getPageClicksByPostData.pending, (state, action) => {
      state.pageClicksByPostLoading = true;
    });
    builder.addCase(getPageClicksByPostData.fulfilled, (state, action) => {
      state.pageClicksByPostLoading = false;
      state.pageClicksByPostData = action.payload.data;
    });
    builder.addCase(getPageClicksByPostData.rejected, (state, action) => {
      state.pageClicksByPostLoading = false;
      state.pageClicksByPostError = 'error data';
      state.pageClicksByPostData = {};
    });

    builder.addCase(getPostsPositiveFeedbackData.pending, (state, action) => {
      state.postsPositiveFeedbackLoading = true;
    });
    builder.addCase(getPostsPositiveFeedbackData.fulfilled, (state, action) => {
      state.postsPositiveFeedbackLoading = false;
      state.postsPositiveFeedbackData = action.payload.data;
    });
    builder.addCase(getPostsPositiveFeedbackData.rejected, (state, action) => {
      state.postsPositiveFeedbackLoading = false;
      state.postsPositiveFeedbackError = 'error data';
      state.postsPositiveFeedbackData = {};
    });

    builder.addCase(getPostsNegativeFeedbackData.pending, (state, action) => {
      state.postsNegativeFeedbackLoading = true;
    });
    builder.addCase(getPostsNegativeFeedbackData.fulfilled, (state, action) => {
      state.postsNegativeFeedbackLoading = false;
      state.postsNegativeFeedbackData = action.payload.data;
    });
    builder.addCase(getPostsNegativeFeedbackData.rejected, (state, action) => {
      state.postsNegativeFeedbackLoading = false;
      state.postsNegativeFeedbackError = 'error data';
      state.postsNegativeFeedbackData = {};
    });

    builder.addCase(getCtaClickTypesData.pending, (state, action) => {
      state.ctaClickTypesLoading = true;
    });
    builder.addCase(getCtaClickTypesData.fulfilled, (state, action) => {
      state.ctaClickTypesLoading = false;
      state.ctaClickTypesData = action.payload.data;
    });
    builder.addCase(getCtaClickTypesData.rejected, (state, action) => {
      state.ctaClickTypesLoading = false;
      state.ctaClickTypesError = 'error data';
      state.ctaClickTypesData = {};
    });
  },
});

// Action creators are generated for each case reducer function
export const { handleAudienceSummary } = facebookSlice.actions;

export default facebookSlice.reducer;
