import React, { useState, useEffect, useMemo } from 'react'
import { Avatar, Grid } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from '@mui/icons-material/Home';
import { logox, GreetKaroIcon, FacebookIcon, InstagramIcon, TwitterIcon, MyBusinessIcon } from '../../assets/assets/assets';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import List from '@mui/material/List';
import { Typography, ListItem, ListItemButton, ListItemIcon, Button } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import "./style.css"
import Header from "./Header";
import { setCookie, getCookie, removeCookie } from "../../helpers/cookie";
import { handleToken, handleRestRedux } from "../../redux/Slice/user"
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import socialXperia_logo from "../../assets/images/logo_black.png";
import { sidebarStyles } from "../../Themes/sideBarTheme";
import { Select, FormControl, InputLabel } from '@mui/material';
import {
    getBrandSettingData,
    getAllBrandData
} from '../../redux/reduxThunk';

import SelectSocialDropdown from "./SelectSocialDropdown";
import * as api from "../../redux/Api";

function SideBar() {
    const { userData, accountSettingsData, brandSettingData, currentPlanData, allBrandData } = useSelector((state) => state.user)
    const sidebarClass = sidebarStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedValue, setSelectedValue] = useState(brandSettingData?.name)
    const [userplanStatus, setUserPlanStatus] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSettings = () => {
        setCookie("billing_tab_value", 0);
        setCookie('sidebar_activeItem', "Account settings");
        window.location.href = `${api.onboardUrl}/account-settings`
    };
    const handleAddBrand = () => {
        localStorage.setItem('activeStep', 0);
        window.location.href = `${api.onboardUrl}/social-brand-accounts`
    };
    const handleUpgradeBrand = () => {
        setCookie('billing_tab_value', 3);
        window.location.href = `${api.onboardUrl}/account-settings`
    };
    const handleLogout = () => {
        dispatch(handleRestRedux())
        window.location.href = `${api.onboardUrl}/sign-in`
        dispatch(handleToken(false))
        removeCookie('token')
        localStorage.clear()
        Object.keys(Cookies.set()).forEach(function (cookieName) {
            Cookies.remove(cookieName);
        });
    }
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (currentPlanData?.planDetails?.brandLimit > allBrandData?.length) {
            setUserPlanStatus(true);
        }
    };
    const createdBrandId = getCookie('created_brand_id');
    let brandId = '';
    if (userData?.accountType === 'SUPER_ADMIN') {
        if (createdBrandId === undefined) {
            brandId =
                allBrandData && allBrandData?.length > 0 && allBrandData[0]?._id;
        } else {
            brandId = createdBrandId;
        }
    } else {
        if (createdBrandId === undefined) {
            brandId =
                allBrandData && allBrandData?.length > 0 && allBrandData[0]?.brandId;
        } else {
            brandId = createdBrandId;
        }
    }

    if (brandId) {
        setCookie('created_brand_id', brandId);
    }
    useEffect(() => {
        dispatch(getBrandSettingData(brandId));
        dispatch(getAllBrandData(userData?.accountType));
        console.log("test useEffect", allBrandData)
    }, [brandId]);
    // useEffect(() => {
    //     dispatch(getAllBrandData(userData?.accountType));
    //     console.log("test useEffect1",allBrandData)
    // }, []);
    // useMemo(() => {
    //     dispatch(getAllBrandData(userData?.accountType));
    //     console.log("test useMemo",allBrandData)
    // },[])
    return (
        <>
            <Grid className={sidebarClass.sidebar_header} >
                <Grid className={sidebarClass.sidebar_grid}>
                    <Box>
                        <img src={socialXperia_logo} alt="socialXperia_logo" className={sidebarClass.sidebar_logo} />
                    </Box>
                    <Box>
                        <SelectSocialDropdown brandSettingData={brandSettingData} allBrandData={allBrandData} />
                        {/* <FormControl sx={{ width: '212px', height: '55px', marginLeft: '50px' }}>
                            <Select
                                defaultValue={selectedValue}
                                onChange={handleChange}
                                name="scheduleType"
                                sx={{
                                    height: '100%',
                                    px: 2,
                                    '& .MuiSelect-select': {
                                        textAlign: 'center',
                                        display: 'contents',
                                        fontSize: '16px',
                                        fontWeight: "600"
                                    },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                            >
                                {allBrandData?.map((brands) => (
                                    <MenuItem key={brands.id} value={brands.name} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ height: '40px', width: '40px', mr: 1, fontSize: '16px', fontWeight: "600" }}>
                                            {brands.name[0]}
                                        </Avatar>
                                        <Typography sx={{ fontSize: '16px', fontWeight: "600" }}>{brands.name}</Typography>
                                    </MenuItem>
                                ))}
                                {userplanStatus ? (
                                    <Button
                                        className="brand-drop-down-button brand-dropdown-list-button"
                                        onClick={handleAddBrand}
                                    >
                                        Add Brands
                                    </Button>
                                ) : (
                                    <Button
                                        className="brand-drop-down-button brand-dropdown-list-button"
                                        onClick={handleUpgradeBrand}
                                    >
                                        Upgrade
                                    </Button>
                                )}
                            </Select>
                        </FormControl> */}
                    </Box>
                </Grid>
                <Grid className={sidebarClass.sidebar_grid}>
                    <Box >
                        <NotificationsIcon fontSize='large' className={sidebarClass.avatar} sx={{ color: '#7b7b7b', mr: "24px", mt: "7px" }} />
                    </Box>
                    <Box className={sidebarClass.sidebar_grid}>
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            {accountSettingsData?.accountInformation?.profileImage ?
                                <Avatar src={accountSettingsData?.accountInformation?.profileImage} className={sidebarClass.avatar}></Avatar>
                                : <Avatar className={sidebarClass.avatar} >{userData?.emailId ? userData?.emailId[0] : 'M'}</Avatar>}

                        </IconButton>

                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >


                        <MenuItem onClick={handleSettings}>
                            <ListItemIcon>
                                <ManageAccountsIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant='h6'>
                                Account settings
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            <Typography variant='h6'>
                                Logout
                            </Typography>

                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </>
    )
}

export default SideBar