import React from 'react'
import Navigation from '../../pages';
import { useDispatch, useSelector } from 'react-redux';

function FacebookAnalytic() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  let title = '';
  if (audienceSummary.name === 'facebookPage') {
    title = 'Facebook Reports';
  } else if (audienceSummary.name === 'instagram') {
    title = 'Instagram Reports';
  } else if (audienceSummary.name === 'CustomReports') {
    title = 'Custom Reports';
  }
  return <Navigation title={title} />;
  
  
}

export default FacebookAnalytic