import { MoreHorizOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';
import { useDispatch, useSelector } from 'react-redux';

function NegativeFeedback() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { postsNegativeFeedbackLoading, postsNegativeFeedbackData } =
    useSelector((state) => state.facebook);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {postsNegativeFeedbackLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Negative Feedback
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {postsNegativeFeedbackData?.data?.length > 0 ? (
              <>
                <DoughnutChart
                  doughnutData={postsNegativeFeedbackData?.doughnutData}
                  dataKey="value"
                />
                <TableComponent
                  heading={heading}
                  tabledata={postsNegativeFeedbackData?.data}
                />
              </>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default NegativeFeedback;
