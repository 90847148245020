import React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import './style.css';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 26,
  height: 26,
  border: `2px solid ${theme.palette.background.paper}`,
}));

function NestedAvatar({ src1, src2 }) {
  return (
    <>
      <Badge
        className="social-account"
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<SmallAvatar alt="Remy Sharp" src={src2} />}
      >
        <Avatar alt="Travis Howard" src={src1} />
      </Badge>
    </>
  );
}

export default NestedAvatar;
