import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from "../../Common/NoDataComponent";
import Loader from '../../Loader';

const data = [
  {
    name: "16 MAR' 23",
    posts: 0,
    engagement: 800,
  },
  {
    name: "17 MAR' 23",
    posts: 800,
    engagement: 967,
  },
  {
    name: "18 MAR' 23",
    posts: 1397,
    engagement: 1098,
  },
  {
    name: "19 MAR' 23",
    posts: 1480,
    engagement: 1200,
  },
  {
    name: "20 MAR' 23",
    posts: 1520,
    engagement: 1108,
},
{
    name: "21 MAR' 23",
    posts: 0,
    engagement: 680,
},
{
    name: "22 MAR' 23",
    posts: 0,
    engagement: 680,
},
];
function NumofPostsVsEngagement() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const {postsEngagementImpressionLoading,postsEngagementImpressionData} = useSelector((state)=>state.facebook)
 
    return (<>
       
            <Box sx={{
                width: '100%',
                height:"100%",
                bgcolor: '#fff',
                p: 4,
                border: '2px solid #ECECEC',
                borderRadius: '8px',
                mb: '10px',
            }}>
                 {postsEngagementImpressionLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
                <>
                <Grid display='flex' justifyContent='space-between' mb={4}>
                    <Typography variant='h4' color='#000' fontWeight={600}>
                        Number of Posts Vs Engagegement
                    </Typography>
                    <MoreHorizOutlined fontSize='large' />
                </Grid>
                <MenuComponent
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            displayItems={[
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
            ]}
          />
                  {postsEngagementImpressionData?.data?.length>0 ? 
                <BarAndAreaChart
                data={postsEngagementImpressionData?.data}
                XAxis_dataKey='day'
                barDataKey='postCount'
                area1DataKey='engagementCount'
                YAxis_Label='Number Of Posts'
                />
                : <NoDataComponent />} 
                </>
}
            </Box>
       
    </>
  );
}

export default NumofPostsVsEngagement;
