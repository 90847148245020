import { createSlice } from '@reduxjs/toolkit';
import {
  getAllBrandData,
  getAllSocialAccountData,
  getSocialAccountsSettingsData,
  getUserData,
  getCurrentPlanDetailsApiData,
  getFacebookPagePostsData,
  getSocialPagePostsData,
  getBrandSettingData
} from '../reduxThunk';
const getCurrentStep = () => {
  const currentStep = localStorage.getItem('activeStep');
  if (currentStep === undefined) {
    localStorage.setItem('activeStep', 0);
    return 0;
  } else if (currentStep < 0 || currentStep > 2) {
    return 0;
  } else {
    return currentStep;
  }
};

const initialState = {
  user: {},
  userLoader: false,
  activeStep: getCurrentStep() || 0,
  isOtpModal: false,
  validateOtpDialog: false,
  isEmailChange: false,
  isMobileChange: false,
  validateEmail: false,
  validateMobile: false,
  signedUserData: {},
  signedUserLoader: false,

  // Account setting states
  accountSettingsData: {},
  accountSettingsLoader: false,
  accountSettingsError: '',
  notificationsData: {},
  notificationsLoader: false,
  currentPlanData: {},
  currentPlanLoader: false,
  billingPlanData: {},
  billingPlanLoader: false,
  invoiceData: [],
  invoiceLoader: false,
  paymentPriceModel: false,

  // members
  membersLoader: false,
  membersData: [],

  userBrandInfo: {},
  // Brand:

  allBrandLoading: false,
  allBrandData: [],
  allBrandError: '',

  brandId: '',
  defaultSelectBrand: '',

  token: false,

  allSocialAccountLoading: false,
  allSocialAccountError: '',
  allSocialAccountData: [],

  selectedSocialAccountId: '',
  selectedSocialAccountType: '',

  // social account settings
  socialAccountSettingsLoading: false,
  socialAccountSettingsError: '',
  socialAccountSettingsData: [],

  //user data
  userDataLoading: false,
  userDataError: '',
  userData: [],

  // current plan data:-
  CurrentPlanDetailsDataLoading: false,
  CurrentPlanDetailsDataError: '',
  CurrentPlanDetailsData: [],

  // Conversations States
  socialPostsLoader: false,
  socialPostsData: [],
  socialPostsError: '',
  selectedPost: {},
  selectedSocialAccount: {},
  activeDiv: 0,

  // Analytics States
  instagram: false,
  facebook: true,

  // account info
  accountSettingsData: {},

    // Brand:
    brandSettingLoading: false,
    brandSettingData: [],
    brandSettingError: '',
  
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    handleCurrentPlanData: (state, action) => {
      state.currentPlanData = action.payload;
    },
    handleCurrentPlanLoader: (state, action) => {
      state.currentPlanLoader = action.payload;
    },
    handleSelectedBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    handleToken: (state, action) => {
      state.token = action.payload;
    },
    handleSelectedSocialAccountId: (state, action) => {
      state.selectedSocialAccountId = action.payload;
    },
    handleRestRedux: (state = initialState, action) => {
      state.allBrandData = [];
      state.userData = [];
      state.brandId = '';
      state.brandSettingData = [];
    },

    // Conversations States
    handleSelectPostData: (state, action) => {
      state.selectedPost = action.payload;
    },
    handleSelectedAccountInfo: (state, action) => {
      state.selectedSocialAccount = action.payload;
    },
    handleActiveDiv: (state, action) => {
      state.activeDiv = action.payload;
    },
    // Analytics States
  handleInstagramState: (state, action) => {
    state.instagram = action.payload;
  },
  handleFacebookState: (state, action) => {
    state.facebook = action.payload;
  },
  handleAccountSettingsData: (state, action) => {
    state.accountSettingsData = action.payload;
  },
  },

  
  // To handle Async data through redux toolkit

  extraReducers: (builder) => {
    builder.addCase(getAllBrandData.pending, (state, action) => {
      state.allBrandLoading = true;
    });
    builder.addCase(getAllBrandData.fulfilled, (state, action) => {
      state.allBrandLoading = false;
      state.allBrandData = action.payload.data;
    });
    builder.addCase(getAllBrandData.rejected, (state, action) => {
      state.allBrandLoading = false;
      state.allBrandError = 'error data';
      state.allBrandData = [];
    });

    builder.addCase(getAllSocialAccountData.pending, (state, action) => {
      state.allSocialAccountLoading = true;
    });
    builder.addCase(getAllSocialAccountData.fulfilled, (state, action) => {
      state.allSocialAccountLoading = false;
      state.allSocialAccountData = action.payload.data;
    });
    builder.addCase(getAllSocialAccountData.rejected, (state, action) => {
      state.allSocialAccountLoading = false;
      state.allSocialAccountError = 'error data';
      state.allSocialAccountData = [];
    });

    builder.addCase(getSocialAccountsSettingsData.pending, (state, action) => {
      state.socialAccountSettingsLoading = true;
    });
    builder.addCase(
      getSocialAccountsSettingsData.fulfilled,
      (state, action) => {
        state.socialAccountSettingsLoading = false;
        state.socialAccountSettingsData = action.payload.data;
      }
    );
    builder.addCase(getSocialAccountsSettingsData.rejected, (state, action) => {
      state.socialAccountSettingsLoading = false;
      state.socialAccountSettingsError = 'error data';
      state.socialAccountSettingsData = [];
    });

    builder.addCase(getUserData.pending, (state, action) => {
      state.userDataLoading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload.data;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.userDataLoading = false;
      state.userDataError = 'error data';
      state.userData = [];
    });

    builder.addCase(getCurrentPlanDetailsApiData.pending, (state, action) => {
      state.CurrentPlanDetailsLoading = true;
    });
    builder.addCase(getCurrentPlanDetailsApiData.fulfilled, (state, action) => {
      state.CurrentPlanDetailsDataLoading = false;
      state.CurrentPlanDetailsData = action.payload;
    });
    builder.addCase(getCurrentPlanDetailsApiData.rejected, (state, action) => {
      state.CurrentPlanDetailsDataLoading = false;
      state.CurrentPlanDetailsDataError = 'error data';
      state.CurrentPlanDetailsData = [];
    });

    builder.addCase(getFacebookPagePostsData.pending, (state, action) => {
      state.socialPostsLoader = true;
    });
    builder.addCase(getFacebookPagePostsData.fulfilled, (state, action) => {
      state.socialPostsLoader = false;
      state.socialPostsData = action.payload?.data;
    });
    builder.addCase(getFacebookPagePostsData.rejected, (state, action) => {
      state.socialPostsLoader = false;
      state.socialPostsError = 'error data';
      state.socialPostsData = [];
    });

    builder.addCase(getSocialPagePostsData.pending, (state, action) => {
      state.socialPostsLoader = false;
    });
    builder.addCase(getSocialPagePostsData.fulfilled, (state, action) => {
      state.socialPostsLoader = false;
      state.socialPostsData = action.payload?.data;
    });
    builder.addCase(getSocialPagePostsData.rejected, (state, action) => {
      state.socialPostsLoader = false;
      state.socialPostsError = 'error data';
      state.socialPostsData = [];
    });
    builder.addCase(getBrandSettingData.pending, (state, action) => {
      state.brandSettingLoading = true;
    });
    builder.addCase(getBrandSettingData.fulfilled, (state, action) => {
      state.brandSettingLoading = false;
      state.brandSettingData = action.payload;
    });
    builder.addCase(getBrandSettingData.rejected, (state, action) => {
      state.brandSettingLoading = false;
      state.brandSettingError = 'error data';
      state.brandSettingData = [];
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  handleCurrentPlanData,
  handleCurrentPlanLoader,
  handleSelectedBrandId,
  handleToken,
  handleSelectedSocialAccountId,
  handleRestRedux,
  handleSelectPostData,
  handleSelectedAccountInfo,
  handleActiveDiv,
  handleInstagramState,
  handleFacebookState,
  handleAccountSettingsData
} = userSlice.actions;

export default userSlice.reducer;
