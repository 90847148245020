import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
} from 'recharts';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';

const data = [
  {
    name: 'None',
    uv: 590,
    pv: 800,
    amt: 1400,
    cnt: 490,
  },
  {
    name: 'Other',
    uv: 868,
    pv: 967,
    amt: 1506,
    cnt: 590,
  },
];

function PageViewExtRefferrers() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { pageViewsByExternalLoading, pageViewsByExternalData } = useSelector(
    (state) => state.facebook
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {pageViewsByExternalLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Page Views via External Refferers
            </Typography>
            <MoreHorizOutlined
              fontSize="large"
              onClick={openMenu}
              sx={{ cursor: 'pointer' }}
            />
          </Grid>
          <MenuComponent
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            displayItems={[
              'Export as PDF',
              'Export as PNG',
              'Export as CSV',
              'Send as Email',
              'Schedule',
            ]}
          />
        </>
      )}
    </Box>
  );
}

export default PageViewExtRefferrers;
