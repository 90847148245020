import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Divider,
} from '@mui/material';

function TopReelCards({ data }) {
  return (
    <>
      {data?.map((item) => {
        return (
          <>
            <Grid className='topPostcards'>
              <Card sx={{ bgcolor: '#f8f8f8' }}>
                <Grid p='8px 15px'>
                  <Grid sx={{ mb: 1 }}>
                    <Typography variant='p' className='subheading' >
                      {item.Title}
                    </Typography>
                  </Grid>

                  <Grid sx={{ mb: 1 }} display="flex" alignItems="center">
                    {/* <Typography variant="p" mr={1.5}>
                      {item?.imageUrl}
                    </Typography> */}
                    <Typography variant="p">{item?.timestamp}</Typography>
                  </Grid>
                </Grid>
                <CardMedia
                  sx={{ height: 140 }}
                  image={item.permalink}
                />
                <CardContent>
                  <Typography variant='p' display='flex' alignItems='center' height={100} overflow='hidden' >
                    {item.description}
                  </Typography>
                  <Divider />
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Likes
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.like_count}
                    </Typography>
                  </Grid>

                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Comments
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Comments}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Shares
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Shares}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Saved
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Saved}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Engagement

                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Engagement}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Plays

                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Plays}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Reach

                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Reach}
                    </Typography>
                  </Grid>

                </CardContent>
              </Card>
            </Grid>
          </>
        )
      })}
    </>
  )
}

export default TopReelCards;
