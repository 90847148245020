import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Grid,
  Divider,
} from '@mui/material';

function TopStoryCards({ data }) {
  return (
    <>
      {data?.map((item) => {
        return (
          <>
            <Grid className='topPostcards'>
              <Card sx={{ bgcolor: '#f8f8f8' }}>
                <Grid p='8px 15px'>
                  <Grid sx={{ mb: 1 }}>
                    <Typography variant='p' className='subheading' >
                      {item.Title}
                    </Typography>
                  </Grid>

                  <Grid sx={{ mb: 1 }} display="flex" alignItems="center">
                    {/* <Typography variant='p' mr={1.5} >
                                        {item.PostedtContentIcon}
                                    </Typography> */}
                    <Typography variant="p">{item?.createdTime}</Typography>
                  </Grid>
                </Grid>



                <CardMedia
                  sx={{ height: 140 }}
                  image={item.PostedContent}
                />
                <CardContent>
                  <Typography variant='p' display='flex' alignItems='center' height={100} overflow='hidden' >
                    {item.Description}
                  </Typography>
                  <Divider />
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Exits
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Exits}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Replies
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Replies}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Taps Forward
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Forward}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Taps Back
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Back}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Engagement
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Engagement}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Impressions
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Impressions}
                    </Typography>
                  </Grid>
                  <Grid display='flex' alignItems='center'
                    justifyContent='space-between' mt={1}>
                    <Typography variant='h6' fontWeight={500}>
                      Reach
                    </Typography>
                    <Typography variant='h6' fontWeight={500}>
                      {item.Reach}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card >
            </Grid >
          </>
        )
      })}
    </>
  )
}

export default TopStoryCards;
