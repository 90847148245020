import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import VerticalDualLargeSizeBar from '../../Common/ChartComponents/VertiacalDualLargeSizedBar/VerticalDualLargeSizeBar';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';
const verticalDualLargeBarData = [
  {
    name: 'HOME',
    Page_Tab_Views: 35,
    Unique_Views: 8,
  },
  {
    name: 'PHOTOS',
    Page_Tab_Views: 2,
    Unique_Views: 1,
  },
  {
    name: 'OTHERS',
    Page_Tab_Views: 3,
    Unique_Views: 3,
  },
];

function PageTabViews() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { pageTabViewsLoading, pageTabViewsData } = useSelector(
    (state) => state.facebook
  );
  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      
      {pageTabViewsLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Page Tab Views
            </Typography>
            <MoreHorizOutlined fontSize="large" onClick={openMenu} sx={{cursor:"pointer"}}/>
          </Grid>
          <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
          {pageTabViewsData?.data?.length > 0 ? (
            <VerticalDualLargeSizeBar
              dataKey1="PageTabViews"
              dataKey2="UniqueViews"
              verticalDualLargeBarData={pageTabViewsData?.data}
              xAxisLabel="name"
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
    </Box>
  );
}

export default PageTabViews;
