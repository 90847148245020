import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCardComponent: [],
    activeComponentName:'',
    dateRangeText:[{
      initialValue:'Select Date Range',
      startDate:'',
      endDate:''
    }]
};

export const selectedCardComponentSlice = createSlice({
  name: "addCards",
  initialState,
  reducers: {
    handleAddCards: (state, action) => {
      console.log('ActionPaload',action.payload)
      state.selectedCardComponent = action.payload;
      console.log('selectedd:::::',state.selectedCardComponent)
    },
    handleActiveComponent: (state, action) => {
      
      state.activeComponentName = action.payload;
      console.log('updatedValue',action.payload)
    },
    handleDateRangeText: (state, action) => {
      state.dateRangeText = action.payload;
    },
    handleDeleteCards: (state, action) => {
      let tempArray = [...state.selectedCardComponent];
      tempArray.splice(action.payload, 1);
      state.selectedCardComponent = [...tempArray];
    },
},
});
// Action creators are generated for each case reducer function
export const { handleAddCards, handleDeleteCards,handleActiveComponent,handleDateRangeText } =
selectedCardComponentSlice.actions;

export default selectedCardComponentSlice.reducer;