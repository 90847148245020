import { createSlice } from '@reduxjs/toolkit';
import {
  getAudienceSummaryData,
  getPerformanceSummaryData,
  getPostEngagementSummaryData,
  getImpressionsSummaryData,
  getReelsEngagementSummaryData,
  getStorySummaryData,
  getPageReachImpressionData,
  getProfileViewsData,
  getPageClicksData,
  getCtaClickTypesData,
  getReelsVsEngagementData,
  getReelsPlaysVsReachData,
  getTop5ReelsData,
  getReelsEngagementActionsData,
  getReelsEngagementTypeData,
  getLikeGrowthData,
  getLikesByCountyData,
  getLikesByLanguageData,
  getLikesByCityData,
  getLikesByGenderData,
  getLikesByAgeData,
  getPostsByTypeData,
  getTopPostsData,
  getPostsEngagementImpressionData,
  getEngagementByTypeData,
  getEngagementTypeActionsData,
  getStoriesVsEngagementData,
  getStoriesEngagementTypeData,
  getTopStoriesData,
  getImpressionsVsReachData,
  getFollowersVsReachData,
  getExitsVsReachData,
  getStoriesCompletionRateData,
} from '../reduxThunk/instagram';

const initialState = {
  // summary tab:-
  // audienceSummary
  audienceSummaryDataLoading: false,
  audienceSummaryDataError: '',
  audienceSummaryData: [],

  // performanceSummary
  performanceSummaryLoading: false,
  performanceSummaryError: '',
  performanceSummaryData: [],

  // postEngagementSummary
  postEngagementSummaryLoading: false,
  postEngagementSummaryError: '',
  postEngagementSummaryData: [],

  // impressionsSummary
  impressionsSummaryLoading: false,
  impressionsSummaryError: '',
  impressionsSummaryData: [],

  // reelsEngagementSummary
  reelsEngagementSummaryLoading: false,
  reelsEngagementSummaryError: '',
  reelsEngagementSummaryData: [],

  reelsStorySummaryLoading: false,
  reelsStorySummaryError: '',
  reelsStorySummaryData: [],

  // audience
  // likeGrowth
  likeGrowthLoading: false,
  likeGrowthError: '',
  likeGrowthData: [],

  // likesByCounty
  likesByCountyLoading: false,
  likesByCountyError: '',
  likesByCountyData: [],

  // likesByLanguage
  likesByLanguageLoading: false,
  likesByLanguageError: '',
  likesByLanguageData: [],

  // likesByCity
  likesByCityLoading: false,
  likesByCityError: '',
  likesByCityData: [],

  // likesByGender
  likesByGenderLoading: false,
  likesByGenderError: '',
  likesByGenderData: [],

  // likesByAge
  likesByAgeLoading: false,
  likesByAgeError: '',
  likesByAgeData: [],

  //  pageReachImpression tab
  // pageReachImpression
  pageReachImpressionLoading: false,
  pageReachImpressionError: '',
  pageReachImpressionData: [],

  // Performance tab
  // profileViews
  profileViewsLoading: false,
  profileViewsError: '',
  profileViewsData: [],

  //  pageClicks
  pageClicksLoading: false,
  pageClicksError: '',
  pageClicksData: [],

  // ctaClickTypes
  ctaClickTypesLoading: false,
  ctaClickTypesError: '',
  ctaClickTypesData: [],

  // REELS AND MANAGEMENT
  // reelsVsEngagement
  reelsVsEngagementLoading: false,
  reelsVsEngagementError: '',
  reelsVsEngagementData: [],

  // reelsPlaysVsReach
  reelsPlaysVsReachLoading: false,
  reelsPlaysVsReachError: '',
  reelsPlaysVsReachData: [],

  // top5Reels
  top5ReelsLoading: false,
  top5ReelsError: '',
  top5ReelsData: [],

  // reelsEngagementActions
  reelsEngagementActionsLoading: false,
  reelsEngagementActionsError: '',
  reelsEngagementActionsData: [],

  reelsEngagementTypeLoading: false,
  reelsEngagementTypeError: '',
  reelsEngagementTypeData: [],

  // Posts & Engagement
  // postsByType
  postsByTypeLoading: false,
  postsByTypeError: '',
  postsByTypeData: [],

  // topPosts
  topPostsLoading: false,
  topPostsError: '',
  topPostsData: [],

  // postsEngagementImpression
  postsEngagementImpressionLoading: false,
  postsEngagementImpressionError: '',
  postsEngagementImpressionData: [],

  // engagementByType
  engagementByTypeLoading: false,
  engagementByTypeError: '',
  engagementByTypeData: [],

  // engagementTypeActions
  engagementTypeActionsLoading: false,
  engagementTypeActionsError: '',
  engagementTypeActionsData: [],

  //Stories States
  storiesEngagementLoading: false,
  storiesEngagementData: {},
  storiesEngagementError: '',

  storiesEngagementTypeLoading: false,
  storiesEngagementTypeData: {},
  storiesEngagementTypeError: '',

  storiesLoading: false,
  storiesData: {},
  storiesError: '',

  storiesImpressionReachLoading: false,
  storiesImpressionReachData: {},
  storiesImpressionReachError: '',

  storiesFollowerReachLoading: false,
  storiesFollowerReachData: {},
  storiesFollowerReachError: '',

  storiesExitsReachLoading: false,
  storiesExitsReachData: {},
  storiesExitsReachError: '',

  storiesCompletionLoading: false,
  storiesCompletionData: {},
  storiesCompletionError: '',
};

export const instagramSlice = createSlice({
  name: 'instagram',
  initialState,
  reducers: {},

  // To handle Async data through redux toolkit

  extraReducers: (builder) => {
    // summary tab
    builder.addCase(getAudienceSummaryData.pending, (state, action) => {
      state.audienceSummaryDataLoading = true;
    });
    builder.addCase(getAudienceSummaryData.fulfilled, (state, action) => {
      state.audienceSummaryDataLoading = false;
      state.audienceSummaryData = action.payload.data;
    });
    builder.addCase(getAudienceSummaryData.rejected, (state, action) => {
      state.audienceSummaryDataLoading = false;
      state.audienceSummaryDataError = 'error data';
      state.audienceSummaryData = [];
    });
    builder.addCase(getPerformanceSummaryData.pending, (state, action) => {
      state.performanceSummaryLoading = true;
    });
    builder.addCase(getPerformanceSummaryData.fulfilled, (state, action) => {
      state.performanceSummaryLoading = false;
      state.performanceSummaryData = action.payload.data;
    });
    builder.addCase(getPerformanceSummaryData.rejected, (state, action) => {
      state.performanceSummaryLoading = false;
      state.performanceSummaryError = 'error data';
      state.performanceSummaryData = [];
    });
    builder.addCase(getPostEngagementSummaryData.pending, (state, action) => {
      state.postEngagementSummaryLoading = true;
    });
    builder.addCase(getPostEngagementSummaryData.fulfilled, (state, action) => {
      state.postEngagementSummaryLoading = false;
      state.postEngagementSummaryData = action.payload.data;
    });
    builder.addCase(getPostEngagementSummaryData.rejected, (state, action) => {
      state.postEngagementSummaryLoading = false;
      state.postEngagementSummaryError = 'error data';
      state.postEngagementSummaryData = [];
    });
    builder.addCase(getImpressionsSummaryData.pending, (state, action) => {
      state.impressionsSummaryLoading = true;
    });
    builder.addCase(getImpressionsSummaryData.fulfilled, (state, action) => {
      state.impressionsSummaryLoading = false;
      state.impressionsSummaryData = action.payload.data;
    });
    builder.addCase(getImpressionsSummaryData.rejected, (state, action) => {
      state.impressionsSummaryLoading = false;
      state.impressionsSummaryError = 'error data';
      state.impressionsSummaryData = [];
    });
    builder.addCase(getReelsEngagementSummaryData.pending, (state, action) => {
      state.reelsEngagementSummaryLoading = true;
    });
    builder.addCase(
      getReelsEngagementSummaryData.fulfilled,
      (state, action) => {
        state.reelsEngagementSummaryLoading = false;
        state.reelsEngagementSummaryData = action.payload.data;
      }
    );
    builder.addCase(getReelsEngagementSummaryData.rejected, (state, action) => {
      state.reelsEngagementSummaryLoading = false;
      state.reelsEngagementSummaryError = 'error data';
      state.reelsEngagementSummaryData = [];
    });

    builder.addCase(getStorySummaryData.pending, (state, action) => {
      state.reelsStorySummaryLoading = true;
    });
    builder.addCase(getStorySummaryData.fulfilled, (state, action) => {
      state.reelsStorySummaryLoading = false;
      state.reelsStorySummaryData = action.payload.data;
    });
    builder.addCase(getStorySummaryData.rejected, (state, action) => {
      state.reelsStorySummaryLoading = false;
      state.reelsStorySummaryError = 'error data';
      state.reelsStorySummaryData = [];
    });

    // audience
    builder.addCase(getLikeGrowthData.pending, (state, action) => {
      state.likeGrowthLoading = true;
    });
    builder.addCase(getLikeGrowthData.fulfilled, (state, action) => {
      console.log('instagramapi store', action.payload.data);
      state.likeGrowthLoading = false;
      state.likeGrowthData = action.payload.data;
    });
    builder.addCase(getLikeGrowthData.rejected, (state, action) => {
      console.log('instagramapi store err', action);
      state.likeGrowthLoading = false;
      state.likeGrowthError = 'error data';
      state.likeGrowthData = [];
    });

    builder.addCase(getLikesByCountyData.pending, (state, action) => {
      state.likesByCountyLoading = true;
    });
    builder.addCase(getLikesByCountyData.fulfilled, (state, action) => {
      state.likesByCountyLoading = false;
      state.likesByCountyData = action.payload.data;
    });
    builder.addCase(getLikesByCountyData.rejected, (state, action) => {
      state.likesByCountyLoading = false;
      state.likesByCountyError = 'error data';
      state.likesByCountyData = [];
    });
    builder.addCase(getLikesByLanguageData.pending, (state, action) => {
      state.likesByLanguageLoading = true;
    });
    builder.addCase(getLikesByLanguageData.fulfilled, (state, action) => {
      state.likesByLanguageLoading = false;
      state.likesByLanguageData = action.payload.data;
    });
    builder.addCase(getLikesByLanguageData.rejected, (state, action) => {
      state.likesByLanguageLoading = false;
      state.likesByLanguageError = 'error data';
      state.likesByLanguageData = [];
    });
    builder.addCase(getLikesByCityData.pending, (state, action) => {
      state.likesByCityLoading = true;
    });
    builder.addCase(getLikesByCityData.fulfilled, (state, action) => {
      state.likesByCityLoading = false;
      state.likesByCityData = action.payload.data;
    });
    builder.addCase(getLikesByCityData.rejected, (state, action) => {
      state.likesByCityLoading = false;
      state.likesByCityError = 'error data';
      state.likesByCityData = [];
    });
    builder.addCase(getLikesByGenderData.pending, (state, action) => {
      state.likesByGenderLoading = true;
    });
    builder.addCase(getLikesByGenderData.fulfilled, (state, action) => {
      state.likesByGenderLoading = false;
      state.likesByGenderData = action.payload.data;
    });
    builder.addCase(getLikesByGenderData.rejected, (state, action) => {
      state.likesByGenderLoading = false;
      state.likesByGenderError = 'error data';
      state.likesByGenderData = [];
    });
    builder.addCase(getLikesByAgeData.pending, (state, action) => {
      state.likesByAgeLoading = true;
    });
    builder.addCase(getLikesByAgeData.fulfilled, (state, action) => {
      state.likesByAgeLoading = false;
      state.likesByAgeData = action.payload.data;
    });
    builder.addCase(getLikesByAgeData.rejected, (state, action) => {
      state.likesByAgeLoading = false;
      state.likesByAgeError = 'error data';
      state.likesByAgeData = [];
    });
    // pageReachImpression
    builder.addCase(getPageReachImpressionData.pending, (state, action) => {
      state.pageReachImpressionLoading = true;
    });
    builder.addCase(getPageReachImpressionData.fulfilled, (state, action) => {
      state.pageReachImpressionLoading = false;
      state.pageReachImpressionData = action.payload.data;
    });
    builder.addCase(getPageReachImpressionData.rejected, (state, action) => {
      state.pageReachImpressionLoading = false;
      state.pageReachImpressionError = 'error data';
      state.pageReachImpressionData = [];
    });

    // Performance
    builder.addCase(getProfileViewsData.pending, (state, action) => {
      state.profileViewsLoading = true;
    });
    builder.addCase(getProfileViewsData.fulfilled, (state, action) => {
      console.log(
        'getProfileViewsData action.payload.data',
        action.payload.data
      );
      state.profileViewsLoading = false;
      state.profileViewsData = action.payload.data;
    });
    builder.addCase(getProfileViewsData.rejected, (state, action) => {
      state.profileViewsLoading = false;
      state.profileViewsError = 'error data';
      state.profileViewsData = [];
    });
    builder.addCase(getPageClicksData.pending, (state, action) => {
      state.pageClicksLoading = true;
    });
    builder.addCase(getPageClicksData.fulfilled, (state, action) => {
      state.pageClicksLoading = false;
      state.pageClicksData = action.payload.data;
    });
    builder.addCase(getPageClicksData.rejected, (state, action) => {
      state.pageClicksLoading = false;
      state.pageClicksError = 'error data';
      state.pageClicksData = [];
    });
    builder.addCase(getCtaClickTypesData.pending, (state, action) => {
      state.ctaClickTypesLoading = true;
    });
    builder.addCase(getCtaClickTypesData.fulfilled, (state, action) => {
      state.ctaClickTypesLoading = false;
      state.ctaClickTypesData = action.payload.data;
    });
    builder.addCase(getCtaClickTypesData.rejected, (state, action) => {
      state.ctaClickTypesLoading = false;
      state.ctaClickTypesError = 'error data';
      state.ctaClickTypesData = [];
    });

    //  REELS AND MANAGEMENT
    builder.addCase(getReelsVsEngagementData.pending, (state, action) => {
      state.reelsVsEngagementLoading = true;
    });
    builder.addCase(getReelsVsEngagementData.fulfilled, (state, action) => {
      state.reelsVsEngagementLoading = false;
      state.reelsVsEngagementData = action.payload.data;
    });
    builder.addCase(getReelsVsEngagementData.rejected, (state, action) => {
      state.reelsVsEngagementLoading = false;
      state.reelsVsEngagementError = 'error data';
      state.reelsVsEngagementData = [];
    });
    builder.addCase(getReelsPlaysVsReachData.pending, (state, action) => {
      state.reelsPlaysVsReachLoading = true;
    });
    builder.addCase(getReelsPlaysVsReachData.fulfilled, (state, action) => {
      state.reelsPlaysVsReachLoading = false;
      state.reelsPlaysVsReachData = action.payload.data;
    });
    builder.addCase(getReelsPlaysVsReachData.rejected, (state, action) => {
      state.reelsPlaysVsReachLoading = false;
      state.reelsPlaysVsReachError = 'error data';
      state.reelsPlaysVsReachData = [];
    });
    builder.addCase(getTop5ReelsData.pending, (state, action) => {
      state.top5ReelsLoading = true;
    });
    builder.addCase(getTop5ReelsData.fulfilled, (state, action) => {
      state.top5ReelsLoading = false;
      state.top5ReelsData = action.payload.data;
    });
    builder.addCase(getTop5ReelsData.rejected, (state, action) => {
      state.top5ReelsLoading = false;
      state.top5ReelsError = 'error data';
      state.top5ReelsData = [];
    });
    builder.addCase(getReelsEngagementActionsData.pending, (state, action) => {
      state.reelsEngagementActionsLoading = true;
    });
    builder.addCase(
      getReelsEngagementActionsData.fulfilled,
      (state, action) => {
        state.reelsEngagementActionsLoading = false;
        state.reelsEngagementActionsData = action.payload.data;
      }
    );
    builder.addCase(getReelsEngagementActionsData.rejected, (state, action) => {
      state.reelsEngagementActionsLoading = false;
      state.reelsEngagementActionsError = 'error data';
      state.reelsEngagementActionsData = [];
    });

    builder.addCase(getReelsEngagementTypeData.pending, (state, action) => {
      state.reelsEngagementTypeLoading = true;
    });
    builder.addCase(getReelsEngagementTypeData.fulfilled, (state, action) => {
      state.reelsEngagementTypeLoading = false;
      state.reelsEngagementTypeData = action.payload.data;
    });
    builder.addCase(getReelsEngagementTypeData.rejected, (state, action) => {
      state.reelsEngagementTypeLoading = false;
      state.reelsEngagementTypeError = 'error data';
      state.reelsEngagementTypeData = [];
    });

    // Posts & Engagement
    builder.addCase(getPostsByTypeData.pending, (state, action) => {
      state.postsByTypeLoading = true;
    });
    builder.addCase(getPostsByTypeData.fulfilled, (state, action) => {
      state.postsByTypeLoading = false;
      state.postsByTypeData = action.payload.data;
    });
    builder.addCase(getPostsByTypeData.rejected, (state, action) => {
      state.postsByTypeLoading = false;
      state.postsByTypeError = 'error data';
      state.postsByTypeData = [];
    });
    builder.addCase(getTopPostsData.pending, (state, action) => {
      state.topPostsLoading = true;
    });
    builder.addCase(getTopPostsData.fulfilled, (state, action) => {
      state.topPostsLoading = false;
      state.topPostsData = action.payload.data;
    });
    builder.addCase(getTopPostsData.rejected, (state, action) => {
      state.topPostsLoading = false;
      state.topPostsError = 'error data';
      state.topPostsData = [];
    });
    builder.addCase(
      getPostsEngagementImpressionData.pending,
      (state, action) => {
        state.postsEngagementImpressionLoading = true;
      }
    );
    builder.addCase(
      getPostsEngagementImpressionData.fulfilled,
      (state, action) => {
        state.postsEngagementImpressionLoading = false;
        state.postsEngagementImpressionData = action.payload.data;
      }
    );
    builder.addCase(
      getPostsEngagementImpressionData.rejected,
      (state, action) => {
        state.postsEngagementImpressionLoading = false;
        state.postsEngagementImpressionError = 'error data';
        state.postsEngagementImpressionData = [];
      }
    );
    builder.addCase(getEngagementByTypeData.pending, (state, action) => {
      state.engagementByTypeLoading = true;
    });
    builder.addCase(getEngagementByTypeData.fulfilled, (state, action) => {
      state.engagementByTypeLoading = false;
      state.engagementByTypeData = action.payload.data;
    });
    builder.addCase(getEngagementByTypeData.rejected, (state, action) => {
      state.engagementByTypeLoading = false;
      state.engagementByTypeError = 'error data';
      state.engagementByTypeData = [];
    });
    builder.addCase(getEngagementTypeActionsData.pending, (state, action) => {
      state.engagementTypeActionsLoading = true;
    });
    builder.addCase(getEngagementTypeActionsData.fulfilled, (state, action) => {
      state.engagementTypeActionsLoading = false;
      state.engagementTypeActionsData = action.payload.data;
    });
    builder.addCase(getEngagementTypeActionsData.rejected, (state, action) => {
      state.engagementTypeActionsLoading = false;
      state.engagementTypeActionsError = 'error data';
      state.engagementTypeActionsData = [];
    });

    // Stories
    builder.addCase(getStoriesVsEngagementData.pending, (state, action) => {
      state.storiesEngagementLoading = true;
    });
    builder.addCase(getStoriesVsEngagementData.fulfilled, (state, action) => {
      state.storiesEngagementLoading = false;
      state.storiesEngagementData = action.payload.data;
    });
    builder.addCase(getStoriesVsEngagementData.rejected, (state, action) => {
      state.storiesEngagementLoading = false;
      state.storiesEngagementError = 'error data';
      state.storiesEngagementData = {};
    });
    builder.addCase(getStoriesEngagementTypeData.pending, (state, action) => {
      state.storiesEngagementTypeLoading = true;
    });
    builder.addCase(getStoriesEngagementTypeData.fulfilled, (state, action) => {
      state.storiesEngagementTypeLoading = false;
      state.storiesEngagementTypeData = action.payload.data;
    });
    builder.addCase(getStoriesEngagementTypeData.rejected, (state, action) => {
      state.storiesEngagementTypeLoading = false;
      state.storiesEngagementTypeError = 'error data';
      state.storiesEngagementTypeData = {};
    });

    builder.addCase(getTopStoriesData.pending, (state, action) => {
      state.storiesLoading = true;
    });
    builder.addCase(getTopStoriesData.fulfilled, (state, action) => {
      state.storiesLoading = false;
      state.storiesData = action.payload.data;
    });
    builder.addCase(getTopStoriesData.rejected, (state, action) => {
      state.storiesLoading = false;
      state.storiesError = 'error data';
      state.storiesData = {};
    });

    builder.addCase(getImpressionsVsReachData.pending, (state, action) => {
      state.storiesImpressionReachLoading = true;
    });
    builder.addCase(getImpressionsVsReachData.fulfilled, (state, action) => {
      state.storiesImpressionReachLoading = false;
      state.storiesImpressionReachData = action.payload.data;
    });
    builder.addCase(getImpressionsVsReachData.rejected, (state, action) => {
      state.storiesImpressionReachLoading = false;
      state.storiesImpressionReachError = 'error data';
      state.storiesImpressionReachData = {};
    });

    builder.addCase(getFollowersVsReachData.pending, (state, action) => {
      state.storiesFollowerReachLoading = true;
    });
    builder.addCase(getFollowersVsReachData.fulfilled, (state, action) => {
      state.storiesFollowerReachLoading = false;
      state.storiesFollowerReachData = action.payload.data;
    });
    builder.addCase(getFollowersVsReachData.rejected, (state, action) => {
      state.storiesFollowerReachLoading = false;
      state.storiesFollowerReachError = 'error data';
      state.storiesFollowerReachData = {};
    });

    builder.addCase(getExitsVsReachData.pending, (state, action) => {
      state.storiesExitsReachLoading = true;
    });
    builder.addCase(getExitsVsReachData.fulfilled, (state, action) => {
      state.storiesExitsReachLoading = false;
      state.storiesExitsReachData = action.payload.data;
    });
    builder.addCase(getExitsVsReachData.rejected, (state, action) => {
      state.storiesExitsReachLoading = false;
      state.storiesExitsReachError = 'error data';
      state.storiesExitsReachData = {};
    });

    builder.addCase(getStoriesCompletionRateData.pending, (state, action) => {
      state.storiesCompletionLoading = true;
    });
    builder.addCase(getStoriesCompletionRateData.fulfilled, (state, action) => {
      state.storiesCompletionLoading = false;
      state.storiesCompletionData = action.payload.data;
    });
    builder.addCase(getStoriesCompletionRateData.rejected, (state, action) => {
      state.storiesCompletionLoading = false;
      state.storiesCompletionError = 'error data';
      state.storiesCompletionData = {};
    });
  },
});

// Action creators are generated for each case reducer function
// export const { handleAudienceSummary } = facebookSlice.actions;

export default instagramSlice.reducer;
