import React, { useState, useEffect, useMemo } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { handleSelectPostData } from '../../redux/Slice/user';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Tooltip } from '@mui/material';
import commentImg from '../../assets/assets/comment.png';
import { handleActiveDiv } from '../../redux/Slice/user';

function ImageListBlock({ socialPostsData }) {
  const dispatch = useDispatch();

  // Setting default selected post & default Active state for image on Page load
  useEffect(() => {
    let firstPost =
      socialPostsData && socialPostsData?.length > 0 ? socialPostsData[1] : {};
    // setSelectedPost(firstPost);
    dispatch(handleSelectPostData(firstPost));
    //default active state set
    const images = document.querySelectorAll('.image_list');
    const imageParent = document.querySelectorAll('.MuiImageListItem-root');
    if (images.length > 0) {
      images.forEach((image) => {
        image.classList.remove('selected');
      });
      imageParent.forEach((image) => {
        image.classList.remove('selectedParent');
      });
      images[0].classList.add('selected');
      imageParent[0].classList.add('selectedParent');
    }
  }, [socialPostsData]);

  /* JavaScript to toggle the "selected" class on click */
  const handleImageClick = (event, index, item) => {
    const images = document.querySelectorAll('.image_list');
    const imageParent = document.querySelectorAll('.MuiImageListItem-root');
    if (images.length > index) {
      images.forEach((image) => {
        image.classList.remove('selected');
      });
      imageParent.forEach((image) => {
        image.classList.remove('selectedParent');
      });
      images[index].classList.add('selected');
      imageParent[index].classList.add('selectedParent');
    }
    // setSelectedPost(item);
    dispatch(handleSelectPostData(item));

    // on click on diff image comment reply active div state set to first index
    dispatch(handleActiveDiv(0));
  };

  return (
    <>
      {socialPostsData && socialPostsData?.length > 0 ? (
        <ImageList cols={2} gap={5} style={{ overflowY: 'hidden' }}>
          {socialPostsData?.map((item, index) =>
            item.image_url ? (
              <ImageListItem key={item.id}>
                <div
                  className="social_image"
                  onClick={(event) => handleImageClick(event, index, item)}
                >
                  <img
                    className="social_img image_list"
                    src={item.image_url}
                    alt={`Post created on ${item.created_time}`}
                    loading="lazy"
                  />
                  {item?.comments_count > 0 ? (
                    <div className="chat-icon">
                      <img src={commentImg} alt="chat" />
                      <Typography>{item?.comments_count}</Typography>
                    </div>
                  ) : null}
                </div>
              </ImageListItem>
            ) : null
          )}
        </ImageList>
      ) : (
        <Typography className="no-data">No Post Data Available.</Typography>
      )}
    </>
  );
}

export default ImageListBlock;
