import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import BarAndLineChart from '../../Common/ChartComponents/BarAndLineChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const data = [
  {
    name: '19 Mar 23',
    Page_Views: 0,
    Logged_in_to_Facebook: 0,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '22 Mar 23',
    Page_Views: 0,
    Logged_in_to_Facebook: 0,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '25 Mar 23',
    Page_Views: 3,
    Logged_in_to_Facebook: 3,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '28 Mar 23',
    Page_Views: 3,
    Logged_in_to_Facebook: 3,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '31 Mar 23',
    Page_Views: 0,
    Logged_in_to_Facebook: 0,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '03 Apr 23',
    Page_Views: 0,
    Logged_in_to_Facebook: 0,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '06 Apr 23',
    Page_Views: 13,
    Logged_in_to_Facebook: 13,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '09 Apr 23',
    Page_Views: 6,
    Logged_in_to_Facebook: 6,
    Not_logged_in_to_Facebook: 1,
  },
  {
    name: '12 Apr 23',
    Page_Views: 14,
    Logged_in_to_Facebook: 14,
    Not_logged_in_to_Facebook: 0,
  },
  {
    name: '15 Apr 23',
    Page_Views: 0,
    Logged_in_to_Facebook: 0,
    Not_logged_in_to_Facebook: 0,
  },
];

function PageProfileViews() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { pageProfileViewsLoading, pageProfileViewsData } = useSelector(
    (state) => state.facebook
  );

  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        
        {pageProfileViewsLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Page Profile Views
              </Typography>
              <MoreHorizOutlined fontSize="large" onClick={openMenu} sx={{cursor:"pointer"}}/>
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {pageProfileViewsData?.data?.length > 0 ? (
              <BarAndLineChart
                data={pageProfileViewsData?.data}
                XAxis_dataKey="day"
                barDatakey="totalDayCount"
                line1Datakey="loggedInViewsCount"
                line2Datakey="loggedOutViewsCount"
                YAxis_Label="Number Of Views"
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PageProfileViews;
