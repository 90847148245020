import { Box, Grid, Typography } from '@mui/material';
import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip,
    Label,
    LabelList,
    Legend
} from "recharts";

function HorizontalStackedBarChart({
    data,
    YAxis_dataKey,
    bar1DataKey,
    bar2DataKey,
    bar3DataKey,
    XAxis_Label,
    YAxis_Label
}) {
  return (
    <>
     <Grid width='100%' height='100%'>
                <ResponsiveContainer height={500} width={"100%"}>
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{ left: 20, right: 20, top:20 }}
                    >
                        <Legend iconSize={16} iconType='circle'
                        verticalAlign='bottom'
                        wrapperStyle={{ fontSize:18,marginTop:5 }}
                        />
                        <XAxis
                            type="number"
                            orientation='top'
                            fontSize="14"
                            domain={[  dataMax => (dataMax * 1.5), 0]}
                            scale="auto"
                            label={{
                                value: XAxis_Label,
                                position: 'top',
                                fontSize: '16px',    
                            }} 
                            />
                        <YAxis
                            type='category'
                            dataKey={YAxis_dataKey}
                            fontSize="14"
                            domain={[0, dataMax => (dataMax * 1.5)]}
                            label={{
                                value: YAxis_Label,
                                position: 'left',
                                fontSize: '16px',
                                angle: -90,
                                style: { textAnchor: 'middle' },
                            }}
                        />
                        <Tooltip itemStyle={{fontSize:'16px'}} wrapperStyle={{ fontSize:18, borderRadius:'8px' }}/>
                        {bar1DataKey ?<Bar dataKey={bar1DataKey} fill="#7a40ed" stackId="a" barSize={25}/> :null}
                        {bar2DataKey ?<Bar dataKey={bar2DataKey} fill="#bba2ec" stackId="a" barSize={25}/>:null}
                        {bar3DataKey ?<Bar dataKey={bar3DataKey} fill="#dcd5eb" stackId="a" barSize={25}/>:null}
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
    </>
  )
}

export default HorizontalStackedBarChart