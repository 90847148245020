import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Total',
    heading3: '%',
  },
];
const tabledata = [
  {
    data1: 'Website',
    data2: 3,
    data3: '100%',
  },
  {
    data1: 'Phone Call',
    data2: 0,
    data3: '0%',
  },
  {
    data1: 'Email',
    data2: 0,
    data3: '0%',
  },
  {
    data1: 'Directions',
    data2: 0,
    data3: '0%',
  },
  {
    data1: 'Text Message',
    data2: 0,
    data3: '0%',
  },
];
const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'CTA Clicks by Type', value: [sum] },
  { labels: 'Website', value: 3 },
  { labels: 'Phone Call', value: 0 },
  { labels: 'Email', value: 0 },
  { labels: 'Directions', value: 0 },
  { labels: 'Text Message', value: 0 },
];

function PageClicksType() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { ctaClickTypesLoading, ctaClickTypesData } = useSelector(
    (state) => state.facebook
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
        }}
      >
        {ctaClickTypesLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography variant="h4" color="#000" fontWeight={600}>
                CTA Clicks by Type
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {ctaClickTypesData?.data?.length > 0 ? (
              <>
                <DoughnutChart
                  doughnutData={ctaClickTypesData?.doughnutData}
                  dataKey="value"
                />
                <TableComponent
                  heading={heading}
                  tabledata={ctaClickTypesData?.data}
                />
              </>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PageClicksType;
