import { makeStyles } from '@mui/styles';

export const inputStyles = makeStyles({
    input: {
        height: '50px',
        width: '400px',
        border: 'solid 1px grey',
        borderRadius: '8px',
        // marginBottom: '30px',
        marginTop: '10px',
        paddingLeft: '15px',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        outlineStyle: 'none',
    
        '@media (min-width: 1800px)': {
          width: 700,
          height: 60,
        },
        '@media (min-width: 1600px)': {
          width: 600,
          height: 60,
        },
        '@media (max-width: 1226px)': {
          width: 400,
          height: 45,
        },
        '@media (max-width: 1000px)': {
          width: 300,
          height: 40,
        },
        '@media (max-width: 768px)': {
          width: 300,
          height: 35,
        },
    
      },
      OtpInput: {
        height: '50px',
        width: '400px',
        border: 'solid 1px grey',
        borderRadius: '8px',
        marginBottom: '12px',
        marginTop: '10px',
        paddingLeft: '15px',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        outlineStyle: 'none',
    
        '@media (min-width: 1800px)': {
          width: 700,
          height: 60,
        },
        '@media (min-width: 1600px)': {
          width: 600,
          height: 60,
        },
        '@media (max-width: 1226px)': {
          width: 400,
          height: 45,
        },
        '@media (max-width: 1000px)': {
          width: 300,
          height: 40,
        },
        '@media (max-width: 768px)': {
          width: 300,
          height: 35,
        },
    
      },
      input1: {
        height: '50px',
        width: '400px',
        border: 'solid 1px grey',
        borderRadius: '8px',
        marginBottom: '8px',
        marginTop: '10px',
        paddingLeft: '15px',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        outlineStyle: 'none',
        '@media (min-width: 1800px)': {
          width: 700,
          height: 60,
        },
        '@media (min-width: 1600px)': {
          width: 600,
          height: 60,
        },
        '@media (max-width: 1226px)': {
          width: 400,
          height: 45,
        },
        '@media (max-width: 1000px)': {
          width: 300,
          height: 40,
        },
        '@media (max-width: 768px)': {
          width: 300,
          height: 35,
        },
    
      },
      signUp_phInput: {
        height: '55px',
        width: '400px',
        border: 'solid 1px grey',
        borderRadius: '10px',
        marginBottom: '25px',
        marginTop: '10px',
        paddingLeft: '15px',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
    
        '@media (min-width: 1800px)': {
          width: 700,
          height: 60,
        },
        '@media (min-width: 1600px)': {
          width: 600,
          height: 60,
        },
        '@media (max-width: 1226px)': {
          width: 400,
          height: 45,
        },
        '@media (max-width: 1000px)': {
          width: 300,
          height: 40,
        },
        '@media (max-width: 768px)': {
          width: 300,
          height: 35,
        },
      },
      phInput: {
        height: 45,
        width: 290,
        border: 'solid 1px #979797',
        borderRadius: '5px',
        marginBottom: '30px',
        marginLeft: '25px',
        marginTop: '10px',
        '@media (max-width: 1339px)': {
          width: 200,
          height: 45,
        },
        '@media (max-width: 768px)': {
          width: 200,
          height: 45,
        },
        '@media (min-width: 1600px)': {
          width: 350,
          height: 45,
        },
      },
      otp_phInput: {
        height: 45,
        width: 306,
        border: 'solid 1px #979797',
        borderRadius: '5px',
        marginBottom: '30px',
        marginLeft: '0px',
        marginTop: '10px',
        '@media (max-width: 1339px)': {
          width: 200,
          height: 45,
        },
        '@media (max-width: 768px)': {
          width: 200,
          height: 45,
        },
        '@media (min-width: 1600px)': {
          width: 350,
          height: 45,
        },
      },
});


