import React, { useState } from 'react';
import { Avatar, Box,Typography } from '@mui/material';
import redirectImg from '../../assets/assets/link.png';
import CommentReplyInput from './CommentReplyInput';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import * as api from '../../redux/Api';
import { useDispatch, useSelector } from 'react-redux';
import { getSocialPagePostsData } from '../../redux/reduxThunk';

function CommentReply({
  commentData,
  displayTimeDifference,
  index,
  activeDiv,
  handleToggleReply,
}) {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [showReplies, setShowReplies] = useState(false);

  const dispatch = useDispatch();
  const { selectedSocialAccount } = useSelector((state) => state.user);

  const [postComment, setPostComment] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  const handlePostComment = (event) => {
    setPostComment(event.target.value);
  };
  const handleClickComment = async (event) => {
    setLoader(true);
    let data = {
      socialId: selectedSocialAccount?.socialId,
      socialAccountName: selectedSocialAccount?.socialAccountName,
      commentId: commentData?.id,
      reply_message: postComment,
    };
    try {
      await api.replyToPostComment(data).then((res) => {
        setSuccessMsg(res?.data);
        let postData = {
          socialId: selectedSocialAccount?.socialId,
          socialAccountName: selectedSocialAccount?.socialAccountName,
        };
        // API call to fetch updated posts data
        dispatch(getSocialPagePostsData(postData));
        // setting loader false
        setLoader(false);
      });
    } catch (err) {
      setLoader(false);
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const handleEmojiSelect = (emoji) => {
    setSelectedEmoji(emoji);
    const cursorPosition =
      document.getElementById('myTextField').selectionStart;
    const textBeforeCursor = postComment.slice(0, cursorPosition);
    const textAfterCursor = postComment.slice(cursorPosition);
    const newValue = `${textBeforeCursor}${emoji.native}${textAfterCursor}`;
    setPostComment(newValue);
    setShowPicker(!showPicker);
  };

  return (
    <Box className="group_div">
      <Box
        className={
          !commentData?.hasReplies
            ? `comments_reply ${index === activeDiv ? 'active' : ''}`
            : `engaged_reply ${index === activeDiv ? 'active' : ''}`
        }
        onClick={() => handleToggleReply(index)}
      >
        <Box className="align_row">
          {/* Profile Img */}
          <Box className="profile_img">
            <a href={commentData?.author_profile_url} target="_blank">
              <Avatar>B</Avatar>
              <div className="profile_overlay">
                <img
                  src={redirectImg}
                  alt="My Image"
                  className="profile_overlay_image"
                />
              </div>
            </a>
          </Box>
          {/* Profile Info and Reply */}
          <Box className="reply_sec">
            <Box>
              <Typography className="profile" variant='h6'>
                <a
                  href={commentData?.author_profile_url}
                  target="_blank"
                  className="profile_name"
                >
                  {commentData?.author_name}
                </a>
                <span>{commentData?.text}</span>
              </Typography>
              <Box className="profile_info">
                <Typography>
                  {' '}
                  {displayTimeDifference(commentData?.created_time)}
                </Typography>
                <Typography className="ml-10" variant='p'>
                  {commentData?.hasReplies ? 'Engaged' : 'Reply'}
                </Typography>
              </Box>
            </Box>
            <Box className="mt-10">
              {!commentData?.hasReplies && index === activeDiv ? (
                <CommentReplyInput
                  commentData={commentData}
                  postComment={postComment}
                  handlePostComment={handlePostComment}
                  handleClickComment={handleClickComment}
                  loader={loader}
                />
              ) : null}
              <Box
                className={
                  commentData?.replies?.length > 0 ? 'btn_align' : 'btn_justify'
                }
              >
                {commentData?.replies?.length > 0 && (
                  <button
                    className="reply_btn"
                    onClick={() => setShowReplies(!showReplies)}
                  >
                    {showReplies
                      ? `___ Close Replies (${commentData?.replies?.length})`
                      : `___ View Replies (${commentData?.replies?.length})`}
                  </button>
                )}

                {!commentData?.hasReplies && activeDiv === index && (
                  <button
                    onClick={() => setShowPicker(!showPicker)}
                    className="emoji_btn"
                  >
                    ...
                  </button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        {showPicker && <Picker data={data} onEmojiSelect={handleEmojiSelect} />}
      </Box>

      {showReplies && (
        <div className="replies">
          {commentData?.replies?.map((reply) => (
            <>
              {/* ... display reply data */}
              <Box className="nested_comments_reply">
                <Box className="align_row">
                  {/* Profile Img */}
                  <Box className="profile_img">
                    <a href={reply?.author_profile_url} target="_blank">
                      <Avatar>B</Avatar>
                      <div className="profile_overlay">
                        <img
                          src={redirectImg}
                          alt="My Image"
                          className="profile_overlay_image"
                        />
                      </div>
                    </a>
                  </Box>
                  {/* Profile Info and Reply */}
                  <Box className="reply_sec">
                    <Box>
                      <Typography className="profile" variant='p'>
                        <a
                          href={reply?.author_profile_url}
                          target="_blank"
                          className="profile_name"
                        >
                          {reply?.author_name}
                        </a>
                        <span>{reply?.text}</span>
                      </Typography>
                      <Box className="profile_info">
                        <Typography>
                          {' '}
                          {displayTimeDifference(reply?.created_time)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          ))}
        </div>
      )}
    </Box>
  );
}
export default CommentReply;
