import React, { useEffect } from 'react';
import PageLikeGrowth from '../../../components/Audience/PageLikeGrowth/PageLikeGrowth';
import { Box, Divider, Grid } from '@mui/material';
import OrganicVsPaidLikes from '../../../components/Audience/OrganicVsPaidLikes/OrganicVsPaidLikes';
import PageLikesBySoure from '../../../components/Audience/PageLikesBySource/PageLikesBySoure';
import PageUnlikeBySource from '../../../components/Audience/PageUnlikeBySource/PageUnlikeBySource';
import PageLikesByCountry from '../../../components/Audience/PageLikesByCountry/PageLikesByCountry';
import PageLikesByLanguage from '../../../components/Audience/PageLikesByLanguage/PageLikesByLanguage';
import PageLikesByCity from '../../../components/Audience/PageLikesByCity/PageLikesByCity';
import PageLikesByGender from '../../../components/Audience/PageLikesByGender/PageLikesByGender';
import LikesByAgeGroup from '../../../components/Audience/LikesByAgeGroup/LikesByAgeGroup';
import {
  getLikeGrowthData,
  getPaidOrganicLikesData,
  getLikeSourcesData,
  getUnlikeSourcesData,
  getLikesByCountyData,
  getLikesByLanguageData,
  getLikesByCityData,
  getLikesByGenderData,
  getLikesByAgeData,
} from '../../../redux/reduxThunk/facebook';
import { useSelector, useDispatch } from 'react-redux';
function Audience() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchFacebookAudienceTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-02',
        toDate: '2023-04-13',
      };
      dispatch(getLikeGrowthData(summaryData));
      dispatch(getPaidOrganicLikesData(summaryData));
      dispatch(getLikeSourcesData(summaryData));
      dispatch(getUnlikeSourcesData(summaryData));
      dispatch(getLikesByCountyData(summaryData));
      dispatch(getLikesByLanguageData(summaryData));
      dispatch(getLikesByCityData(summaryData));
      dispatch(getLikesByGenderData(summaryData));
      dispatch(getLikesByAgeData(summaryData));
    };
    fetchFacebookAudienceTabData();
  }, [audienceSummary]);

  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <PageLikeGrowth />
          </Grid>
          <Grid lg={5.5} sx={{ m: 1 }}>
            <OrganicVsPaidLikes />
          </Grid>
        </Grid>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <PageLikesBySoure />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <PageUnlikeBySource />
          </Grid>
        </Grid>
        <Grid lg={11.7} sx={{m:1}}>
        <PageLikesByCountry />
        </Grid>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <PageLikesByLanguage />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <PageLikesByCity />
          </Grid>
        </Grid>
        <Grid container>
          <Grid lg={6} sx={{ width: '100%', m: 1 }}>
            <PageLikesByGender />
          </Grid>
          <Grid lg={5.5} sx={{ width: '100%', m: 1 }}>
            <LikesByAgeGroup />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Audience;
