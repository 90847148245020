import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import HorizontalDualBarComponent from '../../Common/ChartComponents/HorizontalDualBarComponent/HorizontalDualBarComponent';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

function PageShareImpressionType() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { shareImpressionsByTypeLoading, shareImpressionsByTypeData } =
    useSelector((state) => state.facebook);
  const horizontalDualBarData = [
    {
      name: 'Mention',
      value1: 224,
      value2: 161,
    },
    {
      name: 'Page Post',
      value1: 5,
      value2: 5,
    },
    {
      name: 'Fan',
      value1: 0,
      value2: 0,
    },
    {
      name: 'Other',
      value1: 0,
      value2: 0,
    },
  ];
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {shareImpressionsByTypeLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Page Share Impressions by Type
            </Typography>
            <MoreHorizOutlined
              fontSize="large"
              onClick={openMenu}
              sx={{ cursor: 'pointer' }}
            />
          </Grid>
          <MenuComponent
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            displayItems={[
              'Export as PNG',
              'Export as PDF',
              'Export as CSV',
              'Send as Email',
              'Schedule',
            ]}
          />

          {shareImpressionsByTypeData ? (
            <HorizontalDualBarComponent
              horizontalDualBarData={shareImpressionsByTypeData}
              dataKey1="count"
              dataKey2="value2"
              yAxisLabel="name"
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
    </Box>
  );
}

export default PageShareImpressionType;
