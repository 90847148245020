import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import HorizontalStackedBarChart from '../../Common/HorizontalStackedBarChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';

function PageViewsAge() {
  const data = [
    {
      age: '<13',
      Male: 230,
      Female: 335,
      Others: 453,
    },
    {
      age: '13-17',
      Male: 335,
      Female: 330,
      Others: 345,
    },
    {
      age: '18-24',
      Male: 537,
      Female: 243,
      Others: 200,
    },
    {
      age: '25-34',
      Male: 132,
      Female: 328,
      Others: 540,
    },
    {
      age: '35-44',
      Male: 530,
      Female: 145,
      Others: 335,
    },
    {
      age: '45-54',
      Male: 538,
      Female: 312,
      Others: 110,
    },
    {
      age: '55-64',
      Male: 538,
      Female: 312,
      Others: 110,
    },
    {
      age: '65+',
      Male: 538,
      Female: 312,
      Others: 0,
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { pageViewsByAgeGroupLoading, pageViewsByAgeGroupData } = useSelector(
    (state) => state.facebook
  );

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {pageViewsByAgeGroupLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Page Views by Age Group
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            <HorizontalStackedBarChart
              data={data}
              YAxis_dataKey="age"
              bar1DataKey="Female"
              bar2DataKey="Male"
              bar3DataKey="Others"
              XAxis_Label="Number of Likes"
              YAxis_Label="Age Range"
            />
          </>
        )}
      </Box>
    </>
  );
}

export default PageViewsAge;
