import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TableComponent from '../../TableComponent/TableComponent';
import { MoreHorizOutlined } from '@mui/icons-material';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Language',
    heading2: 'Likes',
    heading3: 'Likes%',
  },
];

function LikesByCity() {
  const { likesByCityLoading, likesByCityData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Likes by City
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {likesByCityLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <Grid container columns={12}>
            {likesByCityData?.data?.length > 0 ? (
              <TableComponent
                heading={heading}
                tabledata={likesByCityData?.data?.slice(0, 6)}
              />
            ) : (
              <NoDataComponent />
            )}
          </Grid>
        )}
      </Box>
    </>
  );
}

export default LikesByCity;
