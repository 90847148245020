import React, { useEffect } from 'react';
import FollowerGrowth from '../../../components/InstagramAudience/FollowerGrowth/FollowerGrowth';
import { Box, Divider, Grid } from '@mui/material';
import LikesByCountry from '../../../components/InstagramAudience/LikesByCountry/LikesByCountry';
import LikesByLanguage from '../../../components/InstagramAudience/LikesByLanguage/LikesByLanguage';
import LikesByCity from '../../../components/InstagramAudience/LikesByCity/LikesByCity';
import LikesByGender from '../../../components/InstagramAudience/LikesByGender/LikesByGender';
import {
  getLikeGrowthData,
  getLikesByCountyData,
  getLikesByLanguageData,
  getLikesByCityData,
  getLikesByGenderData,
  getLikesByAgeData,
} from '../../../redux/reduxThunk/instagram';
import { useSelector, useDispatch } from 'react-redux';
import IntagramLikesByAgeGroup from '../../../components/InstagramAudience/LikesByAgeGroup/IntagramLikesByAgeGroup';

function InstagramAudience() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchFacebookAudienceTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-05',
        toDate: '2023-05-01',
      };
      dispatch(getLikeGrowthData(summaryData));
      dispatch(getLikesByCountyData(summaryData));
      dispatch(getLikesByLanguageData(summaryData));
      dispatch(getLikesByCityData(summaryData));
      dispatch(getLikesByGenderData(summaryData));
      dispatch(getLikesByAgeData(summaryData));
    };
    fetchFacebookAudienceTabData();
  }, [audienceSummary]);
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <FollowerGrowth />
        </Grid>

        <LikesByCountry />

        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <LikesByLanguage />
          </Grid>
          <Grid lg={5.5} sx={{ m: 1 }}>
            <LikesByCity />
          </Grid>
        </Grid>
        <Grid container>
          <Grid lg={5.5} sx={{ m: 1 }}>
            <LikesByGender />
          </Grid>
          {/* <Divider orientation="vertical" flexItem /> */}
          <Grid lg={6} sx={{ m: 1 }}>
            <IntagramLikesByAgeGroup />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InstagramAudience;
