import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import ConnectionGrowth from '../../../components/MessengerComponents/ConectionGrowth'
import ConnectionLost from '../../../components/MessengerComponents/ConnectionLost'
import {
    getMessengerConnectionGrowthData,
    getMessengerConnectionLostData
} from "../../../redux/reduxThunk/facebook";
import { useSelector, useDispatch } from 'react-redux';

function Messenger() {
    const { audienceSummary } = useSelector((state) => state.facebook);
    const dispatch = useDispatch();
     // on load fetching API data and assigning to redux state
     const dataFetchedRef = React.useRef(false);


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        
        const fetchFacebookMessengerTabData = () => {
            const summaryData = {
                socialId: audienceSummary?.data?._id,
                socialAccountName: audienceSummary?.name,
                fromDate: "2023-04-02",
                toDate: "2023-04-13"
            }
            dispatch(getMessengerConnectionGrowthData(summaryData))
            dispatch(getMessengerConnectionLostData(summaryData))
        }
        fetchFacebookMessengerTabData()
    }, [audienceSummary])
    return (<>
        <Grid sx={{ width: '100%' }} container>
            <Grid container>
                <Grid lg={6} sx={{
                    width: '100%', bgcolor: '#fff', p: 4,
                    border: '2px solid #ECECEC', borderRadius: '8px',
                    m: 1
                }}>
                    <ConnectionGrowth />
                </Grid>

                <Grid lg={5.5} sx={{
                    width: '100%', bgcolor: '#fff', p: 4,
                    border: '2px solid #ECECEC', borderRadius: '8px',
                    m: 1
                }}>
                    <ConnectionLost />
                </Grid>
            </Grid>
        </Grid>
    </>
    )
}

export default Messenger