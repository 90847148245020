import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
  } from 'recharts';

function VerticalDualLargeSizeBar({dataKey1,dataKey2,verticalDualLargeBarData,xAxisLabel}) {
  return (
    <Grid container columns={12}>
      <ResponsiveContainer height={500} width={"100%"}>
        <BarChart
          data={verticalDualLargeBarData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisLabel} fontSize={16}/>
          <YAxis fontSize={16} domain={[0, dataMax => (dataMax * 1.5)]}/>
          <Tooltip itemStyle={{ fontSize: '16px' }} wrapperStyle={{ fontSize: '16px' }} />
          <Legend iconSize={14} wrapperStyle={{ fontSize: 18 }} iconType='circle'/>
          {dataKey1 ? <Bar dataKey={dataKey1} fill="#413ea0" barSize={45}/>:null}
          {dataKey2 ? <Bar dataKey={dataKey2} fill="#8884d8" barSize={45}/>:null}
        </BarChart>
        </ResponsiveContainer>
      </Grid>
  )
}

export default VerticalDualLargeSizeBar
