import React, { useCallback, useState } from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import InstagramReelsIcon from '../../../../assets/images/instagram-reels.png';
import TableComponent from '../../../TableComponent/TableComponent';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import DoughnutChart from '../../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import { useSelector } from 'react-redux';
import Loader from '../../../Loader';
import NoDataComponent from '../../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Engagegement',
    heading3: '%',
  },
];

function ReelsandEngagementSummary() {
  let { facebook, instagram } = useSelector((state) => state.user);
  let { reelsEngagementSummaryLoading, reelsEngagementSummaryData } =
    useSelector((state) => state.instagram);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Reels & Engagement Summary
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {reelsEngagementSummaryLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <Grid container columns={12}>
            <GridContainer3>
              <GridDataComponent
                heading="Total Reels"
                value={reelsEngagementSummaryData?.reelsCount}
                // percentRise="500"
                // avg="3"
              />

              <Grid mb={2} />
              <Divider />
              <Grid mb={2} />

              <GridDataComponent
                heading="Total Engagement"
                value={reelsEngagementSummaryData?.reelsEngagementCount}
                // percentFall="1240"
                // avg="6"
              />
            </GridContainer3>
            <Divider orientation="vertical" flexItem />

            <GridContainer3>
              <Typography variant="h5" className="subheading" mb={2}>
                Top Reel
              </Typography>

              {reelsEngagementSummaryData?.topReels && (
                <>
                  <Typography variant="p" className="subheading" mb={1}>
                    DIRECTLY VIA INSTAGRAM
                  </Typography>

                  <Typography
                    variant="p"
                    display="flex"
                    alignItems="center"
                    mb={1}
                  >
                    {/* <img
                    src={InstagramReelsIcon}
                    style={{
                      marginRight: '10px',
                      width: '15px',
                      height: '15px',
                    }}
                  /> */}
                    {reelsEngagementSummaryData?.topReels?.timestamp}
                  </Typography>
                  <Grid width="100%" mb={1}>
                    <img
                      className="image"
                      src={reelsEngagementSummaryData?.topReels?.imageUrl}
                      alt="img"
                    />
                  </Grid>

                  <Typography variant="p" display="flex" alignItems="center">
                    {reelsEngagementSummaryData?.topReels?.content}
                  </Typography>
                  {/* <Typography
                  variant="p"
                  display="flex"
                  alignItems="center"
                  sx={{ color: '#2C539A' }}
                >
                  {item.Hashtags}
                </Typography> */}
                  <Divider />
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Reactions
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.Reactions}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Comments
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.comments}
                    </Typography>
                  </Grid>

                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Shares
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.shares}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Saved
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.saved}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Engagement
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.engagement}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Plays
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.plays}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Reach
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsEngagementSummaryData?.topReels?.reach}
                    </Typography>
                  </Grid>
                </>
              )}
            </GridContainer3>

            <Divider orientation="vertical" flexItem />

            <Divider orientation="vertical" flexItem />
            <Grid lg={5.5} pl="15px">
              <Typography variant="h5" className="subheading">
                Reels Engagegements by Type
              </Typography>
              {reelsEngagementSummaryData?.reelEngageType?.data?.length > 0 ? (
                <>
                  <DoughnutChart
                    doughnutData={
                      reelsEngagementSummaryData?.reelEngageType?.doughnutData
                    }
                    dataKey="value"
                  />

                  <Grid height="100%">
                    <TableComponent
                      heading={heading}
                      tabledata={
                        reelsEngagementSummaryData?.reelEngageType?.data
                      }
                    />
                  </Grid>
                </>
              ) : (
                <NoDataComponent />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

export default ReelsandEngagementSummary;
