import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const heading = [
  {
    heading1: 'Gender',
    heading2: 'Likes',
    heading3: '%',
  },
];
const tabledata = [
  {
    data1: 'Male  ',
    data2: 267,
    data3: '58.17%',
  },
  {
    data1: 'Female  ',
    data2: 96,
    data3: '20.92%',
  },
  {
    data1: 'others  ',
    data2: 96,
    data3: '20.92%',
  },
];

const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Total', value: [sum] },
  { labels: 'Male', value: 267 },
  { labels: 'Female', value: 96 },
  { labels: 'Others', value: 96 },
];

function PageLikesByGender() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const {likesByGenderLoading,likesByGenderData} = useSelector((state)=>state.facebook)
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height:"100%",
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
          {likesByGenderLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
        <>
        <Grid display="flex" justifyContent="space-between">
          <Typography variant="h4" color="#000" fontWeight={600}>
            Page Likes By Gender
          </Typography>
          <MoreHorizOutlined fontSize="large" onClick={openMenu}/>
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />

        {likesByGenderData?.likesByGender?.length >0 ? 
        <DoughnutChart doughnutData={doughnutData} dataKey="value" />
        : <NoDataComponent />}
        <Grid height="100%" width="100%" display="flex" justifyContent="center">
          {likesByGenderData?.likesByGender?.length >0 ? 
           <TableComponent heading={heading} tabledata={likesByGenderData?.likesByGender} />
          :  null}
         
        </Grid>
        </>
        }
      </Box>
    </>
  );
}

export default PageLikesByGender;
