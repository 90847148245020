import { MoreHorizOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';

function PageCtaClicks() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Page CTA Clicks
          </Typography>
          <MoreHorizOutlined fontSize="large" onClick={openMenu}
            sx={{ cursor: 'pointer' }}/>
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PNG',
            'Export as PDF',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
      </Box>
    </>
  );
}

export default PageCtaClicks;
