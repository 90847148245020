import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Padding } from '@mui/icons-material';

export const useStyles = makeStyles({
  leftImage: {
    marginLeft: '5%',
    height: '65%',
    maxWidth: '100%',
    width: '85%',
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  logo: {
    height: '60px',
    width: '65px',
    marginTop: 50,
    marginBottom: '20px',
    objectFit: 'fill',

  },
  input: {
    height: '50px',
    width: '500px',
    border: 'solid 1px grey',
    borderRadius: '10px',
    marginBottom: '25px',
    marginTop: '10px',
    paddingLeft: '15px',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',

    '@media (min-width: 1800px)': {
      width: 700,
      height: 60,
    },
    '@media (min-width: 1600px)': {
      width: 600,
      height: 60,
    },
    '@media (max-width: 1226px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 1000px)': {
      width: 300,
      height: 40,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 35,
    },

  },

  submit_button: {
    height: '50px',
    width: '500px',
    borderRadius: '8px !important',
    color: 'white !important',
    backgroundColor: 'black !important',
    cursor: 'pointer',
    fontSize: 'large',
    marginBottom: '25px',
    '@media (min-width: 1800px)': {
      width: 700,
      height: 60,
    },
    '@media (min-width: 1600px)': {
      width: 600,
      height: 60,
    },
    '@media (max-width: 1226px)': {
      width: 400,
      height: 45,
    },
    '@media (max-width: 1000px)': {
      width: 300,
      height: 40,
    },
    '@media (max-width: 768px)': {
      width: 300,
      height: 35,
    },

  },
  phInput: {
    height: 45,
    width: 290,
    border: 'solid 1px #979797',
    borderRadius: '5px',
    marginBottom: '30px',
    marginLeft: '25px',
    marginTop: '10px',
    '@media (max-width: 1339px)': {
      width: 200,
      height: 45,
    },
    '@media (max-width: 768px)': {
      width: 200,
      height: 45,
    },
    '@media (min-width: 1600px)': {
      width: 350,
      height: 45,
    },
  },
  err: {
    // marginBottom: '20px',
    marginTop: '-20px',
    fontSize: 'small',
    font: 100,
    color: 'brown',
  },
  data_err: {
    marginBottom: '20px',
    color: 'brown',
    textAlign: 'center',
    fontSize: '16px',
  },
  terms: {
    color: 'blue',
    fontSize: '12px',
    cursor: 'pointer'
  },
  leftGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#F8FAFB',
    height: '100%',
  },
  rightGrid: {
    display: "flex",
    justifyContent: "center",
    // alignItems:'center',
    backgroundColor: '#fff',
    height: '100%',
    overflowY: 'auto',
  }
});


// color design tokens export
export const tokens = () => ({
  black: {
    100: '#000000',
    200: '#000000',
    300: '#000000',
    400: '#000000',
    500: '#000000',
    600: '#333333',
    700: '#666666',
    800: '#999999',
    900: '#cccccc',
  },
  blue: {
    100: '#003333',
    200: '#006666',
    300: '#009999',
    400: '#00cccc',
    500: '#00ffff',
    600: '#33ffff',
    700: '#66ffff',
    800: '#99ffff',
    900: '#ccffff',
  },
  green: {
    100: '#003300',
    200: '#006600',
    300: '#009900',
    400: '#00cc00',
    500: '#00ff00',
    600: '#33ff33',
    700: '#66ff66',
    800: '#99ff99',
    900: '#ccffcc',
  },
  red: {
    100: '#330000',
    200: '#660000',
    300: '#990000',
    400: '#cc0000',
    500: '#ff0000',
    600: '#ff3333',
    700: '#ff6666',
    800: '#ff9999',
    900: '#ffcccc',
  },
});

// mui theme settings
export const theme = createTheme({
  typography: {
    fontFamily: ['Rubik', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 42,
    },
    h2: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 34,
    },
    h3: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 28,
      fontWeight: 400,
    },
    h4: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 22,
      fontWeight: 400,
    },
    h5: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 16,
      fontWeight: 400,
    },
    h6: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
    },

    p: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 12,
      fontWeight: 400,
    },
    body1: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 10,
      fontWeight: 400,
    },
    body2: {
      fontFamily: ['Rubik', 'sans-serif'].join(','),
      fontSize: 8,
      fontWeight: 400,
    },
  },
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    grey: {
      50: 'rgb(52 65 76)',
      100: '#f5f5f5',
      400: '#BAC8D4',
      700: 'rgb(65 83 97)',
      800: 'rgb(42 55 66)',
      900: '#c4c9cd',
    },
    blue: {
      main: '#1273EB',
      dark: '',
    },
    common: {
      white: '#fff',
      black: '#000',
    },

    black: {
      100: '#000000',
      200: '#000000',
      300: '#000000',
      400: '#000000',
      500: '#000000',
      600: '#333333',
      700: '#666666',
      800: '#999999',
      900: '#cccccc',
    },
  },
});
