import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import TableComponent from '../../TableComponent/TableComponent';
import { useSelector } from 'react-redux';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

function ConnectionGrowth() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { messengerConnectionGrowthLoading, messengerConnectionGrowthData } =
    useSelector((state) => state.facebook);
  const data = [
    {
      name: "16 MAR' 23",
      Followers: 0,
      Friends: 5,
    },
    {
      name: "17 MAR' 23",
      Followers: 10,
      Friends: 15,
    },
    {
      name: "18 MAR' 23",
      Followers: 25,
      Friends: 50,
    },
    {
      name: "19 MAR' 23",
      Followers: 45,
      Friends: 80,
    },
    {
      name: "20 MAR' 23",
      Followers: 20,
      Friends: 12,
    },
    {
      name: "21 MAR' 23",
      Followers: 100,
      Friends: 88,
    },
    {
      name: "22 MAR' 23",
      Followers: 522,
      Friends: 680,
    },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
        {messengerConnectionGrowthLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Connections Growth
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {messengerConnectionGrowthData?.totalCount > 0 ? (
              <BarAndAreaChart
                data={messengerConnectionGrowthData?.data}
                XAxis_dataKey="name"
                barDataKey="Followers"
                area1DataKey="Friends"
                YAxis_Label="Connections Lost"
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ConnectionGrowth;
