import { Avatar, Grid, Typography } from '@mui/material'
import React from 'react'
import { NoData } from '../../../assets/assets/assets'

function NoDataComponent() {
  return (
    <>
    <Grid width='100%' height='100%' p={2} >
        <Grid  display='flex' justifyContent='center'>
        <Avatar src={NoData} variant='square' sx={{width:'30%',height:'80%'}} alt='no data'/>
        </Grid>
        <Grid  display='flex' justifyContent='center'>
        <Typography variant='h5' color='#767676'>
        There is no data available currently for this report.
        </Typography>
        </Grid>
        
    </Grid>
    </>
  )
}

export default NoDataComponent