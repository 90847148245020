import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Gender',
    heading2: 'Likes',
    heading3: '%',
  },
];

function LikesByGender() {
  const { likesByGenderLoading, likesByGenderData } = useSelector(
    (state) => state.instagram
  );
  console.log('likesByGenderData', likesByGenderData);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between">
          <Typography variant="h4" color="#000" fontWeight={600}>
            Likes By Gender
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {likesByGenderLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : likesByGenderData?.data?.length > 0 ? (
          <>
            <DoughnutChart
              doughnutData={likesByGenderData?.doughnutData}
              dataKey="value"
            />

            <TableComponent
              heading={heading}
              tabledata={likesByGenderData?.data}
            />
          </>
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default LikesByGender;
