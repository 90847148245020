import React, { useCallback, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { useSelector } from 'react-redux';

function PiChart({ piData, dataKey }) {
  const COLORS = ['#413ea0', '#7a40ed', '#bba2ec', '#dcd5eb'];
  const { performanceSummaryData } = useSelector((state) => state.facebook);
  const { impressionsSummaryData } = useSelector((state) => state.facebook);

  return (
    <Box>
      <Grid  >
        <Grid height={180} width="100%">
          <ResponsiveContainer width="100%" height={200}>
            <PieChart>
              <Pie
                data={piData}
                cx={70}
                cy={80}
                outerRadius={60}
                fill="#8884d8"
                dataKey="value"
              >
                {piData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PiChart;
