import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridContainer from '../../../Common/gridContainer/GridContainer';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useSelector } from 'react-redux';
import PiChart from '../../../Common/ChartComponents/PiCharts/PiCharts';
import Loader from '../../../Loader';
import NoDataComponent from '../../../Common/NoDataComponent';

function InstagramAudienceSummary() {
  let { facebook, instagram } = useSelector((state) => state.user);
  // const { audienceSummaryData } = useSelector((state) => state.facebook);
  const { audienceSummaryDataLoading, audienceSummaryData } = useSelector(
    (state) => state.instagram
  );

  const piData = [
    {
      name: 'Positive',
      value: audienceSummaryData?.totalFollowers,
      percentage: audienceSummaryData?.totalFollowers,
    },
    {
      name: 'Negative',
      value: audienceSummaryData?.newFollowers,
      percentage: audienceSummaryData?.newFollowers,
    },
  ];
  const COLORS = ['#413ea0', '#7a40ed'];
  console.log('instagramapi audienceSummaryData', audienceSummaryData);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {audienceSummaryDataLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid
              display="flex"
              justifyContent="space-between"
              width="100%"
              mb={4}
            >
              <Typography variant="h4" color="#000" fontWeight={600}>
                Audience Summary
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            <Grid container columns={12}>
              <GridContainer>
                <GridDataComponent
                  heading="Total Followers"
                  value={audienceSummaryData?.totalFollowers}
                />
              </GridContainer>
              <Divider orientation="vertical" flexItem />
              <GridContainer3>
                <GridDataComponent
                  heading="New Followers"
                  value={audienceSummaryData?.newFollowers}
                  percentRise={(
                    (audienceSummaryData?.newFollowers * 100) /
                    100
                  ).toFixed(2)}
                  avg={audienceSummaryData?.newFollowers}
                />
              </GridContainer3>

              <Divider orientation="vertical" flexItem />
              {/* <GridContainer3>
                <GridDataComponent heading="Followers Lost" />
              </GridContainer3>
              <Divider orientation="vertical" flexItem />
              <Grid lg={3} p="8px 15px">
                <Typography variant="h5" className="subheading">
                  ORGANIC VS PAID Followers
                </Typography>
                <Grid width="100%">
                  <Grid container width="100%">
                    <Grid width="100%" lg={7}>
                      {audienceSummaryData?.totalFollowers > 0 ? (
                        <>
                          <PiChart piData={piData} dataKey="value" />
                        </>
                      ) : (
                        <NoDataComponent />
                      )}
                    </Grid>
                    {audienceSummaryData?.totalFollowers > 0 ? (
                      <Grid
                        lg={5}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box sx={{ mb: 5 }}>
                          {piData?.map((item) => (
                            <Box display="flex">
                              <Typography variant="h5" sx={{ ml: 1 }}>
                                {item.percentage}
                              </Typography>
                              <Typography variant="h5">{item.name}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}
export default InstagramAudienceSummary;
