import API from './index';

// summary tab apis
export const getAudienceSummary = (data) => API.post(`/audience-summary`, data);
export const getPostEngagementSummary = (data) =>
  API.post('/post-engagement-summary', data);
export const getPerformanceSummary = (data) =>
  API.post('/performance-summary', data);
export const getImpressionsSummary = (data) =>
  API.post('/impressions-summary', data);
export const getMessengerSummary = (data) =>
  API.post('/messenger-summary', data);

// audience
export const getLikeGrowth = (data) => API.post('/like-growth', data);
export const getPaidOrganicLike = (data) =>
  API.post(`/paid-organic-likes`, data);
export const getLikeSources = (data) => API.post(`/like-sources`, data);
export const getUnlikeSources = (data) => API.post(`/unlike-sources`, data);
export const getLikesByCounty = (data) => API.post(`/likes-by-county`, data);
export const getLikesByLanguage = (data) =>
  API.post(`/likes-by-language`, data);
export const getLikesByCity = (data) => API.post(`/likes-by-city`, data);
export const getLikesByGender = (data) => API.post(`/likes-by-gender`, data);
export const getLikesByAge = (data) => API.post(`/likes-by-age`, data);

// Posts & Engagement
export const getPostsByType = (data) => API.post(`/posts-by-type`, data);
export const getTopPosts = (data) => API.post(`/top-posts`, data);
export const getPostsEngagementImpression = (data) =>
  API.post(`/posts-engagement-impression`, data);
export const getPostsByApps = (data) => API.post(`/posts-by-apps`, data);
export const getEngagementByType = (data) =>
  API.post(`/engagement-by-type`, data);
export const getReactionsByType = (data) =>
  API.post(`/reactions-by-type`, data);
export const getEngagementTypeActions = (data) =>
  API.post(`/engagement-type-actions`, data);

//Performance
export const getPageProfileViews = (data) => API.post(`/profile-views`, data);
export const getPageTabViews = (data) => API.post(`/tab-views`, data);
export const getPageViewsByInternal = (data) =>
  API.post(`/views-by-internal-referral`, data);
export const getPageViewsByExternal = (data) =>
  API.post(`/views-by-external-referral`, data);
export const getPageViewsByGender = (data) =>
  API.post(`/views-by-gender`, data);
export const getPageViewsByAgeGroup = (data) => API.post(`/views-by-age`, data);
export const getPageClicks = (data) => API.post(`/page-clicks`, data);
export const getPageClicksByPost = (data) =>
  API.post(`/post-page-clicks`, data);
export const getPostsPositiveFeedback = (data) =>
  API.post(`/posts-positive-feedback`, data);
export const getPostsNegativeFeedback = (data) =>
  API.post(`/posts-negative-feedback`, data);
export const getCtaClickTypes = (data) => API.post(`/cta-click-types`, data);

//Reach & impression
export const getPageReachImpression = (data) =>
  API.post(`/page-reach-impression`, data);
export const getPaidOrganicReach = (data) =>
  API.post(`/paid-organic-reach`, data);
export const getViralReachImpressions = (data) =>
  API.post(`/viral-reach-impressions`, data);
export const getShareImpressionsByType = (data) =>
  API.post(`/share-impressions-by-type`, data);
export const getPageShares = (data) => API.post(`/page-shares`, data);
export const getShareByType = (data) => API.post(`/share-by-type`, data);
export const getReachByCountry = (data) => API.post(`/reach-by-country`, data);
export const getReachByLanguage = (data) =>
  API.post(`/reach-by-language`, data);
export const getReachByCity = (data) => API.post(`/reach-by-city`, data);
export const getReachByGender = (data) => API.post(`/reach-by-gender`, data);

// messenger
export const getMessengerConnectionGrowth = (data) =>
  API.post(`/messenger-connection-growth`, data);
export const getMessengerConnectionLost = (data) =>
  API.post(`/messenger-connection-lost`, data);
