import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import PostAndEngagement from '../../../components/SummaryComponents/InstagramSummary/PostAndEngagementSummary/PostAndEngagement';
import ReelsandEngagementSummary from '../../../components/SummaryComponents//InstagramSummary/ReelsandEngagementSummary/ReelsandEngagementSummary';
import StorySummary from '../../../components/SummaryComponents//InstagramSummary/StorySummary/StorySummary';
import InstagramAudienceSummary from '../../../components/SummaryComponents/InstagramSummary/AudienceSummary/InstagramAudienceSummary';
import InstagramPerformanceSummary from '../../../components/SummaryComponents/InstagramSummary/PerformanceSummary/InstagramPerformanceSummary';
import InstagramImpressionSummary from '../../../components/SummaryComponents/InstagramSummary/ImpressionSummary/InstagramImpressionSummary';

import { useSelector, useDispatch } from 'react-redux';
import {
  getAudienceSummaryData,
  getPerformanceSummaryData,
  getPostEngagementSummaryData,
  getImpressionsSummaryData,
  getReelsEngagementSummaryData,
  getStorySummaryData,
} from '../../../redux/reduxThunk/instagram';
ChartJS.register(ArcElement, Tooltip, Legend);

function InstagramSummary() {
  let { facebook, instagram } = useSelector((state) => state.user);
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchInstagramSummaryTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-05',
        toDate: '2023-05-01',
      };
      dispatch(getAudienceSummaryData(summaryData));
      dispatch(getPerformanceSummaryData(summaryData));
      dispatch(getPostEngagementSummaryData(summaryData));
      dispatch(getImpressionsSummaryData(summaryData));
      dispatch(getReelsEngagementSummaryData(summaryData));
      dispatch(getStorySummaryData(summaryData));
    };
    fetchInstagramSummaryTabData();
  }, [audienceSummary]);
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <InstagramAudienceSummary />
        <PostAndEngagement />
        <InstagramPerformanceSummary />
        <InstagramImpressionSummary />
        <ReelsandEngagementSummary />
        <StorySummary />
      </Grid>
    </>
  );
}

export default InstagramSummary;
