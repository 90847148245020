import API from './index';

// summary tab apis
export const getAudienceSummary = (data) => API.post(`/audience-summary`, data);
export const getPostEngagementSummary = (data) =>
  API.post('/post-engagement-summary', data);
export const getPerformanceSummary = (data) =>
  API.post('/performance-summary', data);
export const getImpressionsSummary = (data) =>
  API.post('/impressions-summary', data);
export const getReelsEngagementSummary = (data) =>
  API.post('/reels-engagement-summary', data);
export const getStorySummary = (data) => API.post('/story-summary', data);

// audience
export const getLikeGrowth = (data) => API.post('/follower-growth', data);
export const getLikesByCounty = (data) => API.post(`/likes-by-county`, data);
export const getLikesByLanguage = (data) =>
  API.post(`/likes-by-language`, data);
export const getLikesByCity = (data) => API.post(`/likes-by-city`, data);
export const getLikesByGender = (data) => API.post(`/likes-by-gender`, data);
export const getLikesByAge = (data) => API.post(`/likes-by-age`, data);

// Post & Engagement
export const getPostsEngagementImpression = (data) =>
  API.post(`/posts-engagement-impression`, data);
export const getPostsByType = (data) => API.post(`/posts-by-type`, data);
export const getTopPosts = (data) => API.post(`/top-posts`, data);
export const getEngagementByType = (data) =>
  API.post(`/engagement-by-type`, data);
export const getEngagementTypeActions = (data) =>
  API.post(`/engagement-type-actions`, data);

// page-reach-impression
export const getPageReachImpression = (data) =>
  API.post('/page-reach-impression', data);

// Performance
export const getProfileViews = (data) => API.post('/profile-views', data);
export const getPageClicks = (data) => API.post('/page-clicks', data);
export const getCtaClickTypes = (data) => API.post('/cta-click-types', data);

// REELS AND MANAGEMENT
export const getReelsVsEngagement = (data) =>
  API.post('/reels-vs-engagement', data);
export const getReelsPlaysVsReach = (data) =>
  API.post('/reels-plays-reach', data);
export const getTop5Reels = (data) => API.post('/top-5-reels', data);
export const getReelsEngagementActions = (data) =>
  API.post('/reels-engagement-actions', data);
export const getReelsEngagementType = (data) =>
  API.post('/reels-engagement-type', data);

// Stories
export const getStoriesVsEngagement = (data) =>
  API.post('/stories-vs-engagement', data);
export const getStoriesEngagementType = (data) =>
  API.post('/stories-engagement-type', data);
export const getTopStories = (data) => API.post('/top-stories', data);
export const getImpressionsVsReach = (data) =>
  API.post('/impressions-vs-reach', data);
export const getFollowersVsReach = (data) =>
  API.post('/followers-vs-reach', data);
export const getExitsVsReach = (data) => API.post('/exits-vs-reach', data);
export const getStoriesCompletionRate = (data) =>
  API.post('/stories-completion-rate', data);
