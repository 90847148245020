import React from 'react';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Button, Dialog, Grid } from '@mui/material';
import './style.css';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import AudienceSummary from '../../SummaryComponents/FacebookSummaryComponents/AudienceSummary/AudienceSummary';
import PostsAndEngagements from '../../../pages/facebookAnalytics/PostsAndEngagements/PostsAndEngagements';
import FacebookAnalyticTabs from '../../../pages/facebookAnalytics/FacebookAnalyticTabs';
import { handleAddCards } from '../../../redux/Slice/addCards';
import { useDispatch, useSelector } from 'react-redux';
import PostAndEngagement from '../../SummaryComponents/FacebookSummaryComponents/PostAndEngagementSummary/PostAndEngagement';
import PageLikeGrowth from '../../Audience/PageLikeGrowth/PageLikeGrowth';
import ImpressionSummary from '../../SummaryComponents/FacebookSummaryComponents/ImpressionSummary/ImpressionSummary';
import MessengerSummary from '../../SummaryComponents/FacebookSummaryComponents/MessengerSummary/MessengerSummary';
import PerformanceSummary from '../../SummaryComponents/FacebookSummaryComponents/PerformanceSummary/PerformanceSummary';
import OrganicVsPaidLikes from '../../Audience/OrganicVsPaidLikes/OrganicVsPaidLikes';
import PageLikesBySoure from '../../Audience/PageLikesBySource/PageLikesBySoure';
import PageUnlikeBySource from '../../Audience/PageUnlikeBySource/PageUnlikeBySource';
import PageLikesByCountry from '../../Audience/PageLikesByCountry/PageLikesByCountry';
import PageLikesByLanguage from '../../Audience/PageLikesByLanguage/PageLikesByLanguage';
import PageLikesByCity from '../../Audience/PageLikesByCity/PageLikesByCity';
import PageLikesByGender from '../../Audience/PageLikesByGender/PageLikesByGender';
import LikesByAgeGroup from '../../Audience/LikesByAgeGroup/LikesByAgeGroup';
import PostByType from '../../PostAndEngageMentComponents/PostByType/PostByType';
import TopPostsBy from '../../PostAndEngageMentComponents/TopPostsBy/TopPostsBy';
import TopConnections from '../../PostAndEngageMentComponents/TopConnections/TopConnections';
import PostsByPublishingApps from '../../PostAndEngageMentComponents/PostsByPublishingApps';
import PostEngagementVsImpresion from '../../PostAndEngageMentComponents/PostEngagementVsImpresions/PostEngagementVsImpresion';
import EngagementByPostType from '../../PostAndEngageMentComponents/EngagementByPostType/EngagementByPostType';
import TypeOfEngagementActions from '../../PostAndEngageMentComponents/TypeofEngagementActions';
import PostReactionByType from '../../PostAndEngageMentComponents/PostReactionByType';
import PageProfileViews from '../../Performance Components/PageProfileViews/PageProfileViews';
import PageTabViews from '../../Performance Components/PageTabViews/PageTabViews';
import PageViewIntRefferrers from '../../Performance Components/PageViewsIntRefferers/PageViewIntRefferrers';
import PageViewExtRefferrers from '../../Performance Components/PageViewsExtRefferers/PageViewExtRefferrers';
import PageViewsByGender from '../../Performance Components/PageViewsGender/PageViewsGender';
import PageViewsAge from '../../Performance Components/PageViewsAge/PageViewsAge';
import PageClicks from '../../Performance Components/PageClicks/PageClicks';
import PageClicksPostType from '../../Performance Components/PageClicksPostType/PageClicksPostType';
import PositiveFeedback from '../../Performance Components/PositiveFeedback/PositiveFeedback';
import NegativeFeedback from '../../Performance Components/NegativeFeedback/NegativeFeedback';
import PageCtaClicks from '../../Performance Components/PageCtaClicks/PageCtaclicks';
import PageClicksType from '../../Performance Components/PageClicksType/PageClicksType';
import PageImpressionsvsReach from '../../ReachandImpressionComponents/PageImpressionsvsReach/PageImpressionsvsReach';
import OrganicandPaidPagevsReach from '../../ReachandImpressionComponents/OrganicandPaidPagevsReach/OrganicandPaidPagevsReach';
import ViralandNonViralpagevsReach from '../../ReachandImpressionComponents/ViralandNonViralPagevsReach/ViralandNonViralpagevsReach';
import PageShareImpressionType from '../../ReachandImpressionComponents/PageShareImpressions/PageShareImpressionType';
import PageShares from '../../ReachandImpressionComponents/PageShares/PageShares';
import PageSharesbytype from '../../ReachandImpressionComponents/PageSharesbyType/PageSharesbytype';
import PageReachbyCountry from '../../ReachandImpressionComponents/PageReachbyCountry/PageReachbyCountry';
import PageReachbyLanguage from '../../ReachandImpressionComponents/PageReachbyLanguage/PageReachbyLanguage';
import PageReachesByCity from '../../ReachandImpressionComponents/PageReachbyCity/PageReachbyCity';
import PageReachesByGender from '../../ReachandImpressionComponents/PageReachbyGender/PageReachbyGender';
import PageReachAgeGroup from '../../ReachandImpressionComponents/PageReachbyAgeGroup/PageReachAgeGroup';
import ConnectionGrowth from '../../MessengerComponents/ConectionGrowth';
import ConnectionLost from '../../MessengerComponents/ConnectionLost';
import InstagramAudience from '../../../pages/Instagram/InstagramAudiance/InstagramAudiance';
import PostEngagement from '../../InstagramPost&mangement/PostEngagement/PostEngagement';
import StorySummary from '../../SummaryComponents/InstagramSummary/StorySummary/StorySummary';
import InstagramPerformanceSummary from '../../SummaryComponents/InstagramSummary/PerformanceSummary/InstagramPerformanceSummary';
import InstagramImpressionSummary from '../../SummaryComponents/InstagramSummary/ImpressionSummary/InstagramImpressionSummary';
import ReelsandEngagementSummary from '../../SummaryComponents/InstagramSummary/ReelsandEngagementSummary/ReelsandEngagementSummary';
import FollowerGrowth from '../../InstagramAudience/FollowerGrowth/FollowerGrowth';
import LikesByCountry from '../../InstagramAudience/LikesByCountry/LikesByCountry';
import LikesByLanguage from '../../InstagramAudience/LikesByLanguage/LikesByLanguage';
import LikesByCity from '../../InstagramAudience/LikesByCity/LikesByCity';
import LikesByGender from '../../InstagramAudience/LikesByGender/LikesByGender';
import IntagramLikesByAgeGroup from '../../InstagramAudience/LikesByAgeGroup/IntagramLikesByAgeGroup';
import NumofPostsVsEngagement from '../../PostAndEngageMentComponents/NumofPostsVsEngagement/NumofPostsVsEngagement';
import InstagramPostByType from '../../InstagramPost&mangement/PostByType/InstagramPostByType';
import InstagramTopPostBy from '../../InstagramPost&mangement/TopPostBy/InstagramTopPostBy';
import InstagramPostAndEngagement from '../../../pages/Instagram/InstagramPostAndEngagement';
import InstgramEngagementByPostType from '../../InstagramPost&mangement/EngagementByPostType/InstgramEngagementByPostType';
import EngagementActions from '../../InstagramPost&mangement/EngagementActions/EngagementActions';
import ProfileViews from '../../InstagramPerformanceComponents/ProfileViews/ProfileViews';
import ProfileClicks from '../../InstagramPerformanceComponents/ProfileClicks/ProfileClicks';
import InstagramCtaClicksbyType from '../../InstagramPerformanceComponents/CtaClicksByType/InstagramCtaClicksbyType';
import ImpressionsVsReach from '../../../pages/Instagram/ImpressionsVsReach';
import NumOfReelsVsEngagement from '../../ReelsAndEngagement/NumOfReelsVsEngagement';
import ReelsPlayVsReach from '../../ReelsAndEngagement/ReelsPlayVsReach';
import TopReelsBy from '../../ReelsAndEngagement/TopReelsBy';
import ReelsEngagementActions from '../../ReelsAndEngagement/ReelsEngagementActions';
import ReelsEngagementByType from '../../ReelsAndEngagement/ReelsEngagementByType/ReelsEngagementByType';
import StoryVsEngagement from '../../StoryComponents/StoryVsEngagement';
import StoryEngagementByType from '../../StoryComponents/StoryEngagementByType';
import TopStoriesBy from '../../StoryComponents/TopStoriesBy';
import StoriesVsImpressions from '../../StoryComponents/StoriesVsImpressions';
import StoryImpressionVsReach from '../../StoryComponents/StoryImpressionVsReach';
import TotalFollowersVsReach from '../../StoryComponents/TotalFollowersVsReach';
import AvgExitsVsNumOfStories from '../../StoryComponents/AvgExitsVsNumOfStories';
import InstagramAudienceSummary from '../../SummaryComponents/InstagramSummary/AudienceSummary/InstagramAudienceSummary';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,

  height: '40px',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  pointerEvents: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  border: alpha(theme.palette.common.black, 0.5),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    display: 'flex',
    justifyContent: 'center',
  },
}));

const cardsList = [
  {
    id: 1,
    name: 'Audience Summary',
    platform: 'Facebook',
    component: <AudienceSummary />,
  },
  {
    id: 2,

    name: 'Post & Engagement Summary',
    platform: 'Facebook',
    component: <PostAndEngagement />,
  },
  {
    id: 3,
    name: 'Performane Summary',
    platform: 'Facebook',
    component: <PerformanceSummary />,
  },
  {
    id: 4,
    name: 'Impression Summary',
    platform: 'Facebook',
    component: <ImpressionSummary />,
  },
  {
    id: 5,
    name: 'Messanger Insights Summary',
    platform: 'Facebook',
    component: <MessengerSummary />,
  },
  {
    id: 6,
    name: 'Page Like Growth',
    platform: 'Facebook',
    component: <PageLikeGrowth />,
  },
  {
    id: 7,
    name: 'Organic vs Paid Page Likes',
    platform: 'Facebook',
    component: <OrganicVsPaidLikes />,
  },
  {
    id: 8,
    name: 'Page Likes by Source',
    platform: 'Facebook',
    component: <PageLikesBySoure />,
  },
  {
    id: 9,
    name: 'Page Unlikes by Source',
    platform: 'Facebook',
    component: <PageUnlikeBySource />,
  },
  {
    id: 10,
    name: 'Page Likes by Country',
    platform: 'Facebook',
    component: <PageLikesByCountry />,
  },
  {
    id: 11,
    name: 'Page Likes by Language',
    platform: 'Facebook',
    component: <PageLikesByLanguage />,
  },
  {
    id: 12,
    name: 'Page Likes by City',
    platform: 'Facebook',
    component: <PageLikesByCity />,
  },
  {
    id: 13,
    name: 'Page Likes by Gender',
    platform: 'Facebook',
    component: <PageLikesByGender />,
  },
  {
    id: 14,
    name: 'Page Likes by Age Group',
    platform: 'Facebook',
    component: <LikesByAgeGroup />,
  },
  {
    id: 15,
    name: 'Number of Posts vs Engagement',
    platform: 'Facebook',
    component: <PostsAndEngagements />,
  },
  {
    id: 16,
    name: 'Posts by Type',
    platform: 'Facebook',
    component: <PostByType />,
  },
  {
    id: 17,
    name: 'Top Posts',
    platform: 'Facebook',
    component: <TopPostsBy />,
  },
  {
    id: 18,
    name: 'Top Connections',
    platform: 'Facebook',
    component: <TopConnections />,
  },
  {
    id: 19,
    name: 'Post by Publishing Apps',
    platform: 'Facebook',
    component: <PostsByPublishingApps />,
  },
  {
    id: 20,
    name: 'Best Time to Post',
    platform: 'Facebook',
    component: '',
  },
  {
    id: 21,
    name: 'Post Engagement vs Impressions',
    platform: 'Facebook',
    component: <PostEngagementVsImpresion />,
  },
  {
    id: 22,
    name: 'Engagement by Post Type',
    platform: 'Facebook',
    component: <EngagementByPostType />,
  },
  {
    id: 23,
    name: 'Types of Engagement Actions',
    platform: 'Facebook',
    component: <TypeOfEngagementActions />,
  },
  {
    id: 24,
    name: 'Post Reactions by Type',
    platform: 'Facebook',
    component: <PostReactionByType />,
  },
  {
    id: 25,
    name: 'Page Profile Views',
    platform: 'Facebook',
    component: <PageProfileViews />,
  },
  {
    id: 26,
    name: 'Page Tab Views',
    platform: 'Facebook',
    component: <PageTabViews />,
  },

  {
    id: 27,
    name: 'Page Views via Internal Referrers',
    platform: 'Facebook',
    component: <PageViewIntRefferrers />,
  },
  {
    id: 28,
    name: 'Page Views via External Referrers',
    platform: 'Facebook',
    component: <PageViewExtRefferrers />,
  },
  {
    id: 29,
    name: 'Page Views by Gender',
    platform: 'Facebook',
    component: <PageViewsByGender />,
  },
  {
    id: 30,
    name: 'Page Views by Age Group',
    platform: 'Facebook',
    component: <PageViewsAge />,
  },
  {
    id: 31,
    name: 'Page Clicks',
    platform: 'Facebook',
    component: <PageClicks />,
  },
  {
    id: 32,
    name: 'Page Clicks by Post Type',
    platform: 'Facebook',
    component: <PageClicksPostType />,
  },
  {
    id: 33,
    name: 'Positive Feedback',
    platform: 'Facebook',
    component: <PositiveFeedback />,
  },
  {
    id: 34,
    name: 'Negative Feedback',
    platform: 'Facebook',
    component: <NegativeFeedback />,
  },
  {
    id: 35,
    name: 'Page CTA Clicks',
    platform: 'Facebook',
    component: <PageCtaClicks />,
  },
  {
    id: 36,
    name: 'CTA Clicks by Type',
    platform: 'Facebook',
    component: <PageClicksType />,
  },
  {
    id: 37,
    name: 'Page Impressions vs Reach',
    platform: 'Facebook',
    component: <PageImpressionsvsReach />,
  },
  {
    id: 38,
    name: 'Organic & Paid Page Impressions vs Reach',
    platform: 'Facebook',
    component: <OrganicandPaidPagevsReach />,
  },
  {
    id: 39,
    name: 'Viral & Non-viral Page Impressions vs Reach',
    platform: 'Facebook',
    component: <ViralandNonViralpagevsReach />,
  },
  {
    id: 40,
    name: 'Page Share Impressions by Type',
    platform: 'Facebook',
    component: <PageShareImpressionType />,
  },
  {
    id: 41,
    name: 'Page Shares',
    platform: 'Facebook',
    component: <PageShares />,
  },
  {
    id: 42,
    name: 'Page Shares by Type',
    platform: 'Facebook',
    component: <PageSharesbytype />,
  },
  {
    id: 43,
    name: 'Page Reach by Country',
    platform: 'Facebook',
    component: <PageReachbyCountry />,
  },
  {
    id: 44,
    name: 'Page Reach by Language',
    platform: 'Facebook',
    component: <PageReachbyLanguage />,
  },
  {
    id: 45,
    name: 'Page Reach by City',
    platform: 'Facebook',
    component: <PageReachesByCity />,
  },
  {
    id: 46,
    name: 'Page Reach by Gender',
    platform: 'Facebook',
    component: <PageReachesByGender />,
  },
  {
    id: 47,
    name: 'Page Reach by Age Group',
    platform: 'Facebook',
    component: <PageReachAgeGroup />,
  },
  {
    id: 48,
    name: 'Connection Growth',
    platform: 'Facebook',
    component: <ConnectionGrowth />,
  },
  {
    id: 49,
    name: 'Connection Lost',
    platform: 'Facebook',
    component: <ConnectionLost />,
  },
  {
    id: 50,
    name: 'Audience Summary',
    platform: 'Instagram',
    component: <InstagramAudienceSummary />,
  },
  {
    id: 51,
    name: 'Post & Engagement Summary',
    platform: 'Instagram',
    component: <PostEngagement />,
  },
  {
    id: 52,
    name: 'Story Summary',
    platform: 'Instagram',
    component: <StorySummary />,
  },
  {
    id: 53,
    name: 'Performane Summary',
    platform: 'Instagram',
    component: <InstagramPerformanceSummary />,
  },
  {
    id: 54,
    name: 'Impression Summary',
    platform: 'Instagram',
    component: <InstagramImpressionSummary />,
  },
  {
    id: 55,
    name: 'Reels & Engagement Summary',
    platform: 'Instagram',
    component: <ReelsandEngagementSummary />,
  },
  {
    id: 56,
    name: 'Follower Growth',
    platform: 'Instagram',
    component: <FollowerGrowth />,
  },
  {
    id: 57,
    name: 'Likes by Country',
    platform: 'Instagram',
    component: <LikesByCountry />,
  },
  {
    id: 58,
    name: 'Likes by Language',
    platform: 'Instagram',
    component: <LikesByLanguage />,
  },
  {
    id: 59,
    name: 'Likes by City',
    platform: 'Instagram',
    component: <LikesByCity />,
  },
  {
    id: 60,
    name: 'Likes by Gender',
    platform: 'Instagram',
    component: <LikesByGender />,
  },
  {
    id: 61,
    name: 'Likes by Age Group',
    platform: 'Instagram',
    component: <IntagramLikesByAgeGroup />,
  },
  {
    id: 62,
    name: 'Number of Posts vs Engagement',
    platform: 'Instagram',
    component: <NumofPostsVsEngagement />,
  },
  {
    id: 63,
    name: 'Posts by Type',
    platform: 'Instagram',
    component: <InstagramPostByType />,
  },
  {
    id: 64,
    name: 'Top Posts By',
    platform: 'Instagram',
    component: <InstagramTopPostBy />,
  },
  {
    id: 65,
    name: 'Best Time To Post',
    platform: 'Instagram',
    component: '',
  },
  {
    id: 66,
    name: 'Post Engagement',
    platform: 'Instagram',
    component: <InstagramPostAndEngagement />,
  },
  {
    id: 67,
    name: 'Engagement by Post Type',
    platform: 'Instagram',
    component: <InstgramEngagementByPostType />,
  },
  {
    id: 68,
    name: 'Engagement Actions',
    platform: 'Instagram',
    component: <EngagementActions />,
  },
  {
    id: 69,
    name: 'Profile Views',
    platform: 'Instagram',
    component: <ProfileViews />,
  },
  {
    id: 70,
    name: 'Profile Clicks',
    platform: 'Instagram',
    component: <ProfileClicks />,
  },
  {
    id: 71,
    name: 'CTA Clicks by Type',
    platform: 'Instagram',
    component: <InstagramCtaClicksbyType />,
  },
  {
    id: 72,
    name: 'Impressions vs Reach',
    platform: 'Instagram',
    component: <ImpressionsVsReach />,
  },
  {
    id: 73,
    name: 'Number of Reels vs Engagement',
    platform: 'Instagram',
    component: <NumOfReelsVsEngagement />,
  },
  {
    id: 74,
    name: 'Reels Plays vs Reach',
    platform: 'Instagram',
    component: <ReelsPlayVsReach />,
  },
  {
    id: 75,
    name: 'Top Reels',
    platform: 'Instagram',
    component: <TopReelsBy />,
  },
  {
    id: 76,
    name: 'Reels Engagement Actions',
    platform: 'Instagram',
    component: <ReelsEngagementActions />,
  },
  {
    id: 77,
    name: 'Reels Engagements by Type',
    platform: 'Instagram',
    component: <ReelsEngagementByType />,
  },
  {
    id: 78,
    name: 'Number of Stories vs Engagement',
    platform: 'Instagram',
    component: <StoryVsEngagement />,
  },
  {
    id: 79,
    name: 'Story Engagements by Type',
    platform: 'Instagram',
    component: <StoryEngagementByType />,
  },
  {
    id: 80,
    name: 'Top Stories',
    platform: 'Instagram',
    component: <TopStoriesBy />,
  },
  {
    id: 81,
    name: 'Number of Stories vs Impressions',
    platform: 'Instagram',
    component: <StoriesVsImpressions />,
  },
  {
    id: 82,
    name: 'Story Impressions vs Reach',
    platform: 'Instagram',
    component: <StoryImpressionVsReach />,
  },
  {
    id: 83,
    name: 'Total Followers vs Story Reach',
    platform: 'Instagram',
    component: <TotalFollowersVsReach />,
  },
  {
    id: 84,
    name: 'Average Exits vs Number of Stories',
    platform: 'Instagram',
    component: <AvgExitsVsNumOfStories />,
  },
  {
    id: 85,
    name: 'Stories Completion Rate',
    platform: 'Instagram',
    component: '',
  },
];

function AddCards({ handleClose }) {
  const [filteredCards, setFilteredCards] = React.useState(cardsList);
  const [activeButton, setActiveButton] = React.useState('');
  const { selectedCardComponent, activeComponentName } = useSelector(
    (state) => state.selectedCardComponent
  );
  const dispatch = useDispatch();

  function filterCardsList(platform) {
    if (!platform) {
      return cardsList;
    }
    return cardsList.filter((item) => item.platform === platform);
  }

  function handleFilter(platform) {
    const filtered = filterCardsList(platform);
    setFilteredCards(filtered);
    setActiveButton(platform);
  }

  function handleCardSelection(component) {
    var updatedComponent = [component]

    const updatedDashboard = selectedCardComponent.map((dashboard) => {
      if (dashboard.name=== activeComponentName) {
          updatedComponent = [...dashboard?.components,component];
        return {
          ...dashboard,
          components: updatedComponent
        };
      } else {
        return dashboard;
      }
    });
    dispatch(handleAddCards(updatedDashboard))
    updatedComponent = []
    handleClose()
  }

  return (
    <Box>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        className="addCards-dialog"
      >
        <Grid container>
          <Grid
            container
            columns={12}
            display="flex"
            sx={{
              justifyContent: 'space-between',
              m: 2,
              pb: 2,
              borderBottom: '2px solid rgba(0,0,0,0.1)',
            }}
          >
            <Typography variant="h4">Cards</Typography>
            <CloseIcon
              sx={{ fontSize: '28px', cursor: 'pointer' }}
              onClick={handleClose}
            />
          </Grid>
          <Grid lg={2} sx={{ borderRight: '1px solid rgba(0,0,0,0.1)' }}>
            <Button
              onClick={() => handleFilter('')}
              style={{
                backgroundColor: activeButton === '' ? '#f0f0f0' : '#ffff',
                borderLeft: activeButton === '' ? '3px solid #f39449' : '#ffff',
                padding: '17px 15px',
                marginBottom: '4px',
              }}
            >
              All
            </Button>
            <Button
              onClick={() => handleFilter('Facebook')}
              style={{
                backgroundColor:
                  activeButton === 'Facebook' ? '#f0f0f0' : '#ffff',
                borderLeft:
                  activeButton === 'Facebook' ? '3px solid #f39449' : '#ffff',
                padding: '17px 15px',
                marginBottom: '4px',
              }}
            >
              <img
                alt="Remy Sharp"
                src="/static/media/facebook.01a470d813915c298403.png"
                style={{ width: '30px' }}
              ></img>
            </Button>
            <Button
              onClick={() => handleFilter('Instagram')}
              style={{
                backgroundColor:
                  activeButton === 'Instagram' ? '#f0f0f0' : '#ffff',
                borderLeft:
                  activeButton === 'Instagram' ? '3px solid #f39449' : '#ffff',
                padding: '17px 15px',
                marginBottom: '4px',
              }}
            >
              <img
                alt="Remy Sharp"
                src="/static/media/instagram.1311da613ef132f9f3bd.png"
                style={{ width: '30px' }}
              ></img>
            </Button>
          </Grid>
          <Grid
            lg={10}
            sx={{
              borderLeft: '1px solid rgba(0,0,0,0.1)',
            }}
          >
            <Grid lg={12}>
              <Search className="search">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
            </Grid>
            <Grid
              lg={12}
              sx={{
                display: 'block',
                height: '100%',
                width: '100%',
                mt: 2
                // overflowY: 'scroll',
              }}
            >
              {filteredCards.map((item) => {
                return (
                  <>
                    <Button
                      className='custom_list_button'
                      onClick={() => handleCardSelection(item.component)}
                    >
                      <Grid sx={{
                        height: '100%',
                        width: '100%',
                        // overflowY: 'scroll',
                      }} display='flex'
                        alignItems='center' justifyContent='space-between'>
                        <Grid display='flex'
                          alignItems='center' color='#000'>
                          <Typography variant='h6' mr={1}>
                            {item.name}
                          </Typography>
                          <Typography variant='p' color='#767676'>
                            {item.platform}
                          </Typography>
                        </Grid>

                        <Grid display='flex' alignItems='center' mr={2} className='customIcon'>
                          <ControlPointRoundedIcon sx={{ color: '#767676' }} />
                        </Grid>
                      </Grid>
                    </Button>
                  </>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}

export default AddCards;
