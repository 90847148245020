import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Doughnut } from 'react-chartjs-2';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import { useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Engagegement',
    heading3: '%',
  },
];

function StoryEngagementByType() {
  let { storiesEngagementTypeLoading, storiesEngagementTypeData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between">
          <Typography variant="h4" color="#000" fontWeight={600}>
            Story Engagements by Type
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {storiesEngagementTypeLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : storiesEngagementTypeData?.totalCount > 0 ? (
          <>
            <DoughnutChart
              doughnutData={storiesEngagementTypeData?.doughnutData}
              dataKey="value"
            />

            <TableComponent
              heading={heading}
              tabledata={storiesEngagementTypeData?.data}
            />
          </>
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default StoryEngagementByType;
