import React, { useCallback, useState } from 'react';
import { Box,  Grid } from '@mui/material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

function DoughnutChart({ doughnutData, dataKey }) {
console.log("doughnutData",doughnutData,dataKey)
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          style={{ fontFamily: 'sans-serif', fontSize: '12px' }}
        >
          {[`${payload.labels}  ${payload.value}`]}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          startAngle={startAngle}
          outerRadius={outerRadius}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const onPieLeave = () => setActiveIndex(0);
  const colors = ['#413ea0', '#7a40ed', '#bba2ec', '#dcd5eb'];
  doughnutData = doughnutData?.map((entry, index) => ({ ...entry, fill: colors[index - 1] }));


  return (
    <Box>
      <Grid height={300}  sx={{mb:2}}>
        <ResponsiveContainer  height={280} >
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={doughnutData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              dataKey={dataKey}
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieLeave}
            >
              {doughnutData?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Box>
  );
}

export default DoughnutChart;
