import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Posts',
    heading3: 'Post%',
  },
];
const tabledata = [
  {
    data1: 'Likes  ',
    data2: 16,
    data3: '87.5%',
  },
  {
    data1: 'Haha  ',
    data2: 2,
    data3: '6.25%',
  },
  {
    data1: 'Anger  ',
    data2: 0,
    data3: '0%',
  },
  {
    data1: 'Love  ',
    data2: 2,
    data3: '6.25%',
  },
  {
    data1: 'Sad  ',
    data2: 0,
    data3: '0%',
  },
  {
    data1: 'Wow  ',
    data2: 0,
    data3: '0%',
  },
];

const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Reactions', value: [sum] },
  { labels: 'Likes', value: 16 },
  { labels: 'Haha', value: 2 },
  { labels: 'Anger', value: 0 },
  { labels: 'Love', value: 2 },
  { labels: 'Sad', value: 0 },
  { labels: 'Wow', value: 0 },
];
function PostReactionByType() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };



  const { reactionsByTypeDataLoading,reactionsByTypeDataData } = useSelector((state) => state.facebook)
  const heading = [
    {
      heading1: 'Type',
      heading2: 'Posts',
      heading3: 'Post%',
    },
  ];
  const tabledata = [
    {
      data1: 'Likes  ',
      data2: 16,
      data3: '87.5%',
    },
    {
      data1: 'Haha  ',
      data2: 2,
      data3: '6.25%',
    },
    {
      data1: 'Anger  ',
      data2: 0,
      data3: '0%',
    },
    {
      data1: 'Love  ',
      data2: 2,
      data3: '6.25%',
    },
    {
      data1: 'Sad  ',
      data2: 0,
      data3: '0%',
    },
    {
      data1: 'Wow  ',
      data2: 0,
      data3: '0%',
    },
  ];

  const sum = tabledata.reduce((total, item) => total + item.data2, 0);
  var doughnutData = [
    { labels: 'Reactions', value: [sum] },
    { labels: 'Likes', value: reactionsByTypeDataData?.reactionsByType?.like },
    { labels: 'Haha', value: reactionsByTypeDataData?.reactionsByType?.haha },
    { labels: 'Anger', value: reactionsByTypeDataData?.reactionsByType?.anger },
    { labels: 'Love', value: reactionsByTypeDataData?.reactionsByType?.love },
    { labels: 'Sad', value: reactionsByTypeDataData?.reactionsByType?.sad },
    { labels: 'Wow', value: reactionsByTypeDataData?.reactionsByType?.wow },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
         {reactionsByTypeDataLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
       <>
       <Grid display="flex" justifyContent="space-between">
          <Typography variant="h4" color="#000" fontWeight={600}>
            Posts Reactions By Type
          </Typography>
          <MoreHorizOutlined
            fontSize="large"
            onClick={openMenu}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
        
        {doughnutData?.length > 0 ?
          <>
            <DoughnutChart doughnutData={doughnutData} dataKey="value" />

            <Grid height="100%" width="100%" display="flex" justifyContent="center">
              <TableComponent heading={heading} tabledata={doughnutData} />
            </Grid>
          </>
          : <NoDataComponent />}
       </>
       }

      </Box>
    </>
  );
}

export default PostReactionByType;
