import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

function OrganicandPaidPagevsReach() {
  const { paidOrganicReachLoading, paidOrganicReachData } = useSelector(
    (state) => state.facebook
  );
  const heading = [
    {
      heading1: 'Type',
      heading2: 'Impressions',
      heading3: 'Reach',
    },
  ];
  const tabledata = [
    {
      data1: 'Paid',
      data2: paidOrganicReachData[0]?.impressions,
      data3: paidOrganicReachData[0]?.reach,
    },
    {
      data1: 'Organic  ',
      data2: paidOrganicReachData[1]?.impressions,
      data3: paidOrganicReachData[1]?.reach,
    },
  ];

  const sum1 = tabledata.reduce((total, item) => total + item.data2, 0);
  const sum2 = tabledata.reduce((total, item) => total + item.data3, 0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  var doughnutData1 = [
    { labels: 'Impressions', value: [sum1] },
    { labels: 'Paid', value: paidOrganicReachData[0]?.impressions },
    { labels: 'Organic', value: paidOrganicReachData[1]?.impressions },
  ];
  let doughnutData2 = [
    { labels: 'Reach', value: [sum2] },
    { labels: 'Paid', value: paidOrganicReachData[0]?.reach },
    { labels: 'Organic', value: paidOrganicReachData[1]?.reach },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
        }}
      >
        {paidOrganicReachLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography variant="h4" color="#000" fontWeight={600}>
                Organic & Paid Page Impressions vs Reach
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PNG',
                'Export as PDF',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            {paidOrganicReachData?.length > 0 ? (
              <>
                <Grid
                  container
                  sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}
                >
                  <Grid lg={5}>
                    <DoughnutChart
                      doughnutData={doughnutData1}
                      dataKey="value"
                    />
                  </Grid>
                  <Grid lg={5}>
                    <DoughnutChart
                      doughnutData={doughnutData2}
                      dataKey="value"
                    />
                  </Grid>
                </Grid>

                <TableComponent heading={heading} tabledata={tabledata} />
              </>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default OrganicandPaidPagevsReach;
