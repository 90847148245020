import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAudienceSummary,
  getPerformanceSummary,
  getPostEngagementSummary,
  getImpressionsSummary,
  getReelsEngagementSummary,
  getStorySummary,
  getPageReachImpression,
  getProfileViews,
  getPageClicks,
  getCtaClickTypes,
  getReelsVsEngagement,
  getReelsPlaysVsReach,
  getTop5Reels,
  getReelsEngagementActions,
  getReelsEngagementType,
  getLikeGrowth,
  getLikesByCounty,
  getLikesByLanguage,
  getLikesByCity,
  getLikesByGender,
  getLikesByAge,
  getPostsEngagementImpression,
  getPostsByType,
  getTopPosts,
  getEngagementByType,
  getEngagementTypeActions,
  getStoriesVsEngagement,
  getStoriesEngagementType,
  getTopStories,
  getImpressionsVsReach,
  getFollowersVsReach,
  getExitsVsReach,
  getStoriesCompletionRate,
} from '../Api/instagramApi';

// summary
export const getAudienceSummaryData = createAsyncThunk(
  'summary/audience-summary',
  async (data) => await getAudienceSummary(data)
);
export const getPostEngagementSummaryData = createAsyncThunk(
  'summary/post-engagement-summary',
  async (data) => await getPostEngagementSummary(data)
);
export const getPerformanceSummaryData = createAsyncThunk(
  'summary/performance-summary',
  async (data) => await getPerformanceSummary(data)
);
export const getImpressionsSummaryData = createAsyncThunk(
  'summary/impressions-summary',
  async (data) => await getImpressionsSummary(data)
);
export const getReelsEngagementSummaryData = createAsyncThunk(
  'summary/reels-engagement-summary',
  async (data) => await getReelsEngagementSummary(data)
);
export const getStorySummaryData = createAsyncThunk(
  'summary/story-summary',
  async (data) => await getStorySummary(data)
);

// audience
export const getLikeGrowthData = createAsyncThunk(
  'audience/like-growth',
  async (data) => await getLikeGrowth(data)
);

export const getLikesByCountyData = createAsyncThunk(
  'audience/likes-by-county',
  async (data) => await getLikesByCounty(data)
);
export const getLikesByLanguageData = createAsyncThunk(
  'audience/likes-by-language',
  async (data) => await getLikesByLanguage(data)
);
export const getLikesByCityData = createAsyncThunk(
  'audience/likes-by-city',
  async (data) => await getLikesByCity(data)
);
export const getLikesByGenderData = createAsyncThunk(
  'audience/likes-by-gender',
  async (data) => await getLikesByGender(data)
);
export const getLikesByAgeData = createAsyncThunk(
  'audience/likes-by-age',
  async (data) => await getLikesByAge(data)
);

// Post & Engagement
export const getPostsByTypeData = createAsyncThunk(
  'Posts & Engagement/posts-by-type',
  async (data) => await getPostsByType(data)
);
export const getTopPostsData = createAsyncThunk(
  'Posts & Engagement/top-posts',
  async (data) => await getTopPosts(data)
);
export const getPostsEngagementImpressionData = createAsyncThunk(
  'Posts & Engagement/posts-engagement-impression',
  async (data) => await getPostsEngagementImpression(data)
);
export const getEngagementByTypeData = createAsyncThunk(
  'Posts & Engagement/engagement-by-type',
  async (data) => await getEngagementByType(data)
);
export const getEngagementTypeActionsData = createAsyncThunk(
  'Posts & Engagement/engagement-type-actions',
  async (data) => await getEngagementTypeActions(data)
);

// page-reach-impression
export const getPageReachImpressionData = createAsyncThunk(
  'Reach & impression/page-reach-impression',
  async (data) => await getPageReachImpression(data)
);

// Performance
export const getProfileViewsData = createAsyncThunk(
  'Performance/profile-views',
  async (data) => await getProfileViews(data)
);
export const getPageClicksData = createAsyncThunk(
  'Performance/page-clicks',
  async (data) => await getPageClicks(data)
);
export const getCtaClickTypesData = createAsyncThunk(
  'Performance/cta-click-types',
  async (data) => await getCtaClickTypes(data)
);

// REELS AND MANAGEMENT
export const getReelsVsEngagementData = createAsyncThunk(
  'REELS AND MANAGEMENT/reels-vs-engagement',
  async (data) => await getReelsVsEngagement(data)
);
export const getReelsPlaysVsReachData = createAsyncThunk(
  'REELS AND MANAGEMENT/reels-plays-reach',
  async (data) => await getReelsPlaysVsReach(data)
);
export const getTop5ReelsData = createAsyncThunk(
  'REELS AND MANAGEMENT/top-5-reels',
  async (data) => await getTop5Reels(data)
);
export const getReelsEngagementActionsData = createAsyncThunk(
  'REELS AND MANAGEMENT/reels-engagement-actions',
  async (data) => await getReelsEngagementActions(data)
);
export const getReelsEngagementTypeData = createAsyncThunk(
  'REELS AND MANAGEMENT/reels-engagement-type',
  async (data) => await getReelsEngagementType(data)
);

//Stories
export const getStoriesVsEngagementData = createAsyncThunk(
  'Stories/stories-vs-engagement',
  async (data) => await getStoriesVsEngagement(data)
);
export const getStoriesEngagementTypeData = createAsyncThunk(
  'Stories/stories-engagement-type',
  async (data) => await getStoriesEngagementType(data)
);
export const getTopStoriesData = createAsyncThunk(
  'Stories/top-stories',
  async (data) => await getTopStories(data)
);
export const getImpressionsVsReachData = createAsyncThunk(
  'Stories/impressions-vs-reach',
  async (data) => await getImpressionsVsReach(data)
);
export const getFollowersVsReachData = createAsyncThunk(
  'Stories/followers-vs-reach',
  async (data) => await getFollowersVsReach(data)
);
export const getExitsVsReachData = createAsyncThunk(
  'Stories/exits-vs-reach',
  async (data) => await getExitsVsReach(data)
);
export const getStoriesCompletionRateData = createAsyncThunk(
  'Stories/stories-completion-rate',
  async (data) => await getStoriesCompletionRate(data)
);
