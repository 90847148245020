import { Typography, Box, Grid, Divider } from '@mui/material';
import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TopPostCards from '../../TopPostCards/TopPostCards';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from "react-redux";
import Loader from '../../Loader';



function TopPostsBy() {
    const { topPostsLoading, topPostsData } = useSelector((state) => state.facebook)
    const top5Posts = topPostsData && topPostsData.length > 0 && topPostsData?.slice(0, 5);
    const data = [
        {
            Title: 'directly via facebook',
            PostedtContentIcon: <PhotoSizeSelectActualIcon />,
            DateAndTime: '27 march 2023 12:00 AM',
            PostedContent: 'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336363969_871328360600246_5636292073696989109_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=wrDwVcqiu6IAX91v3IQ&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfANZZiRD1s1IzRrXGitx9y23JMvxleEfnb8LVs9uSmcMg&oe=643DD81B',
            Description: ` Discover the rich cultural heritage of Yakshagaana, a traditional form of theater from Karnataka,
                           with our vibrant poster! The intricate costumes and dynamic performances are sure to captivate you.`,
      Reactions: 20,
      Comments: 10,
      Shares: 1,
      Engagement: 30,
      EngagementRate: '60%',
    },
    {
      Title: 'directly via facebook',
      PostedtContentIcon: <PhotoSizeSelectActualIcon />,
      DateAndTime: '29 march 2023 2:00 PM',
      PostedContent:
        'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336063631_5685703464874664_2356209906088714133_n.jpg?stp=dst-jpg_p720x720&_nc_cat=107&ccb=1-7&_nc_sid=730e14&_nc_ohc=ej5WkD_mHWcAX-QQ0hp&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfBY6suXyRVTXPtrATbeeHbIoqY6A03mFMY8j__GJcfBUg&oe=64414BAD',
      Description: ` Explore the beauty of Kolkata with our vibrant poster! 🌇💜 Get lost in the city's colorful culture and architecture. 
                           Order now and bring home a piece of this magnificent city! `,
      Reactions: 45,
      Comments: 22,
      Shares: 2,
      Engagement: 60,
      EngagementRate: '65%',
    },
    {
      Title: 'directly via facebook',
      PostedtContentIcon: <PhotoSizeSelectActualIcon />,
      DateAndTime: '27 march 2023 12:00 AM',
      PostedContent:
        'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/338174249_219957520635652_7543591465357023898_n.jpg?stp=dst-jpg_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_ohc=NZ6lfVUYAZsAX_V1trm&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfDpFtM5j_-iQdqTCd6azLGKNbCxDfzheZmifW_CnlILiA&oe=6440D0AA',
      Description: `Wishing you a blessed Ram Navami! Let's celebrate the birth of Lord Rama and embrace his virtues of truth, love, and righteousness.
                          Check out our collection of posters and bring positivity to your home. Shop now at Happy Doki!`,
      Reactions: 100,
      Comments: 99,
      Shares: 55,
      Engagement: 200,
      EngagementRate: '95%',
    },
    {
      Title: 'directly via facebook',
      PostedtContentIcon: <PhotoSizeSelectActualIcon />,
      DateAndTime: '27 march 2023 12:00 AM',
      PostedContent:
        'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336363969_871328360600246_5636292073696989109_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=wrDwVcqiu6IAX91v3IQ&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfANZZiRD1s1IzRrXGitx9y23JMvxleEfnb8LVs9uSmcMg&oe=643DD81B',
      Description: ` Discover the rich cultural heritage of Yakshagaana, a traditional form of theater from Karnataka,
                            with our vibrant poster! The intricate costumes and dynamic performances are sure to captivate you.`,
      Reactions: 20,
      Comments: 10,
      Shares: 1,
      Engagement: 30,
      EngagementRate: '60%',
    },
    {
      Title: 'directly via facebook',
      PostedtContentIcon: <PhotoSizeSelectActualIcon />,
      DateAndTime: '27 march 2023 12:00 AM',
      PostedContent:
        'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/339777887_256550240043020_5668072463112124590_n.jpg?stp=dst-jpg_p720x720&_nc_cat=104&ccb=1-7&_nc_sid=730e14&_nc_ohc=4VG-vkEHChwAX_3092i&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfCxUtYbjE8joK8MB8EidDbWLRZjG-rx9ddsz-0ySSZrIA&oe=6440A54E',
      Description: ` Wishing you and your loved ones a joyful Easter filled with love, peace, and happiness. 
                            Happy Doki hopes this day brings new beginnings and fresh starts for you .`,
      Reactions: 25,
      Comments: 12,
      Shares: 1,
      Engagement: 35,
      EngagementRate: '62%',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  
          
    return (<>
        <Box sx={{ width: '100%' }}>
            <Grid display='flex' justifyContent='space-between' mb={4}>
                <Typography variant='h4' color='#000' fontWeight={600}>
                    Top Posts By
                </Typography>
                <MoreHorizOutlined fontSize='large'  onClick={openMenu}
            sx={{ cursor: 'pointer' }}/>
            </Grid>
            <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />
            {topPostsLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
                <>
                    <Grid container display='flex' >

                        <TopPostCards data={top5Posts} />
                    </Grid>
                </>
            }
        </Box>
    </>
  );
}

export default TopPostsBy;
