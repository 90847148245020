import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import { useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

function StoriesVsImpressions() {
  let { storiesEngagementLoading, storiesEngagementData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Number of Stories Vs Impressions
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {/* <Grid width='100%' height='100%'>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart
                            data={data}
                            margin={{ left: 20, right: 20, top: 20 }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="name" scale="auto" fontSize="12">
                            </XAxis >
                            <YAxis fontSize="14" label={{
                                value: 'Number Of Stories', angle: -90, position: 'insideLeft',
                                fontSize: '16px', style: { textAnchor: 'middle' }
                            }} domain={[0, 'dataMax']} />

                            <Tooltip itemStyle={{ fontSize: '16px' }} wrapperStyle={{ fontSize: '16px' }} />
                            <Legend iconSize={14} wrapperStyle={{ fontSize: 18 }} iconType='circle' />
                            <Bar dataKey="Stories" barSize={30} fill="#413ea0" />
                            <Area type="monotone" dataKey="Impressions" stroke="#ff7300" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Grid> */}
        {storiesEngagementLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : storiesEngagementData?.data?.length > 0 ? (
          <BarAndAreaChart
            data={storiesEngagementData?.data}
            XAxis_dataKey="date"
            barDataKey="Stories"
            area1DataKey="Impressions"
            YAxis_Label="Number Of Stories"
          />
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default StoriesVsImpressions;
