import React from 'react';
import { Grid } from '@mui/material';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
} from 'recharts';

function SingleBarChart({
    data,
    XAxis_dataKey,
    barDataKey,
    YAxis_Label,
    
}) {
    return (
        <>
            <Grid width='100%' height='100%' >
                <ResponsiveContainer height={500} width={"100%"}>
                    <BarChart
                        height={500} width={"100%"}
                        data={data}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                        }}
                    >
                       <XAxis dataKey={XAxis_dataKey} scale="auto" fontSize="12">
                        </XAxis >
                        <YAxis
                            fontSize="14"
                            label={{
                                value: YAxis_Label,
                                position: 'left',
                                fontSize: '16px',
                                angle: -90,
                                style: { textAnchor: 'middle' },
                            }}
                            domain={[0, dataMax => (dataMax * 1.5)]}
                        />
                        <Tooltip
                            itemStyle={{ fontSize: '16px' }}
                            wrapperStyle={{ fontSize: '16px' }}
                        />
                        <Legend
                            iconSize={14}
                            wrapperStyle={{ fontSize: 18 }}
                            iconType="circle"
                        />

                        {barDataKey ? <Bar type="monotone" barSize={40} dataKey={barDataKey} fill="#413ea0" /> : null}
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </>
    )
}

export default SingleBarChart