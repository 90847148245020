import React, { useState,useEffect } from 'react';
import { Grid } from '@mui/material';
import SideBar from '../components/SideBar';
import LeftBar from '../components/SideBar/Sidebar';
import Conversations from '../pages/conversations/Conversations';
import {
  handleCurrentPlanLoader,
  handleCurrentPlanData,
  handleAccountSettingsData,
} from '../redux/Slice/user';
import {
  getAllBrandData,
  getAllSocialAccountData,
  getSocialAccountsSettingsData,
  getBrandSettingData
} from '../redux/reduxThunk';

import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import * as api from '../redux/Api';
import FacebookAnalytic from './facebookAnalytics/FacebookAnalyticTabs';
import { setCookie, getCookie, removeCookie } from '../helpers/cookie';

function Navigation({ title }) {
  const { selectedSocialAccountId, userData } = useSelector((state) => state.user);
  const selected_brand_id = getCookie('created_brand_id');
  // local states
  const [error, setError] = useState('');
  const [is_update, setIs_update] = useState(false);
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);
  const dispatch = useDispatch();
  const fetchAccountSettingsData = async () => {
    await api.accountSettingsApi().then((res) => {
      dispatch(handleAccountSettingsData(res?.data));
  });
}
  const fetchCurrentPlanDetailsData = async () => {
    dispatch(handleCurrentPlanLoader(true));
    try {
      await api.getCurrentPlanDetailsApi().then((res) => {
        dispatch(handleCurrentPlanData(res?.data));
        dispatch(handleCurrentPlanLoader(false));
      });
    } catch (err) {
      dispatch(handleCurrentPlanLoader(false));
      if (err.response) {
        setError(err.response.data);
      } else {
        setError({ message: 'Network Error' });
      }
    }
  };

  const fetchBrandSettingsData = () => {
    const socialAccountSettingsData = {
      social_id: selectedSocialAccountId,
      brand_id: selected_brand_id,
    };
    dispatch(getAllBrandData(userData?.accountType));
    dispatch(getBrandSettingData(selected_brand_id));
    dispatch(getAllSocialAccountData(selected_brand_id));
    dispatch(getSocialAccountsSettingsData(socialAccountSettingsData));
  };

  React.useMemo(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchCurrentPlanDetailsData();
    fetchBrandSettingsData();
    fetchAccountSettingsData();
  }, []);
  const { audienceSummary } = useSelector((state) => state.facebook);
  let socialAccountTitle = '';
  if (audienceSummary.name === 'facebookPage') {
    socialAccountTitle = 'Facebook Reports';
  } else if (audienceSummary.name === 'instagram') {
    socialAccountTitle = 'Instagram Reports';
  }else if (audienceSummary.name === 'CustomReports') {
    socialAccountTitle = 'Custom Reports';
  }
  return (
    <>
      <SideBar title={title} />
      <Grid
        container-fluid
        columns={12}
        spacing={2}
        display="flex"
      className="account-setting-tab"
      >
        <Grid item md={2} lg={2} xl={2} >
          <LeftBar />
        </Grid>
        <Grid item md={10} lg={10} xl={10}>
          {title === socialAccountTitle ? (
            <FacebookAnalytic />
          ) : null}
          {title === 'Conversations' ? (
            <Conversations />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default Navigation;
