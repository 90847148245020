import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Doughnut } from 'react-chartjs-2';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import { useSelector } from 'react-redux';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Lost',
    heading3: 'Lost%',
  },
];
const tabledata = [
  {
    data1: 'Followers  ',
    data2: 79,
    data3: 0,
  },
  {
    data1: 'Friends  ',
    data2: 79,
    data3: 0,
  },
];

const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Total', value: [sum] },
  { labels: 'Followers', value: 79 },
  { labels: 'Friends', value: 79 },
];

function ConnectionLost() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {messengerConnectionLostLoading,messengerConnectionLostData} = useSelector((state)=>state.facebook)

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Connections Lost
          </Typography>
          <MoreHorizOutlined fontSize="large"  onClick={openMenu}
            sx={{ cursor: 'pointer' }}/>
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PNG',
            'Export as PDF',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
        <DoughnutChart doughnutData={doughnutData} dataKey="value" />

        <Grid height="100%" width="100%" display="flex" justifyContent="center">
          <TableComponent heading={heading} tabledata={tabledata} />
        </Grid>
      </Box>
    </>
  );
}

export default ConnectionLost;
