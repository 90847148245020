import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';



function PostsByPublishingApps() {
  const [anchorEl, setAnchorEl] = React.useState(null);
    // const {postsByAppsData}= useSelector((state)=>state.facebook)
    const heading = [
        {
            heading1: 'Apps',
            heading2: 'Posts',
            heading3: 'Split%',
            heading4: 'Reactions',
            heading5: 'Comments',
            heading6: 'Shares',
        },
    ];
    const tabledata = [
        {
            data1: 'Facebook  ',
            data2: '16',
            data3: '94.2%',
            data4: '16  ',
            data5: '0',
            data6: '27',
        },
        {
            data1: 'GreetKaro  ',
            data2: '1',
            data3: '5.8%',
            data4: '0  ',
            data5: '0',
            data6: '0',
        },
      ]
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Posts By Publishing Apps
          </Typography>
          <MoreHorizOutlined
            fontSize="large"
            onClick={openMenu}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />
        <Table size="small" aria-label="a dense table">
          <TableHead>
            {heading?.map((item, index) => {
              return (
                <TableRow key={index} sx={{ height: 50 }}>
                  <TableCell align="center">
                    <Typography variant="h6" fontWeight={500}>
                      {item.heading1}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" fontWeight={500}>
                      {item.heading2}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" fontWeight={500}>
                      {item.heading3}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" fontWeight={500}>
                      {item.heading4}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" fontWeight={500}>
                      {item.heading5}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6" fontWeight={500}>
                      {item.heading6}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableHead>

          <TableBody>
            {tabledata?.map((item, id) => {
              return (
                <TableRow key={id} sx={{ borderBottom: 'none', height: 50 }}>
                  <TableCell align="center" component="th" scope="row">
                    <Typography variant="h6">{item.data1}</Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="h6">{item.data2}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">{item.data3}</Typography>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <Typography variant="h6">{item.data4}</Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Typography variant="h6">{item.data5}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h6">{item.data6}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

export default PostsByPublishingApps;
