import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import Loader from '../../../components/Loader';
import NoDataComponent from '../../Common/NoDataComponent';
import { useSelector, useDispatch } from 'react-redux';

const data = [
  {
    name: "16 MAR' 23",
    Plays: 0,
    Reach: 800,
  },
  {
    name: "17 MAR' 23",
    Plays: 800,
    Reach: 967,
  },
  {
    name: "18 MAR' 23",
    Plays: 1397,
    Reach: 1098,
  },
  {
    name: "19 MAR' 23",
    Plays: 1480,
    Reach: 1200,
  },
  {
    name: "20 MAR' 23",
    Plays: 1520,
    Reach: 1108,
  },
  {
    name: "21 MAR' 23",
    Plays: 0,
    Reach: 680,
  },
  {
    name: "22 MAR' 23",
    Plays: 0,
    Reach: 680,
  },
];

function ReelsPlayVsReach() {
  const { reelsPlaysVsReachLoading, reelsPlaysVsReachData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {reelsPlaysVsReachLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Reels plays Vs Reach
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            {/* <Grid width='100%' height='100%'>
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                        data={data}
                        margin={{ left: 20, right: 20, top: 20 }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="auto" fontSize="12">
                        </XAxis >
                        <YAxis fontSize="14" label={{
                            value: 'Count', angle: -90, position: 'insideLeft',
                            fontSize: '16px', style: { textAnchor: 'middle' }
                        }} domain={[0, 'dataMax']} />

                        <Tooltip itemStyle={{ fontSize: '16px' }} wrapperStyle={{ fontSize: '16px' }} />
                        <Legend iconSize={14} wrapperStyle={{ fontSize: 18 }} iconType='circle' />
                        <Bar dataKey="Reach" barSize={25} fill="#413ea0" />
                        <Area type="monotone" dataKey="Plays" stroke="#ff7300" />
                    </ComposedChart>
                </ResponsiveContainer>
            </Grid> */}
            {reelsPlaysVsReachData?.length > 0 ? (
              <BarAndAreaChart
                data={reelsPlaysVsReachData}
                XAxis_dataKey="name"
                barDataKey="Reach"
                area1DataKey="Plays"
                YAxis_Label="Count"
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default ReelsPlayVsReach;
