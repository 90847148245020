import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Count',
    heading3: '%',
  },
];
const tabledata = [
  {
    data1: 'Like',
    data2: 45,
    data3: '47.37%',
  },
  {
    data1: 'Link',
    data2: 33,
    data3: '34.74%',
  },
  {
    data1: 'Other',
    data2: 17,
    data3: '17.89%',
  },
];

const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Positive Feedback', value: [sum] },
  { labels: 'Like', value: 45 },
  { labels: 'Link', value: 33 },
  { labels: 'Other', value: 17 },
];

function PositiveFeedback() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { postsPositiveFeedbackLoading, postsPositiveFeedbackData } =
    useSelector((state) => state.facebook);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
        }}
      >
        {postsPositiveFeedbackLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography variant="h4" color="#000" fontWeight={600}>
                Positive Feedback
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
              <MenuComponent
                anchorEl={anchorEl}
                closeMenu={closeMenu}
                displayItems={[
                  'Export as PNG',
                  'Export as PDF',
                  'Export as CSV',
                  'Send as Email',
                  'Schedule',
                ]}
              />
            </Grid>
            {postsPositiveFeedbackData?.data?.length > 0 ? (
              <>
                <DoughnutChart
                  doughnutData={postsPositiveFeedbackData?.doughnutData}
                  dataKey="value"
                />
                <TableComponent
                  heading={heading}
                  tabledata={postsPositiveFeedbackData?.data}
                />
              </>
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default PositiveFeedback;
