import { makeStyles } from '@mui/styles';

export const sidebarStyles = makeStyles({
    sidebar_header: {
        padding: "8px 24px 8px 24px",
        display: 'flex',
        justifyContent: 'space-between'
    },
    sidebar_main: {
        width: "250px",
        borderTop: '3px solid #F8F8F8 !important',
        backgroundColor: 'white',
        height: '820px'
    },
    sidebar_grid: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },
    sidebar_logo: {
        width: "180px",
        height: "45px",
        marginRight: "42px"
    },
    avatar: {
        width: "32px !important",
        height: "32px !important",
        fontSize: '16px !important',
        fontWeight: '600 !important'
    },
    brand_title: {
        fontSize: "14px",
        fontWeight: '500 !important'
    },
    icon: {
        height: 20,
        width: 20,
        marginRight: "8px",
        marginTop: "8px"
    },
    drop_down_icon: {
        marginLeft: "60px !important",
        fontSize: "28px !important",
        cursor: 'pointer',
    },
    sidebar_heading: {
        fontFamily: 'Rubik,sans-serif',
        lineHeight: '1.6',
        color: '#6c6868',
        fontSize: '16px',
        fontWeight: '500'
    }
});


