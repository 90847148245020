import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

function EngagementByPostType() {
  const {engagementByTypeLoading, engagementByTypeData } = useSelector((state) => state.facebook)
  console.log("engagementByTypeData",engagementByTypeData)
  const heading = [
    {
      heading1: 'Type',
      heading2: 'Posts',
      heading3: 'Post%',
    },
  ];
  const tabledata = [
    {
      data1: 'Image  ',
      data2: engagementByTypeData?.engagementByType?.photo?.postCount,
      data3: '87.5%',
    },
    {
      data1: 'Video  ',
      data2: engagementByTypeData?.engagementByType?.video?.postCount,
      data3: '6.25%',
    },
    {
      data1: 'Text  ',
      data2: engagementByTypeData?.engagementByType?.text?.postCount,
      data3: '6.25%',
    },
    {
      data1: 'Link  ',
      data2: engagementByTypeData?.engagementByType?.link?.postCount,
      data3: '0%',
    },
  ];

const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Engagement', value: [sum] },
  { labels: 'Image', value: 16 },
  { labels: 'Video', value: 2 },
  { labels: 'Text', value: 2 },
  { labels: 'Link', value: 0 },
];

  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  

  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '8px 15px',
        }}
      >
         {engagementByTypeLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
      <>
        <Grid display="flex" justifyContent="space-between">
          <Typography variant="h4" color="#000" fontWeight={600}>
            Engagegement By Posts Type
          </Typography>
          <MoreHorizOutlined
            fontSize="large"
            onClick={openMenu}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
       
        {doughnutData?.length > 0 ?
          <>
          {console.log("doughnutData",doughnutData)}
            <DoughnutChart doughnutData={doughnutData} dataKey="value" />
            <Grid height="100%" width="100%" display="flex" justifyContent="center">
              <TableComponent heading={heading} tabledata={tabledata} />
            </Grid>
          </>
          : <NoDataComponent />}
      </>

        }

      </Box>
    </>
  );
}

export default EngagementByPostType;
