import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ComposedChart,
  LabelList,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  ResponsiveContainer,
} from 'recharts';

const renderCustomizedLabel = (props) => {
  const { x, y, width, value } = props;
  const radius = 10;

  return (
    <g>
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#413ea0"
        textAnchor="end"
        dominantBaseline="end"
        fontSize={16}
      >
        {value}
      </text>
    </g>
  );
};

function HorizontalBarChart({ horizontalBarData, yAxisLabel, dataKey1 }) {
  return (
    <Grid container columns={12}>
      <ResponsiveContainer height={400} width="100%">
        <ComposedChart
          layout="vertical"
          data={horizontalBarData}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis type="number" hide />
          <YAxis dataKey={yAxisLabel} type="category" fontSize={16} />
          <Tooltip
            itemStyle={{ fontSize: '16px' }}
            wrapperStyle={{ fontSize: '16px' }}
          />

          {dataKey1 ? <Bar dataKey={dataKey1} barSize={20} fill="#413ea0"> 
            <LabelList
              dataKey="views"
              position="insideTopRight"
              content={renderCustomizedLabel}
            />
          </Bar>: null}
        </ComposedChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default HorizontalBarChart;
