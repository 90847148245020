import React from 'react'
import "./style.css";
import AppBar from '@mui/material/AppBar';
import { Typography, Grid } from '@mui/material';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
function Header({ title }) {
    return (
        <div>
            <AppBar
                position="static"
                className="header-bar">
                <Typography variant="h5" className='header-title' >
                    {title}
                </Typography>
            </AppBar>
        </div>
    )
}

export default Header