import React, { useState, useEffect, useMemo } from 'react';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import { getFacebookPagePostsData } from '../../redux/reduxThunk';
import NestedAvatar from '../../components/NestedAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { handleSelectedAccountInfo } from '../../redux/Slice/user';

function SelectSocialDropdown({ filterSocialMediaData }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  // default on load showing first value and api call
  useEffect(() => {
    let firstValue =
      filterSocialMediaData && filterSocialMediaData?.length > 0
        ? filterSocialMediaData[0]
        : '';

    let data = {
      socialId: firstValue?.data?._id,
      socialAccountName: firstValue?.data?.socialAccountName,
    };
    // API call
    dispatch(getFacebookPagePostsData(data));
    //Storing into redux state for comment reply
    dispatch(handleSelectedAccountInfo(data));
    // local state to display dropdown values

    setValue({ ...firstValue?.data, icon: firstValue.icon });
  }, []);

  // Dropdown on change function
  const handleChange = (event) => {
    let selectedAccount = event.target.value?.data;
    let data = {
      socialId: selectedAccount?._id,
      socialAccountName: selectedAccount?.socialAccountName,
    };
    // API call
    dispatch(getFacebookPagePostsData(data));
    //Storing into redux state for comment reply
    dispatch(handleSelectedAccountInfo(data));
    setValue({ ...selectedAccount, icon: event.target.value.icon });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      className="dropdown_div mr-20"
      // style={{margin: '6px', height:'60px',width: "100%"}}
      renderValue={(selectedValue) => (

        <Grid display='flex' alignItems='center'  >
          <NestedAvatar
            className="selected-icons"
            src1={selectedValue?.profileImage}
            src2={selectedValue?.icon}

          />
          <Typography variant='h5'>
            {selectedValue?.pageName}
          </Typography>

        </Grid>
      )}
    >
      {filterSocialMediaData?.map((option) => (
        <MenuItem key={option.name} value={option} >
          <NestedAvatar
            className="connected-icons"
            src1={option?.data?.profileImage}
            src2={option.icon}
          />
          <Typography variant='h5'>
            {option?.data?.pageName}
          </Typography>

        </MenuItem>
      ))}
    </Select>
  );
}

export default SelectSocialDropdown;
