import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import VertiacalDualSmallSizedBar from '../../Common/ChartComponents/VertiacalDualSmallSizedBar/VertiacalDualSmallSizedBar';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const vertiacalDualSmallBarData = [
  {
    name: '16 Mar 23',
    Total: 3,
    Unique: 2,
  },
  {
    name: '19 Mar 23',
    Total: 2,
    Unique: 2,
  },
  {
    name: '22 Mar 23',
    Total: 3,
    Unique: 2,
  },
  {
    name: '25 Mar 23',
    Total: 5,
    Unique: 2,
  },
  {
    name: '28 Mar 23',
    Total: 5,
    Unique: 1,
  },
  {
    name: '31 Mar 23',
    Total: 5,
    Unique: 1,
  },
  {
    name: '3 Apr 23',
    Total: 9,
    Unique: 4,
  },
  {
    name: '6 Apr 23',
    Total: 2,
    Unique: 1,
  },
  {
    name: '9 Apr 23',
    Total: 1,
    Unique: 1,
  },
  {
    name: '12 Apr 23',
    Total: 5,
    Unique: 2,
  },
];

function PageShares() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { pageSharesLoading, pageSharesData } = useSelector(
    (state) => state.facebook
  );
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {pageSharesLoading ? (
        <Loader
          color="inherit"
          value={100}
          size={25}
          onClick={openMenu}
          sx={{ cursor: 'pointer' }}
        />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Page Shares
            </Typography>
            <MoreHorizOutlined fontSize="large" />
          </Grid>
          <MenuComponent
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            displayItems={[
              'Export as PNG',
              'Export as PDF',
              'Export as CSV',
              'Send as Email',
              'Schedule',
            ]}
          />
          {pageSharesData?.data?.length > 0 ? (
            <VertiacalDualSmallSizedBar
              dataKey1="Total"
              dataKey2="Unique"
              vertiacalDualSmallBarData={pageSharesData?.data}
              YAxis_Label="Number of Stories"
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
    </Box>
  );
}

export default PageShares;
