import React, { useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import PageProfileView from '../../../components/Performance Components/PageProfileViews/PageProfileViews';
import PageTabViews from '../../../components/Performance Components/PageTabViews/PageTabViews';
import PageViewIntRefferrers from '../../../components/Performance Components/PageViewsIntRefferers/PageViewIntRefferrers';
import PageViewExtRefferrers from '../../../components/Performance Components/PageViewsExtRefferers/PageViewExtRefferrers';
import PageViewsGender from '../../../components/Performance Components/PageViewsGender/PageViewsGender';
import PageViewsAge from '../../../components/Performance Components/PageViewsAge/PageViewsAge';
import PageClicks from '../../../components/Performance Components/PageClicks/PageClicks';
import PageClicksPostType from '../../../components/Performance Components/PageClicksPostType/PageClicksPostType';
import PositiveFeedback from '../../../components/Performance Components/PositiveFeedback/PositiveFeedback';
import NegativeFeedback from '../../../components/Performance Components/NegativeFeedback/NegativeFeedback';
import PageCtaClicks from '../../../components/Performance Components/PageCtaClicks/PageCtaclicks';
import PageClicksType from '../../../components/Performance Components/PageClicksType/PageClicksType';
import PageViewsByGender from '../../../components/Performance Components/PageViewsGender/PageViewsGender';
import {
  getPageProfileViewsData,
  getPageTabViewsData,
  getPageViewsByInternalData,
  getPageViewsByExternalData,
  getPageViewsByGenderData,
  getPageViewsByAgeGroupData,
  getPageClicksData,
  getPageClicksByPostData,
  getPostsPositiveFeedbackData,
  getPostsNegativeFeedbackData,
  getCtaClickTypesData,
} from '../../../redux/reduxThunk/facebook';
import { useDispatch, useSelector } from 'react-redux';

function Performance() {
  const dispatch = useDispatch();
  const { audienceSummary } = useSelector((state) => state.facebook);

  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchFacebookPerformanceTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-02',
        toDate: '2023-04-13',
      };
      dispatch(getPageProfileViewsData(summaryData));
      dispatch(getPageTabViewsData(summaryData));
      dispatch(getPageViewsByInternalData(summaryData));
      dispatch(getPageViewsByExternalData(summaryData));
      dispatch(getPageViewsByGenderData(summaryData));
      dispatch(getPageViewsByAgeGroupData(summaryData));
      dispatch(getPageClicksData(summaryData));
      dispatch(getPageClicksByPostData(summaryData));
      dispatch(getPostsPositiveFeedbackData(summaryData));
      dispatch(getPostsNegativeFeedbackData(summaryData));
      dispatch(getCtaClickTypesData(summaryData));
    };
    fetchFacebookPerformanceTabData();
  }, [audienceSummary]);

  return (
    <Grid sx={{ width: '100%' }} container>
      <Grid container>
        <Grid lg={6} sx={{ width: '100%' }}>
          <PageProfileView />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
          <PageTabViews />
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid lg={6} sx={{ width: '100%' }}>
          <PageViewIntRefferrers />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
          <PageViewExtRefferrers />
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid lg={6} sx={{ width: '100%' }}>
          <PageViewsByGender />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
          <PageViewsAge />
        </Grid>
      </Grid>
      <Grid container>
        <Grid lg={6} sx={{ width: '100%' }}>
          <PageClicks />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
          <PageClicksPostType />
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: '10px' }}>
        <Grid lg={6} sx={{ width: '100%' }}>
          <PositiveFeedback />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
          <NegativeFeedback />
        </Grid>
      </Grid>

      <Grid container sx={{ marginTop: '10px',marginBottom:"10px"}}>
        <Grid lg={6} sx={{ width: '100%' }}>
          <PageCtaClicks />
        </Grid>
        <Grid lg={5.5} sx={{ width: '100%', ml: 2 }}>
          <PageClicksType />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Performance;
