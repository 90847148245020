import React, { useCallback, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import TableComponent from '../../TableComponent/TableComponent';
import DoughnutChart from '../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Gender',
    heading2: 'Likes',
    heading3: '%',
  },
];
const tabledata = [
  {
    data1: 'Male  ',
    data2: 267,
    data3: '58.17%',
  },
  {
    data1: 'Female  ',
    data2: 96,
    data3: '20.92%',
  },
  {
    data1: 'others  ',
    data2: 96,
    data3: '20.92%',
  },
];
const sum = tabledata.reduce((total, item) => total + item.data2, 0);
var doughnutData = [
  { labels: 'Total', value: [sum] },
  { labels: 'Male', value: 267 },
  { labels: 'Female', value: 96 },
  { labels: 'Others', value: 96 },
];

function PageViewsByGender() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { pageViewsByGenderLoading, pageViewsByGenderData } = useSelector(
    (state) => state.facebook
  );

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {pageViewsByGenderLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between">
              <Typography variant="h4" color="#000" fontWeight={600}>
                Page Views by Gender
              </Typography>
              <MoreHorizOutlined
                fontSize="large"
                onClick={openMenu}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            <DoughnutChart doughnutData={doughnutData} dataKey="value" />
            
              <TableComponent heading={heading} tabledata={tabledata} />
            
          </>
        )}
      </Box>
    </>
  );
}

export default PageViewsByGender;
