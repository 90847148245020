import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import BarAndLineChart from '../../Common/ChartComponents/BarAndLineChart';
import Loader from '../../Loader';

const data = [
  {
    name: "16 MAR' 23",
    Net_Likes: 0,
    New_Likes: 800,
  },
  {
    name: "17 MAR' 23",
    Net_Likes: 800,
    New_Likes: 967,
  },
  {
    name: "18 MAR' 23",
    Net_Likes: 1397,
    New_Likes: 1098,
  },
  {
    name: "19 MAR' 23",
    Net_Likes: 1480,
    New_Likes: 1200,
  },
  {
    name: "20 MAR' 23",
    Net_Likes: 1520,
    New_Likes: 1108,
  },
  {
    name: "21 MAR' 23",
    Net_Likes: 0,
    New_Likes: 680,
  },
  {
    name: "22 MAR' 23",
    Net_Likes: 0,
    New_Likes: 680,
  },
];

function PageLikeGrowth() {
  const { likeGrowthLoading, likeGrowthData } = useSelector(
    (state) => state.facebook
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      
        <Box
         sx={{
            width: '100%',
            height: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          {likeGrowthLoading ? (
            <Loader color="inherit" value={100} size={25} />
          ) : (
            <>
              <Grid
                display="flex"
                mb={4}
                sx={{ ml: 'auto', justifyContent: 'space-between' }}
              >
                <Typography variant="h4" color="#000" fontWeight={600}>
                  Page Like Growth
                </Typography>
                <MoreHorizOutlined fontSize="large" onClick={openMenu} />
              </Grid>
              <MenuComponent
                anchorEl={anchorEl}
                closeMenu={closeMenu}
                displayItems={[
                  'Discuss',
                  'Export as PDF',
                  'Export as PNG',
                  'Export as CSV',
                  'Send as Email',
                  'Schedule',
                ]}
              />

              {likeGrowthData ? (
                <BarAndLineChart
                  data={likeGrowthData?.data}
                  XAxis_dataKey="day"
                  barDatakey="newLikes"
                  line1Datakey="likesLost"
                  YAxis_Label="New Likes"
                />
              ) : (
                <NoDataComponent />
              )}
            </>
          )}
        </Box>
      
    </>
  );
}

export default PageLikeGrowth;
