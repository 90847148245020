import React from 'react'
import { Card, CardContent, CardMedia, Typography, Box, Grid, Divider } from '@mui/material'
import moment from "moment";
import './TopPostCards.css'

function TopPostCards({ data }) {

    // const width=(100%/5)-6%
    return (<>
        {data && data?.length > 0 && data?.map((item) => {
            return (
                <>
                    <Grid className='topPostcards'>
                        <Card sx={{ bgcolor: '#f8f8f8' }}>
                            <Grid p='8px 15px'>
                                <Grid sx={{ mb: 1 }}>
                                    <Typography variant='p' className='subheading' >
                                        {/* {item.message} */}
                                    </Typography>
                                </Grid>

                                <Grid sx={{ mb: 1 }} display='flex' alignItems='center'>
                                    <Typography variant='p' mr={1.5} >
                                        {/* {item.full_picture} */}
                                    </Typography>
                                    <Typography variant='p'  >
                                        {moment(item.createdTime).format("MMMM Do YYYY, h:mm:ss a")}
                                    </Typography>
                                </Grid>

                            </Grid>

                            <CardMedia
                                sx={{ height: 140 }}
                                image={item.full_picture}
                            />
                            <CardContent>
                                <Grid>
                                    <Typography variant='p' display='flex'
                                        alignItems='center'  height={100}
                                         overflow='hidden'
                                    >
                                        {item.message}
                                    </Typography>
                                </Grid>

                                <Divider />
                                <Grid display='flex' alignItems='center'
                                    justifyContent='space-between' mt={1}>
                                    <Typography variant='h6' fontWeight={500}>
                                        Reactions
                                    </Typography>
                                    <Typography variant='h6' fontWeight={500}>
                                        {item.reactions}
                                    </Typography>
                                </Grid>
                                <Grid display='flex' alignItems='center'
                                    justifyContent='space-between' mt={1}>
                                    <Typography variant='h6' fontWeight={500}>
                                        Comments
                                    </Typography>
                                    <Typography variant='h6' fontWeight={500}>
                                        {item.comments}
                                    </Typography>
                                </Grid>
                                <Grid display='flex' alignItems='center'
                                    justifyContent='space-between' mt={1}>
                                    <Typography variant='h6' fontWeight={500}>
                                        Shares
                                    </Typography>
                                    <Typography variant='h6' fontWeight={500}>
                                        {item.shares}
                                    </Typography>
                                </Grid>
                                <Grid display='flex' alignItems='center'
                                    justifyContent='space-between' mt={1}>
                                    <Typography variant='h6' fontWeight={500}>
                                        Engagement
                                    </Typography>
                                    <Typography variant='h6' fontWeight={500}>
                                        {item.engagement}
                                    </Typography>
                                </Grid>
                                <Grid display='flex' alignItems='center'
                                    justifyContent='space-between' mt={1}>
                                    <Typography variant='h6' fontWeight={500}>
                                        Engagement Rate
                                    </Typography>
                                    <Typography variant='h6' fontWeight={500}>
                                        {/* {item.EngagementRate} */}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            )
        })}

    </>
    )
}

export default TopPostCards