import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, Grid, Typography } from '@mui/material'

function TableComponent({heading,tabledata}) {
    return (<>
            <Table  size="small" aria-label="a dense table">
                <TableHead>
                    {heading?.map((item, index) => {
                        return (
                            <TableRow key={index} sx={{ height: 50 }}>
                                <TableCell align="center">
                                    <Typography variant="h6" fontWeight={500}>
                                        {item.heading1}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6" fontWeight={500}>
                                        {item.heading2}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6" fontWeight={500}>
                                        {item.heading3}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableHead>

                <TableBody>
                    {tabledata?.map((item, id) => {
                        return (
                            <TableRow key={id} sx={{ borderBottom: 'none',height: 50 }}>
                                <TableCell align="center" component="th" scope="row">
                                    <Typography variant="h6" >
                                    {item[Object.keys(item)[0]]}
                                    </Typography>
                                </TableCell>

                                <TableCell align="center">
                                    <Typography variant="h6" >
                                    {item[Object.keys(item)[1]]}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="h6" >
                                    {item[Object.keys(item)[2]]}
                                    </Typography>
                                </TableCell>
                            
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
    </>
    )
}

export default TableComponent