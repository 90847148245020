import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Label,
  LabelList,
  Legend,
} from 'recharts';
import HorizontalStackedBarChart from '../../Common/HorizontalStackedBarChart';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';

const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="Bold" />;
};

function IntagramLikesByAgeGroup() {
  const { likesByAgeLoading, likesByAgeData } = useSelector(
    (state) => state.instagram
  );

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Likes By Age Group
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {likesByAgeLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : likesByAgeData?.data?.length > 0 ? (
          <HorizontalStackedBarChart
            data={likesByAgeData?.data}
            YAxis_dataKey="age"
            bar1DataKey="Female"
            bar2DataKey="Male"
            bar3DataKey="Others"
            XAxis_Label="Number of Likes"
            YAxis_Label="Age Range"
          />
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default IntagramLikesByAgeGroup;
