import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import LineChartComponent from '../../Common/ChartComponents/LineChartComponent';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';

function EngagementActions() {
  const { engagementTypeActionsLoading, engagementTypeActionsData } =
    useSelector((state) => state.instagram);
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Engagegement Actions
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {/* <Grid width='100%' height='100%'>
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                        data={data}
                        margin={{ left: 20, right: 20, top: 20 }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" scale="auto" fontSize="12" offset={10}>
                        </XAxis >
                        <YAxis fontSize="14" label={{
                            value: 'Number Of Engagement', angle: -90, position: 'insideLeft',
                            fontSize: '16px', style: { textAnchor: 'middle' }
                        }} domain={[0, dataMax => (dataMax * 1.5)]} />
                        <Tooltip itemStyle={{ fontSize: '16px' }} wrapperStyle={{ fontSize: '16px' }} />
                        <Legend iconSize={14} wrapperStyle={{ fontSize: 18 }} iconType='circle' />
                        <Line type="monotone" dataKey="Likes"  stroke="#413ea0" />
                        <Line type="monotone" dataKey="Comments" stroke="#ff7300" />
                    </ComposedChart>
                </ResponsiveContainer>
            </Grid> */}
        {engagementTypeActionsLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : engagementTypeActionsData?.data?.length > 0 ? (
          <LineChartComponent
            data={engagementTypeActionsData?.data}
            XAxis_dataKey="date"
            line1Datakey="likesCount"
            line2Datakey="commentsCount"
            YAxis_Label="Number Of Engagement"
          />
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default EngagementActions;
