import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import VerticalDualLargeSizeBar from '../../Common/ChartComponents/VertiacalDualLargeSizedBar/VerticalDualLargeSizeBar';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const verticalDualLargeBarData = [
  {
    name: 'OTHER CLICKS',
    Clicks: 23,
    Unique_Clicks: 14,
  },
  {
    name: 'PHOTO VIEW',
    Clicks: 17,
    Unique_Clicks: 11,
  },
];

function PageClicksPostType() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { pageClicksByPostLoading, pageClicksByPostData } = useSelector(
    (state) => state.facebook
  );

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {pageClicksByPostLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Page Clicks by Post Type
            </Typography>
            <MoreHorizOutlined
              fontSize="large"
              onClick={openMenu}
              sx={{ cursor: 'pointer' }}
            />
          </Grid>
          <MenuComponent
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            displayItems={[
              'Export as PNG',
              'Export as PDF',
              'Export as CSV',
              'Send as Email',
              'Schedule',
            ]}
          />
          {pageClicksByPostData?.data?.length > 0 ? (
            <VerticalDualLargeSizeBar
              dataKey1="clicks"
              dataKey2="uniqueClicks"
              verticalDualLargeBarData={pageClicksByPostData?.data}
              xAxisLabel="name"
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
    </Box>
  );
}

export default PageClicksPostType;
