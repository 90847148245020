import { Divider, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import StoryVsEngagement from '../../../components/StoryComponents/StoryVsEngagement';
import StoryEngagementByType from '../../../components/StoryComponents/StoryEngagementByType';
import TopStoriesBy from '../../../components/StoryComponents/TopStoriesBy';
import StoriesVsImpressions from '../../../components/StoryComponents/StoriesVsImpressions';
import StoryImpressionVsReach from '../../../components/StoryComponents/StoryImpressionVsReach';
import TotalFollowersVsReach from '../../../components/StoryComponents/TotalFollowersVsReach';
import AvgExitsVsNumOfStories from '../../../components/StoryComponents/AvgExitsVsNumOfStories';
import { useSelector, useDispatch } from 'react-redux';
import {
  getStoriesVsEngagementData,
  getStoriesEngagementTypeData,
  getTopStoriesData,
  getImpressionsVsReachData,
  getFollowersVsReachData,
  getExitsVsReachData,
  getStoriesCompletionRateData,
} from '../../../redux/reduxThunk/instagram';

function Story() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchInstagramStoriesTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-05',
        toDate: '2023-05-04',
      };
      dispatch(getStoriesVsEngagementData(summaryData));
      dispatch(getStoriesEngagementTypeData(summaryData));
      dispatch(getTopStoriesData(summaryData));
      dispatch(getImpressionsVsReachData(summaryData));
      dispatch(getFollowersVsReachData(summaryData));
      dispatch(getExitsVsReachData(summaryData));
      dispatch(getStoriesCompletionRateData(summaryData));
    };
    fetchInstagramStoriesTabData();
  }, [audienceSummary]);
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <StoryVsEngagement />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <StoryEngagementByType />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
            mt: '10px',
          }}
        >
          <TopStoriesBy />
        </Grid>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <StoriesVsImpressions />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <StoryImpressionVsReach />
          </Grid>
        </Grid>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <TotalFollowersVsReach />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            {/* <AvgExitsVsNumOfStories /> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Story;
