import React, { useState } from 'react';
import { Grid, Button, Box, Typography, Tooltip } from '@mui/material';
// import './style.css';
import './style.css'
import { useDispatch, useSelector } from 'react-redux';
import {
  LinkedinIcon,
  YoutubeIcon,
  InstagramIcon,
  MyBusinessIcon,
  PinterestIcon,
  FacebookIcon,
} from '../../assets/assets/assets';
import Loader from '../../components/Loader';
import SelectSocialDropdown from './SelectSocialDropdown';
import ImageListBlock from './ImageListBlock';
import CommentReply from './CommentReply';
import redirectImg from '../../assets/assets/link.png';
import commentImg from '../../assets/assets/comment.png';
import likeImg from '../../assets/assets/heart.png';
import moment from 'moment';
import { handleActiveDiv } from '../../redux/Slice/user';

function Conversations() {
  const dispatch = useDispatch();
  const {
    allSocialAccountData,
    allSocialAccountLoading,
    socialPostsLoader,
    socialPostsData,
    selectedPost,
    activeDiv,
  } = useSelector((state) => state.user);



  const handleToggleReply = (index) => {
    dispatch(handleActiveDiv(index));
    const images = document.querySelectorAll('.engaged_reply');
    if (images.length > 0) {
      images.forEach((image) => {
        image.classList.remove('active');
      });
      const selectedImage = images[index];
      if (selectedImage) {
        selectedImage.classList.add('active');
      }
    }
  };

  // To display time difference
  function displayTimeDifference(createdTime) {
    const now = moment(); // Current date and time
    const postCreatedTime = moment(createdTime);

    const diffInHours = now.diff(postCreatedTime, 'hours');
    const diffInDays = now.diff(postCreatedTime, 'days');

    return diffInHours >= 24
      ? `${diffInDays} days ago`
      : `${diffInHours} hours ago`;
  }

  const handleNextCommentPost = () => { };

  const socialMediaData = [
    {
      name: 'facebookPage',
      icon: FacebookIcon,
      data: allSocialAccountData?.facebookPage
        ? {
          ...allSocialAccountData?.facebookPage,
          socialAccountName: 'facebookPage',
        }
        : null,
    },
    {
      name: 'facebookGroup',
      icon: FacebookIcon,
      data: allSocialAccountData?.facebookGroup
        ? {
          ...allSocialAccountData?.facebookGroup,
          socialAccountName: 'facebookGroup',
        }
        : null,
    },
    {
      name: 'instagram',
      icon: InstagramIcon,
      data: allSocialAccountData?.instagram
        ? { ...allSocialAccountData?.instagram, socialAccountName: 'instagram' }
        : null,
    },
    {
      name: 'pinterest',
      icon: PinterestIcon,
      data: allSocialAccountData?.pinterest
        ? { ...allSocialAccountData?.pinterest, socialAccountName: 'pinterest' }
        : null,
    },
    {
      name: 'google',
      icon: MyBusinessIcon,
      data: allSocialAccountData?.google
        ? { ...allSocialAccountData?.google, socialAccountName: 'google' }
        : null,
    },
    {
      name: 'linkedIn',
      icon: LinkedinIcon,
      data: allSocialAccountData?.linkedIn
        ? { ...allSocialAccountData?.linkedIn, socialAccountName: 'linkedIn' }
        : null,
    },
    {
      name: 'youtube',
      icon: YoutubeIcon,
      data: allSocialAccountData?.youtube
        ? { ...allSocialAccountData?.youtube, socialAccountName: 'youtube' }
        : null,
    },
  ];

  let filterSocialMediaData = socialMediaData?.filter((item) => item.data !== null);

  return (
    <Box sx={{
      borderTop: '3px solid #fafafa', borderLeft: ' 3px solid #fafafa',
    }}>
      <Grid container-fluid sx={{
        background: 'white', padding: '10px',
        height: '72px',
        borderBottom: '3px solid #fafafa',
      }}>
        <Box className="img_grid">
          {filterSocialMediaData && filterSocialMediaData.length > 0 && (
            <SelectSocialDropdown filterSocialMediaData={filterSocialMediaData} />
          )}
        </Box>
      </Grid>
      <Grid container-fluid sx={{ mt: 1 }} >
        {allSocialAccountLoading ? (
          <Box className="loader_div">
            <Loader color="inherit" value={100} size={40} />
          </Box>
        ) : (
          <Grid container display="flex">
            <Grid item xs={4} lg={5} >
              <Box className="image_scroll">
                {socialPostsLoader ? (
                  <Box className="loader_div">
                    <Loader color="inherit" value={100} size={40} />
                  </Box>
                ) : (
                  <ImageListBlock socialPostsData={socialPostsData} />
                )}
              </Box>
            </Grid>

            <Grid item xs={8} lg={7} className="bg_color">
              <Box className="reply_div">
                <Box className="reply_box">
                  {socialPostsLoader ? (
                    <Box className="loader_div">
                      <Loader color="inherit" value={100} size={40} />
                    </Box>
                  ) : (
                    selectedPost && Object.keys(selectedPost).length > 0 && (
                      <>
                        <Grid container className="separator_line">
                          <Grid item xs={12} sm={3} lg={4}>
                            <Box>
                              <Tooltip title="View Post" placement="top">
                                <div className="imageWrapper">
                                  <a
                                    href={selectedPost?.permalink_url}
                                    target="_blank"
                                  >
                                    <img
                                      src={selectedPost?.image_url}
                                      alt="My Image"
                                      className="image"
                                    />
                                    <div className="overlay">
                                      <img
                                        src={redirectImg}
                                        alt="My Image"
                                        className="overlay_image"
                                      />
                                    </div>
                                  </a>
                                </div>
                              </Tooltip>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={9} lg={8} className="comment_sec">
                            <Typography variant="h6" component="p">
                              {selectedPost?.message}
                            </Typography>
                            <Box className="comments_div">
                              <Box sx={{ display: 'flex', alignItems: 'end' }}>
                                <img
                                  src={likeImg}
                                  alt="like"
                                  className="comment_icon"
                                />
                                <span className="comment_count">
                                  {selectedPost?.like_count}
                                </span>
                              </Box>
                              <Box style={{ marginLeft: 10, display: 'flex', alignItems: 'end' }}>
                                <img
                                  src={commentImg}
                                  alt="comment"
                                  className="comment_icon"
                                />
                                <span className="comment_count">
                                  {selectedPost?.comments_count}
                                </span>
                              </Box>
                            </Box>
                            <Typography className="comment_date">
                              {displayTimeDifference(selectedPost?.created_time)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {selectedPost?.comments_count > 0 ? (
                              selectedPost?.comments?.map((comment, index) => {
                                return (
                                  <CommentReply
                                    commentData={comment}
                                    displayTimeDifference={displayTimeDifference}
                                    activeDiv={activeDiv}
                                    index={index}
                                    handleToggleReply={handleToggleReply}
                                  />
                                );
                              })
                            ) : (
                              <Box className="no_comment">
                                <img
                                  src="https://static.buffer.com/images/engage/inbox-empty.jpeg"
                                  alt="engage"
                                />
                                <Typography variant="h5" component="h2">
                                  There are no comments on this post yet
                                </Typography>
                                <Button
                                  className="engage_btn"
                                  onClick={handleNextCommentPost}
                                >
                                  Engage With Next Post
                                </Button>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    )
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Conversations;
