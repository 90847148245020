import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {  getCookie } from '../../helpers/cookie';

console.log("node_env",process.env,process.env.REACT_APP_onboardUrl)
  let onboardUrl, scheduleUrl, editorUrl, analyticsUrl;
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    onboardUrl = process.env.REACT_APP_onboardUrl;
    scheduleUrl = process.env.REACT_APP_scheduleUrl;
    analyticsUrl = process.env.REACT_APP_analyticsUrl;
    editorUrl = process.env.REACT_APP_editorUrl;
  } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
    onboardUrl = process.env.REACT_APP_onboardUrl;
    scheduleUrl = process.env.REACT_APP_scheduleUrl;
    analyticsUrl = process.env.REACT_APP_analyticsUrl;
    editorUrl = process.env.REACT_APP_editorUrl;
  } else {
    onboardUrl = process.env.REACT_APP_onboardUrl;
    scheduleUrl = process.env.REACT_APP_scheduleUrl;
    analyticsUrl = process.env.REACT_APP_analyticsUrl;
    editorUrl = process.env.REACT_APP_editorUrl;
  }
  
  export { onboardUrl, scheduleUrl, editorUrl, analyticsUrl };

const baseURL =
  process.env.NODE_ENV === 'production' ? 'https://api.socialxperia.ai/api' : 'http://localhost:8080/api';

// to fetch Client geolocation details
let ipinfoToken = 'ed1ce99956a407';
export const ClientGeoLocationUrl = `https://ipinfo.io/json?token=${ipinfoToken}`;

const API = axios.create({ baseURL });
API.interceptors.request.use(
  (config) => {
    const token = getCookie('token');
    if (token) {
      config.headers['token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;

// export const userOtherInfo = (data) => API.post('/userinfo', data, config);
export const getUser = () => API.get(`/user`);

// Brand apis
export const getBrandAll = () => API.get(`/get-all-brands`);
export const getBrandSettings = (id) =>API.get(`/brand-settings?brand=${id}`);

// social-accounts adding
export const getSocialAccountsSettings = (data) =>
  API.get(
    `/social-account-settings?social_id=${data.social_id}&brand_id=${data.brand_id}`
  );

export const getAllSocialAccounts = (data) =>
  API.get(`/social-accounts?brand_id=${data}`);

export const getCurrentPlanDetailsApi = () => API.get('/get-current-plan');

//get all user brands
export const getUserBrands = () => API.get('/get-user-brands');

//Conversation APIs
export const getFacebookPagePosts = (data) =>
  API.post('/social-media-posts', data);

export const replyToPostComment = (data) => API.post('/comment-reply', data);

  // Account info 
  export const accountSettingsApi = () => API.get(`/account-settings`);
