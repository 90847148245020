import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import TableComponent from '../../TableComponent/TableComponent';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import NoDataComponent from '../../Common/NoDataComponent';
import {useSelector} from "react-redux";
import Loader from '../../Loader';

const data = [
  {
    name: "16 MAR' 23",
    Impressions: 48,
    Reach: 25,
  },
  {
    name: "19 MAR' 23",
    Impressions: 33,
    Reach: 21,
  },
  {
    name: "22 MAR' 23",
    Impressions: 23,
    Reach: 14,
  },
  {
    name: "25 MAR' 23",
    Impressions: 87,
    Reach: 43,
  },
  {
    name: "28 MAR' 23",
    Impressions: 50,
    Reach: 40,
  },
  {
    name: "31 MAR' 23",
    Impressions: 33,
    Reach: 19,
  },
  {
    name: "03 APR' 23",
    Impressions: 91,
    Reach: 58,
  },
  {
    name: "06 APR' 23",
    Impressions: 44,
    Reach: 19,
  },
  {
      name: "09 APR' 23",
      Impressions: 89,
      Reach: 49,
  },
  {
    name: "12 APR' 23",
    Impressions: 145,
    Reach: 90,
},
];
function PageImpressionsvsReach() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchorEl(null);
    };
    const {pageReachImpressionLoading,pageReachImpressionData} = useSelector((state)=>state.facebook)
    
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: '38px 15px 0px 40px',
          bgcolor: '#fff',
          border: '2px solid #ECECEC',
          borderRadius: '8px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Page Impressions vs Reach
          </Typography>
          <MoreHorizOutlined fontSize="large" onClick={openMenu}
            sx={{ cursor: 'pointer' }}/>
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PNG',
            'Export as PDF',
            'Export as CSV',
            'Send as Email',
            'Schedule',
          ]}
        />
        <BarAndAreaChart
          data={data}
          XAxis_dataKey="name"
          barDataKey="Impressions"
          area1DataKey="Reach"
          YAxis_Label="Number Of Impressions"
        />
      </Box>
    </>
  );
}

export default PageImpressionsvsReach;
