import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { theme } from './theme';
import { CssBaseline, Grid, ThemeProvider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { handleToken } from './redux/Slice/user';
import { getUserData } from './redux/reduxThunk';
import FacebookAnalytic from './pages/facebookAnalytics/FacebookAnalytic';
import ConversationsPage from './pages/conversations';
import { getCookie } from './helpers/cookie';
import CircularProgress from '@mui/material/CircularProgress';

function App() {
  const { token, userData } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const cookeToken = getCookie('token');

  useEffect(() => {
    const handlePopstate = () => {
      // do nothing when back button is clicked
      window.history.pushState(null, '', window.location.href);
    };

    if (userData?.onboardingStatus === 'COMPLETED') {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopstate);
    } else {
      window.removeEventListener('popstate', handlePopstate);
    }

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
  useEffect(() => {
    if (cookeToken) {
      dispatch(handleToken(true));
      dispatch(getUserData());
    }
  }, [token]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust the delay as needed
  }, []);
  if (isLoading) {
    return <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress color="secondary" value={50} size={50} />;
    </Grid>

  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route exact path="/reports" element={<FacebookAnalytic />} />
          <Route exact path="/conversations" element={<ConversationsPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
