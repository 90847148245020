import React, { useEffect } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import SingleBarChart from '../../../components/Common/ChartComponents/SingleBarChart';
import BarAndAreaChart from '../../../components/Common/ChartComponents/BarAndAreaChart';
import { useSelector, useDispatch } from 'react-redux';
import { getPageReachImpressionData } from '../../../redux/reduxThunk/instagram';
import NoDataComponent from '../../../components/Common/NoDataComponent';
import Loader from '../../../components/Loader';

function ImpressionsVsReach() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const { pageReachImpressionLoading, pageReachImpressionData } = useSelector(
    (state) => state.instagram
  );
  const data = [
    {
      name: "16 MAR' 23",
      Reach: 421,
      Impressions: 800,
    },
    {
      name: "17 MAR' 23",
      Reach: 354,
      Impressions: 750,
    },
    {
      name: "18 MAR' 23",
      Reach: 380,
      Impressions: 755,
    },
    {
      name: "19 MAR' 23",
      Reach: 410,
      Impressions: 820,
    },
    {
      name: "20 MAR' 23",
      Reach: 420,
      Impressions: 840,
    },
    {
      name: "21 MAR' 23",
      Reach: 350,
      Impressions: 750,
    },
    {
      name: "22 MAR' 23",
      Reach: 425,
      Impressions: 760,
    },
    {
      name: "23 MAR' 23",
      Reach: 233,
      Impressions: 755,
    },
    {
      name: "24 MAR' 23",
      Reach: 546,
      Impressions: 820,
    },
    {
      name: "25 MAR' 23",
      Reach: 480,
      Impressions: 840,
    },
    {
      name: "26 MAR' 23",
      Reach: 233,
      Impressions: 750,
    },
    {
      name: "27 MAR' 23",
      Reach: 333,
      Impressions: 760,
    },
  ];
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchInstagramReachImpressionsTabData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-02',
        toDate: '2023-04-13',
      };
      dispatch(getPageReachImpressionData(summaryData));
    };
    fetchInstagramReachImpressionsTabData();
  }, [audienceSummary]);
  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container>
          <Grid lg={12}>
            <Box
              sx={{
                width: '100%',
                bgcolor: '#fff',
                p: '8px 15px',
              }}
            >
              <Grid display="flex" justifyContent="space-between" mb={4}>
                <Typography variant="h4" color="#000" fontWeight={600}>
                  Impressions Vs Reach
                </Typography>
                <MoreHorizOutlined fontSize="large" />
              </Grid>
              {pageReachImpressionLoading ? (
                <Loader color="inherit" value={100} size={25} />
              ) : pageReachImpressionData?.length > 0 ? (
                <BarAndAreaChart
                  data={pageReachImpressionData}
                  XAxis_dataKey="name"
                  barDataKey="Reach"
                  area1DataKey="Impressions"
                  YAxis_Label="Number Of Followers"
                />
              ) : (
                <NoDataComponent />
              )}
            </Box>
          </Grid>
        </Grid>
        {/* <Grid width="100%" height="100%" visibility="hidden">
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Impressions Vs Reach
            </Typography>
            <MoreHorizOutlined fontSize="large" />
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
}

export default ImpressionsVsReach;
