import React, { useCallback, useState } from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TableComponent from '../../../TableComponent/TableComponent';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { useSelector } from 'react-redux';
import DoughnutChart from '../../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import MenuComponent from '../../../Common/MenuComponent/MenuComponent';
import Loader from '../../../Loader';
import NoDataComponent from '../../../Common/NoDataComponent';

const facebookData = [
  {
    Title: 'directly via facebook',
    PostedtContentIcon: <PhotoSizeSelectActualIcon />,
    DateAndTime: '27 march 2023 12:00 AM',
    PostedContent:
      'https://scontent.fbom61-1.fna.fbcdn.net/v/t39.30808-6/336363969_871328360600246_5636292073696989109_n.jpg?stp=dst-jpg_p720x720&_nc_cat=102&ccb=1-7&_nc_sid=730e14&_nc_ohc=wrDwVcqiu6IAX91v3IQ&_nc_ht=scontent.fbom61-1.fna&edm=AJfPMC4EAAAA&oh=00_AfANZZiRD1s1IzRrXGitx9y23JMvxleEfnb8LVs9uSmcMg&oe=643DD81B',
    Description: ` Discover the rich cultural heritage of Yakshagaana, a traditional form of theater from Karnataka,
                         with our vibrant poster! The intricate costumes and dynamic performances are sure to captivate you.`,
    Reactions: 20,
    Comments: 10,
    Shares: 1,
    Engagement: 30,
    EngagementRate: '60%',
  },
];

function PostAndEngagement() {
  let { facebook, instagram } = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  let { postEngagementSummaryLoading, postEngagementSummaryData } = useSelector(
    (state) => state.facebook
  );

  const heading = [
    {
      heading1: 'Type',
      heading2: 'Engagegement',
      heading3: '%',
    },
  ];
  const tabledataFacebook = [
    {
      data1: 'Image  ',
      data2: postEngagementSummaryData?.engagementByPostType?.photo_view,
      data3: postEngagementSummaryData?.engagementByPostType?.photo_view,
    },
    {
      data1: 'videos  ',
      data2: postEngagementSummaryData?.engagementByPostType?.video_play,
      data3: postEngagementSummaryData?.engagementByPostType?.video_play,
    },
    {
      data1: 'Text',
      data2: postEngagementSummaryData?.engagementByPostType?.other_clicks,
      data3: postEngagementSummaryData?.engagementByPostType?.other_clicks,
    },
    {
      data1: 'Link',
      data2: postEngagementSummaryData?.engagementByPostType?.link_clicks,
      data3: postEngagementSummaryData?.engagementByPostType?.link_clicks,
    },
  ];
  const sum = tabledataFacebook.reduce((total, item) => total + item.data2, 0);
  var doughnutData = [
    { labels: 'Engagement', value: [sum] },
    {
      labels: 'Image',
      value: postEngagementSummaryData?.engagementByPostType?.photo_view,
    },
    {
      labels: 'Video',
      value: postEngagementSummaryData?.engagementByPostType?.video_play,
    },
    {
      labels: 'Text',
      value: postEngagementSummaryData?.engagementByPostType?.other_clicks,
    },
    {
      labels: 'Link',
      value: postEngagementSummaryData?.engagementByPostType?.link_clicks,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Post & Engagement Summary
          </Typography>
          <MoreHorizOutlined
            fontSize="large"
            onClick={openMenu}
            sx={{ cursor: 'pointer' }}
          />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />
       

        {postEngagementSummaryLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid container columns={12}>
              <GridContainer3>
                <GridDataComponent
                  heading="Total Posts"
                  value={postEngagementSummaryData?.totalPosts}
                  percentRise={(
                    (postEngagementSummaryData?.totalPosts * 100) /
                    100
                  ).toFixed(2)}
                  avg="5"
                />

                <Grid mb={2} />
                <Divider />

                <GridDataComponent
                  heading="Total engangeMent"
                  value={postEngagementSummaryData?.engagedCount}
                  percentFall={(
                    (postEngagementSummaryData?.engagedCount * 100) /
                    100
                  ).toFixed(2)}
                  avg="5"
                />
                <Grid mb={2} />
              </GridContainer3>
              <Divider orientation="vertical" flexItem />

              <GridContainer3>
                <Typography variant="h5" className="subheading" mb={2}>
                  Top Post
                </Typography>

                {/* {facebookData.map((item) => ( */}
                <>
                  <Typography variant="p" className="subheading" mb={1}>
                    {/* {item.Title} */}
                  </Typography>

                  <Typography
                    variant="p"
                    display="flex"
                    alignItems="center"
                    mb={1}
                  >
                    <PhotoSizeSelectActualIcon
                      fontSize="medium"
                      sx={{ mr: 1 }}
                    />
                    {postEngagementSummaryData?.topEngagePost?.createdTime}
                  </Typography>
                  <Grid width="100%" mb={1}>
                    <img
                      className="image"
                      src={
                        postEngagementSummaryData?.topEngagePost?.full_picture
                      }
                      alt="img"
                    />
                  </Grid>
                  <Typography variant="p" display="flex" alignItems="center">
                    {postEngagementSummaryData?.topEngagePost?.message}
                  </Typography>
                  <Divider />
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Reactions
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {postEngagementSummaryData?.topEngagePost?.reactions}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Comments
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {postEngagementSummaryData?.topEngagePost?.comments}
                    </Typography>
                  </Grid>

                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Shares
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {postEngagementSummaryData?.topEngagePost?.shares}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Engagement
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {postEngagementSummaryData?.topEngagePost?.engagement}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Engagement Rate
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {postEngagementSummaryData?.topEngagePost?.EngagementRate}
                    </Typography>
                  </Grid>
                </>
                {/* // ))} */}
              </GridContainer3>

              <Divider orientation="vertical" flexItem />

              <Divider orientation="vertical" flexItem />
              {postEngagementSummaryData?.engagedCount > 0 ? (
                <>
                  <Grid lg={5.5} pl="15px">
                    <Typography variant="h5" className="subheading">
                      Engagement by post type
                    </Typography>
                    <DoughnutChart
                      doughnutData={doughnutData}
                      dataKey="value"
                    />

                    <Grid height="100%">
                      <TableComponent
                        heading={heading}
                        tabledata={tabledataFacebook}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <NoDataComponent />
              )}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default PostAndEngagement;
