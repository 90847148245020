import React from 'react'
import { Grid } from '@mui/material'
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";

function BarAndLineChart({
    data,
    XAxis_dataKey,
    barDatakey,
    line1Datakey,
    line2Datakey,
    YAxis_Label
}) {
    return (
        <>
            <Grid width='100%' height='100%'>
                <ResponsiveContainer width="100%" height={500}>
                    <ComposedChart
                        data={data}
                        margin={{ left: 0, right: 20, top: 20 }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey={XAxis_dataKey} scale="auto" fontSize="12">
                        </XAxis >
                        <YAxis fontSize="14" label={{
                            value: YAxis_Label, angle: -90, position: 'insideLeft',
                            fontSize: '16px',style: { textAnchor: 'middle' }
                        }} domain={[0, dataMax => (dataMax * 1.5)]} />
                        <Tooltip itemStyle={{ fontSize: '16px' }} wrapperStyle={{ fontSize: '16px' }} />
                        <Legend iconSize={14} wrapperStyle={{ fontSize: 18 }} iconType='circle' />
                        {barDatakey ? <Bar type="monotone" barSize={50} dataKey={barDatakey} fill="#413ea0" /> : null}
                        {line1Datakey ? <Line type="monotone" dataKey={line1Datakey} stroke="#ff7300" /> : null}
                        {line2Datakey ? <Line type="monotone" dataKey={line2Datakey} stroke="#82ca9d" /> : null}
                    </ComposedChart>
                </ResponsiveContainer>
            </Grid>
        </>
    )
}

export default BarAndLineChart