import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from 'recharts';
import SingleBarChart from '../../Common/ChartComponents/SingleBarChart';
import Loader from '../../../components/Loader';
import NoDataComponent from '../../Common/NoDataComponent';
import { useSelector, useDispatch } from 'react-redux';

const data = [
  {
    name: '16 Mar 23',
    Profile_Clicks: 0,
  },
  {
    name: '19 Mar 23',
    Profile_Clicks: 0,
  },
  {
    name: '22 Mar 23',
    Profile_Clicks: 3,
  },
  {
    name: '25 Mar 23',
    Profile_Clicks: 0,
  },
  {
    name: '28 Mar 23',
    Profile_Clicks: 5,
  },
  {
    name: '31 Mar 23',
    Profile_Clicks: 5,
  },
  {
    name: '3 Apr 23',
    Profile_Clicks: 9,
  },
  {
    name: '6 Apr 23',
    Profile_Clicks: 2,
  },
  {
    name: '9 Apr 23',
    Profile_Clicks: 1,
  },
  {
    name: '12 Apr 23',
    Profile_Clicks: 5,
  },
];

function ProfileClicks() {
  const { pageClicksLoading, pageClicksData } = useSelector(
    (state) => state.instagram
  );
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#fff',
        p: 4,
        border: '2px solid #ECECEC',
        borderRadius: '8px',
        mb: '10px',
      }}
    >
      {' '}
      {pageClicksLoading ? (
        <Loader color="inherit" value={100} size={25} />
      ) : (
        <>
          <Grid display="flex" justifyContent="space-between" mb={4}>
            <Typography variant="h4" color="#000" fontWeight={600}>
              Profile Clicks
            </Typography>
            <MoreHorizOutlined fontSize="large" />
          </Grid>
          {/* <Grid container columns={12}>
        <ResponsiveContainer height={600} width={'100%'}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 20,

              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis
              fontSize="14"
              label={{
                value: 'Number Of Views',
                angle: -90,
                position: 'left',
                fontSize: '16px',
                style: { textAnchor: 'middle' }
              }}
              domain={[0, 'dataMax']}
            />
            <Tooltip
              itemStyle={{ fontSize: '16px' }}
              wrapperStyle={{ fontSize: '16px' }}
            />
            <Legend
              iconSize={14}
              wrapperStyle={{ fontSize: 18 }}
              iconType="circle"
            />

            <Bar dataKey="Profile_Clicks" barSize={40} fill="#413ea0" />
          </BarChart>
        </ResponsiveContainer>
      </Grid> */}
          {pageClicksData?.length > 0 ? (
            <SingleBarChart
              data={pageClicksData}
              XAxis_dataKey="end_time"
              barDataKey="Profile_Clicks"
              YAxis_Label="Number Of Views"
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
    </Box>
  );
}

export default ProfileClicks;
