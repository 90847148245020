import React, { useCallback, useState } from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TableComponent from '../../../TableComponent/TableComponent';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DoughnutChart from '../../../Common/ChartComponents/DoughnutChart/DoughnutChart';
import Loader from '../../../Loader';
import NoDataComponent from '../../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Engagegement',
    heading3: '%',
  },
];

function PostAndEngagement() {
  let { facebook, instagram } = useSelector((state) => state.user);
  let { postEngagementSummaryLoading, postEngagementSummaryData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {postEngagementSummaryLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Post & Engagement Summary
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            <Grid container columns={12}>
              <GridContainer3>
                <GridDataComponent
                  heading="Total Posts"
                  value={postEngagementSummaryData?.totalPosts}
                  percentRise={(
                    (postEngagementSummaryData?.totalPosts * 100) /
                    100
                  ).toFixed(2)}
                  avg="2"
                />

                <Grid mb={2} />
                <Divider />
                <Grid mb={2} />

                <GridDataComponent
                  heading="Total engangeMent"
                  value={postEngagementSummaryData?.engagedCount}
                  percentFall={(
                    (postEngagementSummaryData?.engagedCount * 100) /
                    100
                  ).toFixed(2)}
                  avg="27"
                />
              </GridContainer3>
              <Divider orientation="vertical" flexItem />
              {postEngagementSummaryData?.topPost && (
                <GridContainer3>
                  <Typography variant="h5" className="subheading" mb={2}>
                    Top Post
                  </Typography>

                  {/* {instaData.map((item) => ( */}
                  <>
                    <Typography variant="p" className="subheading" mb={1}>
                      DIRECTLY VIA INSTAGRAM
                    </Typography>

                    <Typography
                      variant="p"
                      display="flex"
                      alignItems="center"
                      mb={1}
                    >
                      <PhotoSizeSelectActualIcon
                        fontSize="medium"
                        sx={{ mr: 1 }}
                      />
                      {moment(
                        postEngagementSummaryData?.topPost?.createdTime
                      ).format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                    <Grid width="100%" mb={1}>
                      <img
                        className="image"
                        src={postEngagementSummaryData?.topPost?.full_picture}
                        alt="img"
                      />
                    </Grid>
                    <Typography variant="p" display="flex" alignItems="center">
                      {postEngagementSummaryData?.topPost?.message}
                    </Typography>
                    <Divider />
                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Typography variant="h6" fontWeight={500}>
                        Reactions
                      </Typography>
                      <Typography variant="h6" fontWeight={500}>
                        {postEngagementSummaryData?.topPost?.Reactions}
                      </Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Typography variant="h6" fontWeight={500}>
                        Comments
                      </Typography>
                      <Typography variant="h6" fontWeight={500}>
                        {postEngagementSummaryData?.topPost?.comments}
                      </Typography>
                    </Grid>

                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Typography variant="h6" fontWeight={500}>
                        Shares
                      </Typography>
                      <Typography variant="h6" fontWeight={500}>
                        {postEngagementSummaryData?.topPost?.shares}
                      </Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Typography variant="h6" fontWeight={500}>
                        Engagement
                      </Typography>
                      <Typography variant="h6" fontWeight={500}>
                        {postEngagementSummaryData?.topPost?.engagement}
                      </Typography>
                    </Grid>
                    <Grid
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Typography variant="h6" fontWeight={500}>
                        Engagement Rate
                      </Typography>
                      <Typography variant="h6" fontWeight={500}>
                        {postEngagementSummaryData?.topPost?.EngagementRate}
                      </Typography>
                    </Grid>
                  </>
                  {/* ))} */}
                </GridContainer3>
              )}
              <Divider orientation="vertical" flexItem />
              <Grid lg={5.5} pl="15px">
                <Typography variant="h5" className="subheading">
                  Engagement by post type
                </Typography>
                {postEngagementSummaryData?.engagementType?.data?.length > 0 ? (
                  <>
                    <DoughnutChart
                      doughnutData={
                        postEngagementSummaryData?.engagementType?.doughnutData
                      }
                      dataKey="value"
                    />

                    <Grid height="100%">
                      <TableComponent
                        heading={heading}
                        tabledata={
                          postEngagementSummaryData?.engagementType?.data
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <NoDataComponent />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default PostAndEngagement;
