import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridContainer from '../../../Common/gridContainer/GridContainer';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import { useSelector } from 'react-redux';
import PiChart from '../../../Common/ChartComponents/PiCharts/PiCharts';
import MenuComponent from '../../../Common/MenuComponent/MenuComponent';
import Loader from '../../../Loader';
import NoDataComponent from '../../../Common/NoDataComponent';

function AudienceSummary() {
  let { facebook, instagram } = useSelector((state) => state.user);
  const { audienceSummaryDataLoading, audienceSummaryData } = useSelector(
    (state) => state.facebook
  );
  const piData = [
    {
      name: 'Organic',
      value: audienceSummaryData?.paidLikes,
      percentage: audienceSummaryData?.paidLikes,
    },
    {
      name: 'Paid',
      value: audienceSummaryData?.organicLikes,
      percentage: audienceSummaryData?.organicLikes,
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Audience Summary
          </Typography>
          <MoreHorizOutlined fontSize="large" onClick={openMenu} />
        </Grid>
        <MenuComponent
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          displayItems={[
            'Export as PDF',
            'Export as PNG',
            'Send as Email',
            'Schedule',
          ]}
        />

        
        {audienceSummaryDataLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid container columns={12}>
              <GridContainer>
                <GridDataComponent
                  heading="Total Page Likes"
                  value={audienceSummaryData?.totalLikes}
                />
              </GridContainer>
              <Divider orientation="vertical" flexItem />
              <GridContainer3>
                <GridDataComponent
                  heading="New Likes"
                  value={audienceSummaryData?.newLikes}
                  percentRise={(
                    (audienceSummaryData?.avgDayLikes * 100) /
                    100
                  ).toFixed(2)}
                  avg={audienceSummaryData?.avgDayLikes}
                />
              </GridContainer3>

              <Divider orientation="vertical" flexItem />
              <GridContainer3>
                <GridDataComponent
                  heading="Likes Lost"
                  value={audienceSummaryData?.lostLikes}
                  avg={audienceSummaryData?.avgDayUnLikes}
                />
              </GridContainer3>
              <Divider orientation="vertical" flexItem />
              <Grid lg={3} p="8px 15px">
                <Typography variant="h5" className="subheading">
                  ORGANIC VS PAID LIKES
                </Typography>
                <Grid width="100%">
                  <Grid container width="100%">
                    <Grid width="100%" lg={7}>
                      {audienceSummaryData?.paidLikes > 0 ||
                      audienceSummaryData?.organicLikes > 0 ? (
                        <>
                          <PiChart piData={piData} dataKey="value" />
                        </>
                      ) : (
                        <NoDataComponent />
                      )}
                    </Grid>
                    {audienceSummaryData?.paidLikes > 0 ||
                    audienceSummaryData?.organicLikes > 0 ? (
                      <Grid
                        lg={5}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box sx={{ mb: 5 }}>
                          {piData?.map((item) => (
                            <Box display="flex">
                              <Typography variant="h5" sx={{ ml: 1 }}>
                                {item.percentage}
                              </Typography>
                              <Typography variant="h5">{item.name}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}
export default AudienceSummary;
