import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { MoreHorizOutlined } from '@mui/icons-material';
import BarAndAreaChart from '../../Common/ChartComponents/BarAndAreaChart';
import { useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

const data = [
  {
    name: "16 MAR' 23",
    Reach: 0,
    Followers: 800,
  },
  {
    name: "17 MAR' 23",
    Reach: 0,
    Followers: 750,
  },
  {
    name: "18 MAR' 23",
    Reach: 0,
    Followers: 755,
  },
  {
    name: "19 MAR' 23",
    Reach: 0,
    Followers: 820,
  },
  {
    name: "20 MAR' 23",
    Reach: 0,
    Followers: 840,
  },
  {
    name: "21 MAR' 23",
    Reach: 0,
    Followers: 750,
  },
  {
    name: "22 MAR' 23",
    Reach: 0,
    Followers: 760,
  },
];
function TotalFollowersVsReach() {
  let { storiesFollowerReachLoading, storiesFollowerReachData } = useSelector(
    (state) => state.instagram
  );
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Total Followers Vs Reach
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {storiesFollowerReachLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : storiesFollowerReachData?.data?.length > 0 ? (
          <BarAndAreaChart
            data={storiesFollowerReachData?.data}
            XAxis_dataKey="date"
            barDataKey="Reach"
            area1DataKey="Followers"
            YAxis_Label="Number Of Followers"
          />
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default TotalFollowersVsReach;
