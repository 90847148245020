import { Typography, Box, Grid, Divider } from '@mui/material';
import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TopPostCards from '../../TopPostCards/TopPostCards';
import Loader from '../../Loader';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';

function InstagramTopPostBy() {
  const { topPostsLoading, topPostsData } = useSelector(
    (state) => state.instagram
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Top Posts By
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {topPostsLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : topPostsData?.data?.length > 0 ? (
          <Grid container display="flex">
            <TopPostCards data={topPostsData?.data} />
          </Grid>
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default InstagramTopPostBy;
