import React, { useEffect } from 'react';
import InstagramNumOfPostsAndEngagement from '../../../components/InstagramPost&mangement/NumOfPostsAndEngagement/InstagramNumOfPostsAndEngagement';
import InstagramPostByType from '../../../components/InstagramPost&mangement/PostByType/InstagramPostByType';
import { Divider, Grid } from '@mui/material';
import InstagramTopPostBy from '../../../components/InstagramPost&mangement/TopPostBy/InstagramTopPostBy';
import PostEngagement from '../../../components/InstagramPost&mangement/PostEngagement/PostEngagement';
import InstgramEngagementByPostType from '../../../components/InstagramPost&mangement/EngagementByPostType/InstgramEngagementByPostType';
import EngagementActions from '../../../components/InstagramPost&mangement/EngagementActions/EngagementActions';
import {
  getPostsByTypeData,
  getTopPostsData,
  getPostsEngagementImpressionData,
  getEngagementByTypeData,
  getEngagementTypeActionsData,
} from '../../../redux/reduxThunk/instagram';
import { useSelector, useDispatch } from 'react-redux';

function InstagramPostAndEngagement() {
  const { audienceSummary } = useSelector((state) => state.facebook);
  const dispatch = useDispatch();
  // on load fetching API data and assigning to redux state
  const dataFetchedRef = React.useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    const fetchInstaPostAndEngageData = () => {
      const summaryData = {
        socialId: audienceSummary?.data?._id,
        socialAccountName: audienceSummary?.name,
        fromDate: '2023-04-05',
        toDate: '2023-05-01',
      };
      dispatch(getPostsEngagementImpressionData(summaryData));
      dispatch(getPostsByTypeData(summaryData));
      dispatch(getTopPostsData(summaryData));
      dispatch(getEngagementByTypeData(summaryData));
      dispatch(getEngagementTypeActionsData(summaryData));
    };
    fetchInstaPostAndEngageData();
  }, [audienceSummary]);

  return (
    <>
      <Grid sx={{ width: '100%' }} container>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <InstagramNumOfPostsAndEngagement />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <InstagramPostByType />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            bgcolor: '#fff',
            p: 4,
            border: '2px solid #ECECEC',
            borderRadius: '8px',
            mb: '10px',
          }}
        >
          <InstagramTopPostBy />
        </Grid>
        <Grid container>
          <Grid lg={6} sx={{ m: 1 }}>
            <PostEngagement />
          </Grid>

          <Grid lg={5.5} sx={{ m: 1 }}>
            <InstgramEngagementByPostType />
          </Grid>
        </Grid>
        <Grid container>
          <Grid lg={11.5} sx={{ m: 1 }}>
            <EngagementActions />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default InstagramPostAndEngagement;
