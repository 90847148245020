import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBrandAll,
  getAllSocialAccounts,
  getSocialAccountsSettings,
  getUser,
  getCurrentPlanDetailsApi,
  getUserBrands,
  getFacebookPagePosts,
  getBrandSettings
} from '../Api';

export const getAllBrandData = createAsyncThunk(
  'Brand/get-all-brands',
  async (accountType) => {
    if (accountType === 'USER') {
      return await getUserBrands();
    } else {
      return await getBrandAll();
    }
  }
);

export const getAllSocialAccountData = createAsyncThunk(
  'Brand/social-accounts',
  async (id) => await getAllSocialAccounts(id)
);
export const getSocialAccountsSettingsData = createAsyncThunk(
  'Brand/social-account-settings',
  async (data) => await getSocialAccountsSettings(data)
);

export const getUserData = createAsyncThunk(
  'User/user',
  async () => await getUser()
);

export const getCurrentPlanDetailsApiData = createAsyncThunk(
  'User/get-current-plan',
  async () => await getCurrentPlanDetailsApi()
);

export const getFacebookPagePostsData = createAsyncThunk(
  'Brand/facebook-posts',
  async (data) => await getFacebookPagePosts(data)
);

export const getSocialPagePostsData = createAsyncThunk(
  'Brand/social-posts',
  async (data) => await getFacebookPagePosts(data)
);

export const getBrandSettingData = createAsyncThunk(
  'Brand/brand-settings',
  async (id) => await getBrandSettings(id)
);
