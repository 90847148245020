import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './facebook.css';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Divider, Grid, IconButton } from '@mui/material';
import { FacebookIcon, InstagramIcon } from '../../assets/assets/assets';
import {
    CalendarMonthOutlined,
    Delete,
} from '@mui/icons-material';
import {
    TextField,
    ListItem,
    ListItemButton,
    ListItemIcon,
    List,
} from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import FacebookSummary from './summary/FacebookSummary';
import add from '../../assets/assets/plus.png';
import Audience from './Audience/Audience';
import PostsAndEngagements from './PostsAndEngagements/PostsAndEngagements';
import Performance from './Performane/Performance';
import ReachandImpression from './ReachandImpression/ReachandImpression';
import Messenger from './Messenger';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReelsAndManagement from '../Instagram/ReelsAndManagement';
import Story from '../Instagram/Story';
import {
    handleFacebookState,
    handleInstagramState,
} from '../../redux/Slice/user';
import ImpressionsVsReach from '../Instagram/ImpressionsVsReach';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Tooltip from '@mui/material/Tooltip';
import InstagramPostAndEngagement from '../Instagram/InstagramPostAndEngagement';
import InstagramSummary from '../Instagram/InstagramSummary/InstagramSummary';
import InstagramAudience from '../Instagram/InstagramAudiance/InstagramAudiance';
import InstagramPerformance from '../Instagram/InstagramPerformance/InstagramPerformance';
import NestedAvatar from '../../components/NestedAvatar';
import { handleAudienceSummary } from '../../redux/Slice/facebook';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import addCards, { handleActiveComponent, handleAddCards } from '../../redux/Slice/addCards';
import AddCards from '../../components/Common/AddCardsComponent/AddCards';
import Menu from '@mui/material/Menu';
import DatePicker from "react-multi-date-picker";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }} className="tabPanel-box">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function FacebookAnalyticTabs(props) {
    const dispatch = useDispatch();
    let { allSocialAccountData, facebook, instagram } = useSelector((state) => state.user);
    const { audienceSummary } = useSelector((state) => state.facebook);
    const [value, setValue] = useState(0);
    const [customValue, setCustomValue] = useState(0);
    const [newTabs, setNewTabs] = useState("Untitled Dashboard");
    const [customReportComponents, setCustomReportComponents] = useState([<InstagramSummary />]);
    const [activeIndex, setActiveIndex] = useState(value);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [calendarOpen, setCalendarOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const calendarOpen = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    const fullWidth = true
    const maxWidth = 'md'
    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log('value...', value)
    };


    const [openAddCards, setOpenAddCards] = React.useState(false);
    const { selectedCardComponent, activeComponentName, dateRangeText } = useSelector(
        (state) => state.selectedCardComponent
    );

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    useEffect(() => {
        dispatch(handleAudienceSummary(filterSocialMediaData[0]));
    }, [allSocialAccountData]);

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    useEffect(() => {
        dispatch(handleAudienceSummary(filterSocialMediaData[0]));
    }, [allSocialAccountData]);



    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleCustomReportChange = (event, newValue) => {
        setCustomValue(newValue);
        console.log('CustomValue', customValue)
        console.log('newValue', newValue)
        dispatch(handleActiveComponent(selectedCardComponent[newValue].name))
    };

    const facebook_tab_list = [
        'Summary',
        'Audience',
        'Posts & Engagements',
        'Performance',
        'Reach and Impression',
        'Messenger',
    ];
    const facebookComponents = [
        <FacebookSummary />,
        <Audience />,
        <PostsAndEngagements />,
        <Performance />,
        <ReachandImpression />,
        <Messenger />,
    ];

    const instagram_tab_list = [
        'Summary',
        'Audience',
        'Posts & Engagements',
        'Performance',
        'Reach and Impression',
        'Reels and Management',
        'Story',
    ];
    const instagramComponents = [
        <InstagramSummary />,
        <InstagramAudience />,
        <InstagramPostAndEngagement />,
        <InstagramPerformance />,
        <ImpressionsVsReach />,
        <ReelsAndManagement />,
        <Story />,
    ];
    const socialMediaData = [
        {
            name: 'facebookPage',
            icon: FacebookIcon,
            data: allSocialAccountData?.facebookPage
                ? allSocialAccountData?.facebookPage
                : null,
        },
        {
            name: 'facebookGroup',
            icon: FacebookIcon,
            data: allSocialAccountData?.facebookGroup
                ? allSocialAccountData?.facebookGroup
                : null,
        },
        {
            name: 'instagram',
            icon: InstagramIcon,
            data: allSocialAccountData?.instagram
                ? allSocialAccountData?.instagram
                : null,
        },
        {
            name: 'Pinterest',
            icon: InstagramIcon,
            data: allSocialAccountData?.pinterest
                ? allSocialAccountData?.pinterest
                : null,
        },
        {
            name: 'google',
            icon: InstagramIcon,
            data: allSocialAccountData?.google ? allSocialAccountData?.google : null,
        },
        {
            name: 'LinkedIn',
            icon: InstagramIcon,
            data: allSocialAccountData?.linkedIn
                ? allSocialAccountData?.linkedIn
                : null,
        },
        {
            name: 'Youtube',
            icon: InstagramIcon,
            data: allSocialAccountData?.youtube
                ? allSocialAccountData?.youtube
                : null,
        },
        {
            name: 'CustomReports',
            icon: <DashboardIcon sx={{ fontSize: '35px' }} />,
            data: 1,
        },
    ];

    let filterSocialMediaData =
        socialMediaData && socialMediaData?.filter((item) => item.data !== null);
    const handleClickSocialAccount = (index, socialAccount) => {
        setActiveIndex(index);
        dispatch(handleAudienceSummary(socialAccount));
        dispatch(handleInstagramState(true));
    };

    useEffect(() => {
        dispatch(handleAudienceSummary(filterSocialMediaData[0]));
    }, [allSocialAccountData]);

    const handleCustomReports = (event) => {
        event.preventDefault();
        const newItem = {
            name: newTabs,
            components: [],
        };
        dispatch(handleAddCards([...selectedCardComponent, newItem]));
        dispatch(handleActiveComponent(newTabs))
        dispatch(handleFacebookState(false));
        setNewTabs('Untitled Dashboard');

    };
    const handleAddDashboard = () => {
        dispatch(handleFacebookState(true));
    };

    const handleAddCardsClick = (i) => {
        setOpenAddCards(true);

    }

    const handleUpdateSelectedCard = () => {
        dispatch(handleAddCards([]));

    }

    const handleEditNames = () => {
        dispatch(handleFacebookState(true))
    }
    const handleCancel = () => {
        dispatch(handleFacebookState(false))
    }
    const handleDeleteCustomReport = (id) => {
        const updatedItems = selectedCardComponent.filter(
            (x, index) => index !== id);
        if (customValue === 0) {
            setCustomValue(0)
        }
        else {
            setCustomValue(id - 1)
        }

        dispatch(handleAddCards(updatedItems));

        setDialogOpen(false);
    }


    const handleClickOpen = () => {
        if (selectedCardComponent.length > 0) {
            setDialogOpen(true);
        }

    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const openCalenderRangeSelector = () => {
        setCalendarOpen(true);
    };

    const closeCalenderRangeSelector = () => {
        setCalendarOpen(false);
        setAnchorEl(null);
    };
    const [states, setStates] = useState('Select Date Range');

    return (<>
        <Grid maxHeight='100%'  >
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="facebook_tab"
                >
                    {audienceSummary?.name === "facebookPage" && facebook_tab_list.map((item, index) => {
                        return (
                            <Tab
                                className="facebook_tab_list"
                                label={
                                    <Typography variant="h6" fontWeight={600}>
                                        {item}
                                    </Typography>
                                }
                                {...a11yProps(index)}
                            />
                        )
                    })
                    }


                    {audienceSummary?.name === 'facebookGroup' &&
                        facebook_tab_list.map((item, index) => {
                            return (
                                <Tab
                                    className="facebook_tab_list"
                                    label={
                                        <Typography variant="h6" fontWeight={600}>
                                            {item}
                                        </Typography>
                                    }
                                    {...a11yProps(index)}
                                />
                            );
                        })}
                    {audienceSummary?.name === 'instagram' &&
                        instagram_tab_list.map((item, index) => {
                            return (
                                <Tab
                                    className="facebook_tab_list"
                                    label={
                                        <Typography variant="h6" fontWeight={600}>
                                            {item}
                                        </Typography>
                                    }
                                    {...a11yProps(index)}
                                />
                            );
                        })}
                </Tabs>
            </Box>
            <Grid item display="flex">
                <Grid
                    className="social-account-tab"
                    sx={{ border: '2px solid #ECECEC', borderRadius: '8px' }}
                >
                    <Grid className="social-account-tab">
                        {filterSocialMediaData &&
                            filterSocialMediaData?.length > 0 &&
                            filterSocialMediaData?.map((socialAccount, index) => (
                                <Grid
                                    sx={{ display: 'flex', marginBottom: '25px' }}
                                    className={`${activeIndex === index
                                        ? 'social-media-image'
                                        : 'social-media-image-disable'
                                        }`}
                                    onClick={() =>
                                        handleClickSocialAccount(index, socialAccount)
                                    }
                                    title={socialAccount?.data?.pageName}
                                >
                                    {socialAccount.name !== 'CustomReports' ? (
                                        <NestedAvatar
                                            key={index}
                                            sx={{ height: 50, width: 50 }}
                                            src1={socialAccount?.data?.profileImage}
                                            src2={socialAccount.icon}
                                        />
                                    ) : (
                                        <IconButton
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: 50,
                                                width: 50,
                                                border: '1px solid black',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                            }}
                                            title="Custom Reports"
                                        >
                                            <DashboardIcon sx={{ fontSize: '35px' }} />
                                        </IconButton>
                                    )}
                                </Grid>
                            ))}

                        {true ? (
                            <img
                                sx={{ height: '50px', width: '50px' }}
                                src={add}
                                className="social-media-image"
                                title="add social account"
                            // onClick={AddSocialAccounts}
                            />
                        ) : (
                            <img
                                sx={{ height: '50px', width: '50px' }}
                                src={add}
                                className="social-media-image"
                                title="add social account"
                            // onClick={upgradeSocialAccounts}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container>

                    <Grid lg={12}>
                        {audienceSummary?.name === 'facebookPage' &&
                            facebookComponents.map((item, index) => {
                                return (
                                    <TabPanel
                                        value={value}
                                        index={index}
                                        className="tablePanel-class"
                                    >
                                        {item}
                                    </TabPanel>
                                );
                            })}
                        {audienceSummary?.name === 'facebookGroup' &&
                            facebookComponents.map((item, index) => {
                                return (
                                    <TabPanel
                                        value={value}
                                        index={index}
                                        className="tablePanel-class"
                                    >
                                        {item}
                                    </TabPanel>
                                );
                            })}
                        {audienceSummary?.name === 'instagram' &&
                            instagramComponents.map((item, index) => {
                                return (
                                    <TabPanel
                                        value={value}
                                        index={index}
                                        className="tablePanel-class"
                                    >
                                        {item}
                                    </TabPanel>
                                );
                            })}
                    </Grid>
                    {audienceSummary?.name === 'CustomReports' && (
                        <>
                            <Grid container>
                                <Grid lg={12}>
                                    <Box sx={{
                                        width: '100%', bgcolor: '#fff', p: '8px 15px', height: '80px',
                                        border: '2px solid #ECECEC', borderRadius: '8px',
                                        mb: '5px', display: 'flex', alignItems: 'center'
                                    }}>
                                        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant='h5'>
                                                View by :
                                            </Typography>
                                            <Button
                                                ref={anchorRef}
                                                id="composition-button"
                                                aria-controls={open ? 'composition-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle}
                                                disableRipple
                                                sx={{
                                                    bgcolor: "#fff", display: 'flex', alignItems: 'center',
                                                    justifyContent: 'space-between', color: '#000', height: '50px',
                                                    width: '150px', mr: 2, ml: 1, border: '1px solid grey', borderRadius: '8px'
                                                }}
                                            >
                                                <Typography variant='h5'>
                                                    Day
                                                </Typography>

                                                <KeyboardArrowDownIcon fontSize='large' />
                                            </Button>
                                            <Menu
                                                open={open}
                                                anchorEl={anchorRef.current}
                                                sx={{ width: '180px' }}
                                            >
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem onClick={handleClose}>
                                                            <Typography variant='h5'>
                                                                Day
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}>
                                                            <Typography variant='h5'>
                                                                Week
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}>
                                                            <Typography variant='h5'>
                                                                Monthly
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem onClick={handleClose}>
                                                            <Typography variant='h5'>
                                                                Quarterly
                                                            </Typography>

                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>

                                            </Menu>
                                        </Grid>
                                        <Divider flexItem orientation='vertical' />
                                        <Tooltip title='Set a date range'>
                                            <DatePicker
                                                className='datePicker'
                                                placeholder='Select Date Range'
                                                value={states}
                                                onChange={setStates}
                                                range
                                                rangeHover
                                            />
                                        </Tooltip>
                                        <Divider flexItem orientation='vertical' />
                                    </Box>
                                </Grid>
                                <Grid lg={12}>

                                    {
                                        audienceSummary?.name === "facebookPage" && facebookComponents.map((item, index) => {
                                            return (<TabPanel value={value} index={index} className="tablePanel-class">
                                                {item}
                                            </TabPanel>
                                            )
                                        })
                                    }
                                    {
                                        audienceSummary?.name === "facebookGroup" && facebookComponents.map((item, index) => {
                                            return (<TabPanel value={value} index={index} className="tablePanel-class">
                                                {item}
                                            </TabPanel>
                                            )
                                        })
                                    }
                                    {audienceSummary?.name === "instagram" && instagramComponents.map((item, index) => {
                                        return (<TabPanel value={value} index={index} className="tablePanel-class">
                                            {item}
                                        </TabPanel>
                                        )
                                    })
                                    }
                                </Grid>
                                {audienceSummary?.name === "CustomReports" &&
                                    <>
                                        <Grid container >
                                            <Grid lg={2.5} sx={{ bgcolor: "#fff", border: '2px solid #ECECEC', borderRadius: '8px', m: '0px' }}>
                                                <Grid  >
                                                    <List>
                                                        <ListItem >
                                                            <ListItemButton disableRipple
                                                                onClick={handleAddDashboard}>
                                                                <ListItemIcon sx={{ minWidth: 35 }}>
                                                                    <AddCircleOutlineIcon fontSize='large' />
                                                                </ListItemIcon>
                                                                <Typography variant="h5" sx={{ color: '#383333' }}>
                                                                    Add Dashboard
                                                                </Typography>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </List>
                                                </Grid>
                                                <Tabs
                                                    orientation="vertical"
                                                    value={customValue}

                                                    onChange={handleCustomReportChange}
                                                    aria-label="Vertical tabs example"
                                                    sx={{ borderRight: 1, borderColor: 'divider', }}

                                                >
                                                    {Array.isArray(selectedCardComponent) && selectedCardComponent?.map((item, index) => {
                                                        return (
                                                            <Tab key={index}
                                                                className="facebook_tab_list"
                                                                label={
                                                                    <Typography variant="h6" fontWeight={600}>
                                                                        {item?.name}
                                                                    </Typography>
                                                                }
                                                                {...a11yProps(index)}
                                                            />
                                                        )
                                                    })
                                                    }
                                                </Tabs>
                                            </Grid>
                                            <Grid lg={9} sx={{ m: '10px 0px 0px 15px' }}>
                                                <Grid sx={{ m: '0px 0px 10px 0px' }} display='flex' alignItems='center'
                                                    justifyContent='space-between'>
                                                    {facebook ?
                                                        <form onSubmit={handleCustomReports}>
                                                            <Grid display='flex' alignItems='center' >
                                                                <TextField
                                                                    variant="standard" type="text"
                                                                    value={newTabs}
                                                                    onChange={(event) => setNewTabs(event.target.value)}
                                                                    inputProps={{
                                                                        style: { fontSize: 16, padding: '12px 16px' },
                                                                    }}
                                                                />
                                                                <Button type="submit" variant="contained" sx={{ mr: 1, ml: 1 }}>
                                                                    Save
                                                                </Button>
                                                                <Button onClick={handleCancel} variant="outlined" color="error">
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                        </form> :
                                                        <Box display='flex'>
                                                            <Typography variant='h5' fontWeight={500} textTransform='uppercase'>
                                                                {selectedCardComponent[customValue]?.name}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <Grid display='flex' alignItems='center'>
                                                        <Button onClick={handleAddCardsClick} className='addCards' >
                                                            add cards
                                                        </Button>
                                                        {openAddCards && (
                                                            <AddCards
                                                                handleClose={() => setOpenAddCards(false)}
                                                            />
                                                        )}
                                                        <Box display='flex' alignItems='center' justifyContent='center' sx={{
                                                            bgcolor: "#fff", border: '2px solid #ECECEC',
                                                            borderRadius: '24px', height: 50, width: 50
                                                        }}>

                                                            <IconButton onClick={handleClickOpen} title='Delete Dashboard'>
                                                                <Delete sx={{ fontSize: '30px' }} />
                                                            </IconButton>
                                                            <Dialog
                                                                open={dialogOpen}
                                                                onClose={handleDialogClose}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    <Typography variant='h4' fontWeight={500}>
                                                                        Confirm to delete {selectedCardComponent[customValue]?.name} Dashboard
                                                                    </Typography>
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <Typography variant='h5'>
                                                                        If you delete your custom {selectedCardComponent[customValue]?.name} dashboard all added cards and dashboard will be deleted.
                                                                    </Typography>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleDialogClose}
                                                                        variant="contained">
                                                                        Cancel
                                                                    </Button>
                                                                    <Button variant="contained" color="error"
                                                                        onClick={() => handleDeleteCustomReport(customValue)}
                                                                    >
                                                                        {console.log('handleDeleteCustomReport', customValue)}
                                                                        Delete
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                {Array.isArray(selectedCardComponent) && selectedCardComponent?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <TabPanel value={customValue} index={index} className="tablePanel-class" >
                                                                {item.components?.map((item, index) => {
                                                                    return item
                                                                })}
                                                            </TabPanel>
                                                        </>
                                                    )
                                                })}

                                                <Grid width='100%' height='250px' visibility='hidden'>
                                                    <Grid display='flex' justifyContent='space-between' mb={4}>
                                                        <Typography variant='h4' >
                                                            Just for display full width this has been used
                                                        </Typography>
                                                        <Typography variant='h4' >
                                                            Just for display full width this has been used
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </>}

                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </>
    );
}

export default FacebookAnalyticTabs;
