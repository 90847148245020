import React from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridContainer from '../../../Common/gridContainer/GridContainer';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import TableComponent from '../../../TableComponent/TableComponent';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Loader from '../../../Loader';
import { useSelector } from 'react-redux';

const heading = [
  {
    heading1: 'Countries',
    heading2: 'Count',
    heading3: '%',
  },
];
const tabledata = [
  {
    data1: 'INDIA  ',
    data2: '114',
    data3: '92.68%',
  },
];
function InstagramImpressionSummary() {
  const { impressionsSummaryLoading, impressionsSummaryData } = useSelector(
    (state) => state.instagram
  );
  const data = [
    { name: 'Organic', value: 90, percentage: '90%' },
    { name: 'Paid', value: 10, percentage: '10%' },
  ];
  const COLORS = ['#413ea0', '#7a40ed'];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {impressionsSummaryLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Impression Summary
              </Typography>
              <MoreHorizOutlined fontSize="large" />
            </Grid>
            <Grid container columns={12}>
              <GridContainer>
                <GridDataComponent
                  heading="Impressions"
                  value={impressionsSummaryData?.totalImpressions}
                  percentFall={(
                    (impressionsSummaryData?.totalImpressions * 100) /
                    100
                  ).toFixed(2)}
                  avg="722"
                />
              </GridContainer>
              <Divider orientation="vertical" flexItem />

              <GridContainer>
                <GridDataComponent
                  heading="Reach"
                  value={impressionsSummaryData?.totalReach}
                  percentFall={(
                    (impressionsSummaryData?.totalReach * 100) /
                    100
                  ).toFixed(2)}
                  avg="428"
                />
              </GridContainer>
              <Divider orientation="vertical" flexItem />
              <Grid lg={3} p="8px 15px">
                {/* <Typography variant="h5" className="subheading">
              organic VS paid reach
            </Typography>
            <Grid container width="100%">
              <Grid width="100%" lg={7}>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie
                      data={data}
                      cx={100}
                      cy={100}
                      outerRadius={70}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid
                lg={5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  {data.map((item) => (
                    <Box display="flex">
                      <Typography variant="h5" sx={{ mr: 1 }}>
                        {item.percentage}
                      </Typography>
                      <Typography variant="h5">{item.name}</Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid> */}
              </Grid>

              <Divider orientation="vertical" flexItem />
              <Grid lg={3.5} p="8px 15px">
                {/* <Typography variant="h5" className="subheading">
              top country
            </Typography>
            <Grid height="80%" display="flex" alignItems="center">
              <TableComponent heading={heading} tabledata={tabledata} />
            </Grid> */}
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default InstagramImpressionSummary;
