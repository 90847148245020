import React, { useCallback, useState } from 'react';
import { MoreHorizOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Typography } from '@mui/material';
import GridDataComponent from '../../../Common/gridDataComponent/GridDataComponent';
import GridContainer3 from '../../../Common/gridContainer/GridContainer3';
import InstagramReelsIcon from '../../../../assets/images/instagram-reels.png';
import TableComponent from '../../../TableComponent/TableComponent';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { useSelector } from 'react-redux';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import Loader from '../../../Loader';
import NoDataComponent from '../../../Common/NoDataComponent';

const heading = [
  {
    heading1: 'Type',
    heading2: 'Engagegement',
    heading3: '%',
  },
];

const tabledataInstagram = [
  {
    data1: 'Likes  ',
    data2: 58,
    data3: '87.57%',
  },
  {
    data1: 'Comments  ',
    data2: 6,
    data3: '8.96%',
  },
  {
    data1: 'Shares',
    data2: 2,
    data3: '2.99%',
  },
  {
    data1: 'Saved',
    data2: 1,
    data3: '1.49%',
  },
];

function StorySummary() {
  const sum = tabledataInstagram.reduce((total, item) => total + item.data2, 0);
  let { facebook, instagram } = useSelector((state) => state.user);
  let { reelsStorySummaryLoading, reelsStorySummaryData } = useSelector(
    (state) => state.instagram
  );

  var data = [
    { labels: 'Engagements', value: [sum] },
    { labels: 'Likes', value: 58 },
    { labels: 'Comments', value: 6 },
    { labels: 'Shares', value: 2 },
    { labels: 'Saved', value: 1 },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const onPieLeave = () => setActiveIndex(0);
  const colors = ['#413ea0', '#7a40ed', '#bba2ec', '#dcd5eb'];
  data = data.map((entry, index) => ({ ...entry, fill: colors[index - 1] }));

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          style={{ fontFamily: 'sans-serif', fontSize: '12px' }}
        >
          {[payload.labels, payload.value]}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius + 0}
          startAngle={startAngle}
          outerRadius={outerRadius + 0}
          endAngle={endAngle}
          fill={fill}
        />
      </>
    );
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Story Summary
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {reelsStorySummaryLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <Grid container columns={12}>
            <GridContainer3>
              <GridDataComponent
                heading="Total Stories"
                value={reelsStorySummaryData?.totalStoryCount}
                // percentRise=""
                // avg="<1"
              />

              <Grid mb={2} />
              <Divider />
              <Grid mb={2} />

              <GridDataComponent
                heading="Total Engagement"
                value={reelsStorySummaryData?.totalStoryEngagementCount}
                // percentFall=""
                // avg="0"
              />
            </GridContainer3>
            <Divider orientation="vertical" flexItem />

            <Grid lg={3} sx={{ ml: 1, mr: 1 }}>
              <Typography variant="h5" className="subheading" mb={2}>
                Top Stories
              </Typography>

              {reelsStorySummaryData && reelsStorySummaryData?.topStory && (
                <>
                  <Typography variant="p" className="subheading" mb={1}>
                    DIRECTLY VIA INSTAGRAM
                  </Typography>

                  <Typography
                    variant="p"
                    display="flex"
                    alignItems="center"
                    mb={1}
                  >
                    <PhotoSizeSelectActualIcon
                      fontSize="medium"
                      sx={{ mr: 1 }}
                    />
                    {reelsStorySummaryData?.topStory?.createdTime}
                  </Typography>
                  <Grid width="100%" mb={1}>
                    <img
                      className="image"
                      src={reelsStorySummaryData?.topStory?.imageUrl}
                      alt="img"
                    />
                  </Grid>

                  <Divider />
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Exits
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.exits}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Replies
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.replies}
                    </Typography>
                  </Grid>

                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Taps Forward
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.tapsForward}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Taps Back
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.tapsBack}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Engagement
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.engagement}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Impressions
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.impressions}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                  >
                    <Typography variant="h6" fontWeight={500}>
                      Reach
                    </Typography>
                    <Typography variant="h6" fontWeight={500}>
                      {reelsStorySummaryData?.topStory?.reach}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            <Divider orientation="vertical" flexItem />
          </Grid>
        )}
      </Box>
    </>
  );
}

export default StorySummary;
