import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import TableComponent from '../../TableComponent/TableComponent';
import { MoreHorizOutlined } from '@mui/icons-material';
import MenuComponent from '../../Common/MenuComponent/MenuComponent';
import { useSelector } from 'react-redux';
import NoDataComponent from '../../Common/NoDataComponent';
import Loader from '../../Loader';

const tabledata = [
  {
    data1: 'English',
    data2: '566',
    data3: '76.66%',
  },
  {
    data1: 'Kannada',
    data2: '85',
    data3: '9.5%',
  },
  {
    data1: 'Telugu',
    data2: '14',
    data3: '0.8%',
  },
  {
    data1: 'Tamil',
    data2: '8',
    data3: '0.7%',
  },
  {
    data1: 'Malayalam',
    data2: '7',
    data3: '0.7',
  },
  {
    data1: 'Hindi',
    data2: '5',
    data3: '0.5%',
  },
];

function PageLikesByLanguage() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const { likesByLanguageLoading, likesByLanguageData } = useSelector(
    (state) => state.facebook
  );
  const heading = [
    {
      heading1: 'Language',
      heading2: 'Likes',
      heading3: 'Likes%',
    },
  ];
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height:"100%",
          bgcolor: '#fff',
          p: 4,
          border: '2px solid #ECECEC',
          borderRadius: '8px',
          mb: '10px',
        }}
      >
        {likesByLanguageLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : (
          <>
            <Grid display="flex" justifyContent="space-between" mb={4}>
              <Typography variant="h4" color="#000" fontWeight={600}>
                Pages Likes by Language
              </Typography>
              <MoreHorizOutlined fontSize="large" onClick={openMenu} />
            </Grid>
            <MenuComponent
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              displayItems={[
                'Discuss',
                'Export as PDF',
                'Export as PNG',
                'Export as CSV',
                'Send as Email',
                'Schedule',
              ]}
            />
            <Grid container columns={12}>
              {likesByLanguageData?.likesByLanguage?.length > 0 ? (
                <TableComponent
                  heading={heading}
                  tabledata={likesByLanguageData?.likesByLanguage}
                />
              ) : (
                <NoDataComponent />
              )}
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default PageLikesByLanguage;
