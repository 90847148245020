import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import {
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
  } from 'recharts';

function VertiacalDualSmallSizedBar({dataKey1,dataKey2,vertiacalDualSmallBarData,YAxis_Label}) {
  return (
    <Grid container columns={12}>
    <ResponsiveContainer height={500} width={'100%'}>
      <BarChart
        data={vertiacalDualSmallBarData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" fontSize={10}/>
        <YAxis
          fontSize="14"
          label={{
            value: YAxis_Label,
            angle: -90, 
            fontSize: '16px',
            style: { textAnchor: 'middle' }
          }}
          domain={[0, dataMax => (dataMax * 1.5)]}
        />
        <Tooltip
          itemStyle={{ fontSize: '16px' }}
          wrapperStyle={{ fontSize: '16px' }}
        />
        <Legend
          iconSize={14}
          wrapperStyle={{ fontSize: 18 }}
          iconType="circle"
        />
        {dataKey1 ? <Bar dataKey={dataKey1} barSize={8} fill="#8884d8" />:null}
        {dataKey2 ? <Bar dataKey={dataKey2} barSize={8} fill="#82ca9d" />:null}
      </BarChart>
    </ResponsiveContainer>
  </Grid>
  )
}

export default VertiacalDualSmallSizedBar
