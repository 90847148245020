import React from 'react';
import { Typography, Box, Grid, Divider } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import TopStoryCards from '../../TopStoryCards';
import { useSelector } from 'react-redux';
import Loader from '../../Loader';
import NoDataComponent from '../../Common/NoDataComponent';

function TopStoriesBy() {
  let { storiesLoading, storiesData } = useSelector((state) => state.instagram);
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" color="#000" fontWeight={600}>
            Top Stories By
          </Typography>
          <MoreHorizOutlined fontSize="large" />
        </Grid>
        {storiesLoading ? (
          <Loader color="inherit" value={100} size={25} />
        ) : storiesData?.data?.length > 0 ? (
          <Grid container display="flex">
            <TopStoryCards data={storiesData?.data} />
          </Grid>
        ) : (
          <NoDataComponent />
        )}
      </Box>
    </>
  );
}

export default TopStoriesBy;
