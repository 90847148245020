import { Grid } from '@mui/material'
import React from 'react'

function GridContainer({
    children
}) {
    return (
    <>
        <Grid lg={2.5}
            display='grid' flexDirection='row'
            justifyContent='space-between' p='8px 15px'>
                {children}
        </Grid>
    </>
    )
}

export default GridContainer