import React from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import { MoreHorizOutlined, } from '@mui/icons-material';
import LineChartComponent from '../../Common/ChartComponents/LineChartComponent';
import Loader from '../../Loader';
import NoDataComponent from "../../Common/NoDataComponent";
import { useSelector } from 'react-redux';

const data = [
    {
        name: "16 MAR' 23",
        Likes: 800,
        Comments: 200,
        Shares: 0,
        Saved: 2,

    },
    {
        name: "17 MAR' 23",
        Likes: 785,
        Comments: 80,
        Shares: 2,
        Saved: 1,

    },
    {
        name: "18 MAR' 23",
        Likes: 182,
        Comments: 22,
        Shares: 0,
        Saved: 0,

    },
    {
        name: "19 MAR' 23",
        Likes: 144,
        Comments: 14,
        Shares: 0,
        Saved: 2,

    },
    {
        name: "20 MAR' 23",
        Likes: 44,
        Comments: 2,
        Shares: 0,
        Saved: 0,

    },
    {
        name: "21 MAR' 23",
        Likes: 512,
        Comments: 256,
        Shares: 66,
        Saved: 5,

    },
    {
        name: "22 MAR' 23",
        Likes: 1024,
        Comments: 128,
        Shares: 64,
        Saved: 32,

    }
];

function ReelsEngagementActions() {
    const { reelsEngagementActionsLoading, reelsEngagementActionsData } = useSelector((state) => state.instagram);

  return (<>
  <Box sx={{
          width: '100%', height:"100%", bgcolor: '#fff', p: 4,
          border: '2px solid #ECECEC', borderRadius: '8px',
          mb: '10px'
        }}>
            {reelsEngagementActionsLoading ? (
                <Loader color="inherit" value={100} size={25} />
            ) :
                <>
                    <Grid display='flex' justifyContent='space-between' mb={4}>
                        <Typography variant='h4' color='#000' fontWeight={600}>
                            Reels Engagegement Actions
                        </Typography>
                        <MoreHorizOutlined fontSize='large' />
                    </Grid>
                    {reelsEngagementActionsData?.length > 0 ?
                        <LineChartComponent
                            data={reelsEngagementActionsData}
                            XAxis_dataKey='timestamp'
                            line1Datakey='like_count'
                            line2Datakey='comment_count'
                            line3Datakey='share_count'
                            line4Datakey='save_count'
                            YAxis_Label='Number Of Engagement'
                        />
                        : <NoDataComponent />}

                </>

            }
        </Box>
    </>
    )
}

export default ReelsEngagementActions